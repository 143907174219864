import { useAuth } from "../context/AuthContext";
import axiosInstance from "./axiosInstance";

const useAxiosInterceptors = () => {
  const { setUserToken } = useAuth();

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 errors (token expired)
        sessionStorage.removeItem('userToken');
        localStorage.clear();
        setUserToken(null);
        // You can return a custom response here instead of rejecting the promise
        return Promise.resolve({ data: { message: "Session timed out" } });
      }
      return Promise.reject(error);
    }
  );
};

export { useAxiosInterceptors };
