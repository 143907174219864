import React, { useEffect, useState } from 'react';
import { Autocomplete, Popper, Typography, Box, Grid, TextField, FormControl, MenuItem, Select, Button ,Paper, Dialog, DialogTitle, 
        useTheme, DialogContent, Alert, DialogActions, List, ListItem, ListItemIcon , IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, startOfWeek, isSameMonth, isWeekend } from 'date-fns';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { getTimeTrackerData, postTimeTrackerData, sendTimeSheetApprovalEmail} from '../../services/TimeTrackerServices';
import moment from 'moment'
import { TIMESHEET_DROPDOWN_OPTIONS } from "../../Constants";
import {Report as ReportIcon, Cancel as CancelIcon } from '@mui/icons-material';
import './timeTracker.css';

function TimeTracker() {
  const id =  localStorage.getItem("employeeId");
  const currentMonth = new Date().getMonth();
  const [month, setMonth] = useState(currentMonth);  
  const year = new Date().getFullYear();
  const [currentYear, setCurrentYear] = useState(year);
  const [weekFilter, setWeekFilter] = useState('All');
  const [weeks, setWeeks] = useState([]);
  const [loggedHours, setLoggedHours] = useState({});  
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [existingDates, setExistingDates] = useState(new Set());
  const [isModified, setIsModified] = useState(false);
  const [initialLoggedHours, setInitialLoggedHours] = useState({});
  const [selectedFields, setSelectedFields] = useState(new Set());
  const [loading, setLoading] = useState(false);  
  const [openDialog, setOpenDialog] = useState(false);
  // const [dailyEffortList, setDailyEffortList] = useState([]);
  const [monthNames, setMonthNames] = useState(moment.months());
  const [weekDaysFull, setweekDaysFull] = useState(moment.weekdays());
  const [weekDaysShort, setweekDayShort] = useState(weekDaysFull.map((word) => word.substring(0, 3)));
  const [preferredTimesheetMonths, setPreferredTimesheetMonths] = useState();
  const [leaveDatesWithoutLogTime, setLeaveDatesWithoutLogTime] = useState();
  const [timeTrackerData, setTimeTrackerData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const isMobile = useMediaQuery('(max-width:800px)'); 
  const theme = useTheme();

  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isTimesheetFilled, setIsTimesheetFilled] = useState(false);
  const [isTimesheetAoproved , setIsTimesheetApproved] = useState('');

  useEffect(() => {
    fetchTimeTrackerData();
    if (month !== null) {
      generateWeeks(currentYear, month);
    }
  }, [month]);  

///useeffect to run for the first time only
useEffect(() => {

  // const efforts = [];
  //   for (let i = 1; i <= 8; i++) {
  //     efforts.push(i);
  //   }
  //   efforts.push(CLIENT_HOLIDAY);
  //   efforts.push(LEAVE_TAKEN);
  //   setDailyEffortList(efforts.reverse());
    
  //Previous Month appended with appropriate year
  let previousMonth =
    currentMonth - 1 < 0
      ? monthNames[(currentMonth - 1) * -(monthNames.length - 1)]
          .concat(" ")
          .concat(currentYear - 1)
      : monthNames[currentMonth - 1].concat(" ").concat(currentYear);

  //Next Month appended with appropriate year
  let nextMonth =
    currentMonth + 1 > monthNames.length - 1
      ? monthNames[(currentMonth + 1) % monthNames.length]
          .concat(" ")
          .concat(currentYear + 1)
      : monthNames[currentMonth + 1].concat(" ").concat(currentYear);

  setPreferredTimesheetMonths([
    previousMonth,
    monthNames[currentMonth].concat(" ").concat(currentYear), // appending current year to display in dropdown
    nextMonth,
  ]);

}, []);

  useEffect(() => {
    calculateTotals();
    setLoading();
    if (JSON.stringify(loggedHours) !== JSON.stringify(initialLoggedHours)) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [loggedHours,initialLoggedHours]);

  const fetchTimeTrackerData = async () => {
    try {
      const response = await getTimeTrackerData(id, currentYear, monthNames[month]);
      const timeEntries = response.timeEntries;
      if (timeEntries) {
        const allLoggedHours = {};
        const dates = [];
        
        //This is to fetch the timeEntries that are not filled with Hours logged and when the leaves are applied..
        setLeaveDatesWithoutLogTime(timeEntries.filter(t =>
                                                          t.leave == true
                                                          && (t.hoursLogged == undefined || t.hoursLogged == null)));
        
        setTimeTrackerData(timeEntries);
        timeEntries.forEach(entry => {         
          const { date, hoursLogged } = entry;
          allLoggedHours[date] = hoursLogged || null; 
          dates.push(date);
        });

        setLoggedHours(allLoggedHours);
        setExistingDates(dates);
        setInitialLoggedHours(allLoggedHours);
        setIsTimesheetApproved(response.approvalRequest);
      } else {
        console.error("No time tracker data available.");
      }
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        NotificationManager.error('Failed to fetch time tracker data.');
      } else {
        console.error("No data found, or the server returned a 404 response.");
      }
    }
  };  

  const handleMonthChange = (event) => {
    let selectedMonthYear = event.target.value.split(' ');
    const monthName = selectedMonthYear[0];
    const monthIndex = monthNames.indexOf(monthName);
    const year = Number(selectedMonthYear[1]);
    setCurrentYear(year);
    setMonth(monthIndex);
  };

  const handleWeekFilterChange = (event) => {
    setWeekFilter(event.target.value);
  };

  const generateWeeks = (year, monthIndex) => {
    const start = startOfMonth(new Date(year, monthIndex));
    const end = endOfMonth(new Date(year, monthIndex));
    const startDate = startOfWeek(start, { weekStartsOn: 0 });
    
    const days = eachDayOfInterval({ start: startDate, end: end });
    const weeks = [];
    let week = [];
    let weekHasWorkDays = false;
    let currentWeekIndex = 0;
  
    days.forEach((date, index) => {
      if (week.length < 7) {
        week.push(date);
        if (!isWeekend(date) && isSameMonth(date, new Date(year, monthIndex))) {
          weekHasWorkDays = true;
        }
        if (format(date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) {
          currentWeekIndex = weeks.length;
        }
      } else {
        weeks.push({ days: week, hasWorkDays: weekHasWorkDays });
        week = [date];
        weekHasWorkDays = !isWeekend(date) && isSameMonth(date, new Date(year, monthIndex));
      }
    });
  
    if (week.length > 0) {
      weeks.push({ days: week, hasWorkDays: weekHasWorkDays });
    }
  
    setWeeks(weeks);
    setWeekFilter('All');
  };

  const handleHoursChange = (date, hours) => {
  
    if (hours === '') {
      setLoggedHours(prev => {
        const updatedHours = { ...prev };
        delete updatedHours[date];
        return updatedHours;
      });
    } else {
      setLoggedHours(prev => ({
        ...prev,
        [date]: hours && (typeof hours === 'string') ? hours.toUpperCase() : hours
      }));
    } 
  
  };
  
  // const handleSaveAndUpdate = async () => {
  //   setLoading(true);
  //   try {
  //     const newDays = Object.keys(loggedHours)
  //       .filter(date => !existingDates.has(date))
  //       .map(date => ({
  //         date,
  //         hoursLogged: loggedHours[date]
  //       }));
  
  //     const updatedDays = Object.keys(loggedHours)
  //       .filter(date => existingDates.has(date))
  //       .map(date => ({
  //         date,
  //         hoursLogged: loggedHours[date]
  //       }));
  
  //     const monthName = monthNames[month];
      
  //     const dataToSaveAndUpdate = {
  //       year: currentYear,
  //       month: monthName,
  //       days: [...newDays, ...updatedDays]
  //     };
  
  //     if (newDays.length > 0 || updatedDays.length > 0) {
  //       const response = await (newDays.length > 0
  //         ? postTimeTrackerData(id, dataToSaveAndUpdate)
  //         : putTimeTrackerData(id, dataToSaveAndUpdate));

  //       NotificationManager.success('Time tracker data updated successfully.');
  //       fetchTimeTrackerData();
  //     } else {
  //       NotificationManager.info('No data to update.');
  //     }
  //   } catch (error) {
  //     NotificationManager.error('Failed to update time tracker data.');
  //     setLoading(false);
  //   }
  // };
 
  const handleSaveAndUpdate = async () => {
    setLoading(true);
    try {
      const existingDatesSet = new Set(existingDates);

        const newDays = Object.keys(loggedHours)
        .filter(date => !existingDatesSet.has(date))
        .map(date => ({
          date,
          hoursLogged: loggedHours[date]
        }));
  
      const updatedDays = Object.keys(loggedHours)
        .filter(date => existingDatesSet.has(date))
        .map(date => ({
          date,
          hoursLogged: loggedHours[date]
        }));
      const monthName = monthNames[month];
      
      const dataToSaveAndUpdate = {
        year: currentYear,
        month: monthName,
        days: [...newDays, ...updatedDays]
      };

      //Exclude dates that are related to leaves and which were not filled initially      
      for (var i = dataToSaveAndUpdate.days.length - 1; i >= 0; i--) {
        const dateExists = dataToSaveAndUpdate.days.some((day) =>
          leaveDatesWithoutLogTime.some((leave) => leave.date === day.date)
        );

        if ( dataToSaveAndUpdate.days[i]?.hoursLogged == null && dateExists) {          
            dataToSaveAndUpdate.days.splice(i, 1);
          }
      }
      
      const response = await postTimeTrackerData(id, dataToSaveAndUpdate);
      if(response.statusText === "OK"){
        setTimeout(()=>{
          fetchTimeTrackerData();
        },[3000])
        NotificationManager.success('Time tracker data updated successfully.');
       
      }
    } catch (error) {
      NotificationManager.error('Failed to update time tracker data.');
      setLoading(false);
    }
  };
 
  const handleSendApproval = async () => {

    const getTotalDaysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };
    
    const getAllDatesInMonth = (month, year) => {
      const dates = [];
      for (let i = 1; i <= getTotalDaysInMonth(month, year); i++) {
        const date = new Date(year, month - 1, i);
        if (date.getDay() !== 0 && date.getDay() !== 6) { // exclude weekends (Sunday = 0, Saturday = 6)
          dates.push(`${year}-${String(month).padStart(2, '0')}-${String(i).padStart(2, '0')}`);
        }
      }
      return dates;
    };

    const checkLoggedHours = (timeTrackerData, dates) => {
      return dates.every((date) => {
        const found = timeTrackerData.find((item) => item.date === date);
        return found && (found.hoursLogged || found.leave);
      });
    };

    const allDates = getAllDatesInMonth(month + 1, currentYear);
    const timesheetFilled = checkLoggedHours(timeTrackerData, allDates);

    if (timesheetFilled) {
      setDialogMessage('Do you wish to send this timesheet for approval?');
      setIsTimesheetFilled(true);
    } else {
      setDialogMessage('Please fill all the details before you proceed to send approval request');
      setIsTimesheetFilled(false);
    }

    setOpenApproveDialog(true);
  }

  const proceedToApproval = async () => {
    try {
      const data = {
        employeeId: id,
        month: monthNames[month],
        year: currentYear,
        status: 'requested'
      }
      await sendTimeSheetApprovalEmail(data);
      NotificationManager.success('The timesheet has been submitted for approval.');
      fetchTimeTrackerData();
      setOpenApproveDialog(false)
    } catch (error) {
      NotificationManager.error('Failed to send approval For Timesheet , try again later');
    }
  }

  const calculateTotals = () => {
    const newWeeklyTotals = weeks.map(weekInfo => {
      return weekInfo.days.reduce((total, date) => {
        if (isSameMonth(date, new Date(currentYear, month))) {
          const dateString = format(date, 'yyyy-MM-dd');
          const hours = parseFloat(loggedHours[dateString]) || 0;
          return total + hours;
        }
        return total;
      }, 0);
    });
  
    const newMonthlyTotal = newWeeklyTotals.reduce((sum, weekTotal) => sum + weekTotal, 0);
    setMonthlyTotal(newMonthlyTotal);
  };

  const handleCancel = () => {
    setLoggedHours(prev => {
      const newLoggedHours = { ...prev };
      
      selectedFields.forEach(date => {
        newLoggedHours[date] = '';
      });
      return newLoggedHours;
    });
    setSelectedFields(new Set());
    fetchTimeTrackerData();
  };  
  
  const handleFieldFocus = (date) => {
    setSelectedFields(prev => new Set(prev.add(date)));
  };

  const handleCancelDialog = () => {
    setOpenDialog(false);
  }; 

  const handleTooltipOpen = (date) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [date]: true,
    }));
  };
  
  const handleTooltipClose = (date) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [date]: false,
    }));
  };

  const isTooltipOpen = (date) => tooltipOpen[date] || false;
  
  const renderWeeks = () => {
    return (
      <>
        <Grid style={{backgroundColor:'#fff'}} paddingLeft={0.5} paddingRight={0.25} borderRadius={2} >  
        <Grid container sx={{ paddingLeft: { xs: 0, sm: 3.5 }}}>
          {(isMobile ? weekDaysShort : weekDaysFull).map((day, index) => (
            <Grid
              item
              xs={ index === 0 || index === 6 ? 1 : 2}
              md={1.65}
              key={index}
              sx={{
                border: '1px solid #ddd',
                padding: 1,
                backgroundColor: '#f5f5f5',
                marginTop: '10px',
              }}
            >
              <Typography
                variant="h6"
                align="center"
                color={ theme.palette.primary.main}
                style={{ fontWeight: 'bold' }}
              >
                {(index === 0 || index === 6) && isMobile ? "S" : day}
              </Typography>
            </Grid>
          ))}
        </Grid>
  
        {weeks.map((weekInfo, weekIndex) => {
          if (weekFilter !== 'All' && weekFilter !== `Week ${weekIndex + 1}`) {
            return null;
          }
  
          return (
            <Grid container key={weekIndex} sx={{ paddingLeft: { xs: 0, sm: 3.5 }}}>
              {weekInfo.days.map((date, index) => (
                <Grid item xs={ index === 0 || index === 6 ? 1 : 2} md={1.65} key={index}>
                  {isSameMonth(date, new Date(currentYear, month)) && (
                    <>
                      <Paper
                        elevation={2}
                        className='tt-calender-date'
                        sx={{
                          height: {md:"70px", sm:"50px", xs:"35px"},
                          backgroundColor: isWeekend(date)
                            ? "#FEFFA7"
                            : loggedHours[format(date, "yyyy-MM-dd")]
                            ? "#B6FFA1"
                            : "#ffff",
                        }}
                        
                        onClick={(e) => {
                          if (!isWeekend(date)) {
                            // Prevent tooltip from closing by stopping the event from bubbling
                            e.stopPropagation();
                            e.preventDefault();
                            // Focus the TextField
                            document.getElementById(`textField-${format(date, "yyyy-MM-dd")}`).focus();
                          }
                        }}                       
                      >
                        <Typography
                          fontWeight="bold"
                          sx={{
                            color: theme.palette.primary.main,
                            position: "absolute",
                            // top: 0,
                            bottom: (index === 0 || index === 6) ? null : 0,
                            textAlign: 'center',
                            margin: 0,
                            // right: 0,
                            padding: { md: "0px", xs: "2px" },
                            // backgroundColor: "#f5f5f5",
                            fontSize: { xs: "9px", sm: "10px", md: "14px" },
                            borderRadius: '50px'
                          }}
                        >
                          {format(date, "dd")}
                        </Typography>
                        { timeTrackerData.map((entry) => (
                            entry.leave && entry.date === format(date, "yyyy-MM-dd") && !isWeekend(date) && (
                          <Tooltip title="You have applied leave on this day. Please fill it accordingly. (Hint: Select LT from dropdown)"
                            open={isTooltipOpen(format(date, "yyyy-MM-dd"))}
                            onClose={() => handleTooltipClose(format(date, "yyyy-MM-dd"))}
                            onOpen={() => handleTooltipOpen(format(date, "yyyy-MM-dd"))}
                            leaveTouchDelay={10000}
                            disableInteractive
                            arrow
                          >
                            <IconButton
                              onClick={(e) =>{
                                e.stopPropagation();
                                setTooltipOpen((prev) => ({
                                  ...prev,
                                  [format(date, "yyyy-MM-dd")]: !isTooltipOpen(format(date, "yyyy-MM-dd")),
                                }))
                              }}
                              onMouseEnter={() => handleTooltipOpen(format(date, "yyyy-MM-dd"))}
                              onMouseLeave={() => handleTooltipClose(format(date, "yyyy-MM-dd"))}
                              sx={{
                                  position: 'absolute',
                                  bottom: {xs: -5, sm: 0},
                                  right: {xs: -5, sm: 0},
                              }}
                              size="small"
                            >
                                <ReportIcon  sx={{ color: '#1976d2' , fontSize: {xs: '0.7rem', sm: '1rem'} }} />
                            </IconButton>
                          </Tooltip>
                          ))
                        )}

                        {!isWeekend(date) && (
                          <Autocomplete
                            freeSolo
                            options={TIMESHEET_DROPDOWN_OPTIONS}
                            id={`textField-${format(date, "yyyy-MM-dd")}`}
                            onFocus={() =>handleFieldFocus(format(date, "yyyy-MM-dd"))}
                            value={loggedHours[format(date, "yyyy-MM-dd")] || ""}
                            onChange={(e, newValue) => handleHoursChange(format(date, "yyyy-MM-dd"), newValue)}
                            onInputChange={(e, newInputValue) => {
                              // Allow only single digits 4-8 or "CH"
                              if (/^(CH|ch|LT|lt|[4-8]?)$/.test(newInputValue)) {
                                // Retain 'CH', 'LT' as uppercase strings; convert numbers to integers
                                const normalizedValue = isNaN(newInputValue) 
                                  ? newInputValue.toUpperCase() // Strings: 'CH', 'LT'
                                  : parseInt(newInputValue); // Numbers: 4-8
                                handleHoursChange(format(date, "yyyy-MM-dd"), normalizedValue);
                              } else {
                                handleHoursChange(format(date, "yyyy-MM-dd"), "");
                                console.error("Invalid input: Only numbers 4-8, 'CH', 'ch', 'LT', or 'lt' are allowed.");
                              }
                            }}
                            PopperComponent={(props) => (
                              <Popper
                                {...props}
                                style={{
                                  width: { xs: "10%", sm: "5%" }
                                }}
                              />
                            )}
                            ListboxProps={{
                              style: {
                                border:"1px solid grey",
                                maxHeight: "160px",
                                overflowY: "auto",
                                padding: 0,
                              },
                              sx: {
                                "& .MuiAutocomplete-option": {
                                  // minHeight: { xs: "20px", sm: "20px" },
                                  minHeight: '20px',
                                },
                                "& li": {
                                  fontSize: { xs: "0.7rem", sm: "0.87rem" },
                                  // lineHeight: { xs: '0.9rem !important ', sm: "0.9rem !important" },
                                  lineHeight: '0.9rem',
                                },
                              },
                            }}
                            getOptionLabel={(option) => String(option)}
                            sx={{
                              "& .MuiAutocomplete-input": {
                                height:{
                                  xs:'7px !important',
                                  sm:'22px !important'
                                },
                                paddingLeft: {
                                  xs: "0px !important",
                                  sm: "4px",
                                },
                                paddingRight: {
                                  xs: "0px !important",
                                  sm: "4px",
                                },
                              },
                            }}
                            className='custom-autocomplete'
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={`textField-${format(date, "yyyy-MM-dd")}`}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  // Remove the clear icon by passing null
                                  endAdornment: null,
                                }}
                                // onKeyDown={(e) => {
                                //   // Allow backspace, delete, tab, enter, numbers (0-8), and 'C', 'H', 'L', 'T'
                                //   if (!/[1-8]|Backspace|Delete|Tab|Enter|ArrowLeft|ArrowRight|[cchlt]/i.test(e.key)) {
                                //       e.preventDefault();
                                //   }
                                // }}
                                onKeyDown={(e) => {
                                  const validFirstKeys = /[c|l|4-8]/i; // First key must be C or L
                                  const validSecondKeys = /[h|t]/i; // Second key must be H or T
                                  const allowedKeys = /Backspace|Delete|Tab|Enter|ArrowLeft|ArrowRight/i;
                                
                                  const currentValue = e.target.value;
                                
                                  if (
                                    currentValue.length === 0 &&
                                    !validFirstKeys.test(e.key) &&
                                    !allowedKeys.test(e.key)
                                  ) {
                                    // If the field is empty, only C or L is allowed
                                    e.preventDefault();
                                  } else if (
                                    currentValue.length === 1 &&
                                    !validSecondKeys.test(e.key) &&
                                    !allowedKeys.test(e.key)
                                  ) {
                                    // If the field has 1 character, only H or T is allowed
                                    e.preventDefault();
                                  } else if (
                                    currentValue.length >= 2 &&
                                    !allowedKeys.test(e.key)
                                  ) {
                                    // Prevent any further input after 2 characters
                                    e.preventDefault();
                                  }
                                }}
                                sx={{
                                  border: "1px solid #cccccc",
                                  borderRadius: "0.3rem",
                                  width: "100%",
                                  maxWidth: { xs: "25px", sm: "40px" },
                                  marginBottom: { xs: "10px", sm: "5px" },
                                  height: { xs: "20px", sm: "35px" },
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "none",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: { xs: "0.75rem", sm: "1rem" },
                                    textTransform: "uppercase",
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                      </Paper>
                    </>
                  )}
                </Grid>
              ))}

              {weekInfo.days.length < 7 &&
                Array.from({ length: 7 - weekInfo.days.length }).map((_, i) => (
                  <Grid item xs={2} sm={6} md={1} key={i} />
                ))}
            </Grid>
          );
        })}

          <Typography marginTop={2} sx={{ paddingLeft: { xs: 0, sm: 3.5 }}}>
          <strong>Monthly Total: </strong>{monthlyTotal} hours
        </Typography>
          {isTimesheetAoproved == 'requested' &&
            <Alert sx={{backgroundColor:'transparent'}} severity="info">Waiting for approval: Sent to Reporting Manager for review.</Alert>
          }
          {isTimesheetAoproved == 'approved' &&
            <Alert sx={{backgroundColor:'transparent'}} severity="success">Approved: Your timesheet has been successfully approved.</Alert>
          }
        <Grid container sx={{ justifyContent: 'right', padding: 1, gap: 2, marginBottom: { xs: 0, sm: 0, md:5 } }}>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} disabled={!isModified || loading}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveAndUpdate} disabled={isTimesheetAoproved === 'requested' || isTimesheetAoproved === 'approved' || (!isModified && !loading)}>
            Save
          </Button>
          <Button variant="contained" color="primary" onClick={handleSendApproval} disabled={isTimesheetAoproved == 'requested' || isTimesheetAoproved == 'approved'} >
             Submit for Approval
          </Button>
        </Grid>
        <Dialog open={openDialog} onClose={handleCancelDialog}>
        <DialogTitle className="confirmDialogTitle">Unsaved Changes Confirmation <IconButton aria-label="close" onClick={handleCancelDialog}><CancelIcon /></IconButton></DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity='warning' className='confirmDialogMsg'>
            There are unsaved changes that will be lost. Do you wish to continue?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCancelDialog} color="primary"> No </Button>
          <Button variant='contained'  onClick={() => {handleCancel(); handleCancelDialog();}} color="primary"> Continue </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openApproveDialog} onClose={() => setOpenApproveDialog(false)} >
        <DialogTitle className="confirmDialogTitle"> Timesheet Approval Confirmation </DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity='warning' className='confirmDialogMsg'>
            {dialogMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color="primary" onClick={() => setOpenApproveDialog(false)}> Cancel </Button>
          {isTimesheetFilled &&
            <Button variant='contained' color="primary" onClick = {proceedToApproval}> Proceed </Button>
          }
        </DialogActions>
      </Dialog>
       </Grid>
      </>
    );
  };

  return (
    <Box className = 'main-content'>      
                <Grid container className='grid-container-header align-header-center'>
                    <Grid item xs={12} md={3}>
                    My Timesheet Tracker
                    </Grid>
                    <Grid container xs={12} md={9} className='align-filters-right'>
                    <Grid item xs={5} md={3}>
                    <FormControl size="small" fullWidth>
                     <Select value={`${monthNames[month]} ${currentYear}`} onChange={handleMonthChange} displayEmpty>
                          {preferredTimesheetMonths?.map((monthData, i) => {

                            const monthName = monthData.split(' ')[0];
                            const monthIndex = monthNames.indexOf(monthName);
                            const year = monthData.split(' ')[1];

                            return (
                              <MenuItem
                                key={i}
                                value={`${monthName} ${year}`}
                                sx={{
                                  fontWeight: currentMonth === monthIndex ? 'bold' : 'normal',
                                }}
                              >
                                {monthData}
                              </MenuItem>
                            );
                          })}
                     </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={5} md={3}>
                    <FormControl size="small" fullWidth>
                     <Select value={weekFilter} onChange={handleWeekFilterChange} displayEmpty>
                        <MenuItem value="All">All Weeks</MenuItem>
                        {weeks.map((weekInfo, weekIndex) => {

                          const monday = weekInfo.days.find((day) => new Date(day).getDay() === 1);
                          
                          const friday = weekInfo.days.find((day) => new Date(day).getDay() === 5);
                          
                          const firstDay = new Date(weekInfo.days[0]);

                          const lastDay = new Date(weekInfo.days[weekInfo.days.length - 1]);

                          const startOfMonth = new Date(currentYear, month, 1);

                          const endOfMonth = new Date(currentYear, month + 1, 0);

                          const adjustedStart = monday ? (monday < startOfMonth ? startOfMonth : monday) : startOfMonth;

                          const adjustedEnd = friday ? (friday > endOfMonth ? endOfMonth : friday) : endOfMonth;

                          const formatDate = (date) => {
                            return date ? `${String(date.getDate()).padStart(2, '0')}/${date.toLocaleString('en-GB', { month: 'short' })}/${date.getFullYear()}` : '';
                          };

                          return (
                            <MenuItem
                              key={weekIndex}
                              value={`Week ${weekIndex + 1}`}
                              sx={{
                                fontWeight: weekFilter === `Week ${weekIndex + 1}` ? 'bold' : 'normal',
                              }}
                            >
                              {`Week ${weekIndex + 1} (${formatDate(adjustedStart)} to ${formatDate(adjustedEnd)})`}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    </FormControl>
                    </Grid>
                    </Grid>
                </Grid>
  
        <Grid container rowGap={1} columnSpacing={1}>
          <Grid item md={10} sm ={12} xs={12}>
            {renderWeeks()}
          </Grid>

          <Grid item md={2} xs={12} sx={{ minWidth: '120px' }} >
            <Paper elevation={2} sx={{ padding: 1.75 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>Legend</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <Box className='timetracker-legend tt-filled-color'/>
                <Typography variant="body2">Filled</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <Box className='timetracker-legend tt-weekend-color'  />
                <Typography variant="body2">Weekend</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box  className='timetracker-legend tt-workingday-color' />
                <Typography variant="body2">Working Day</Typography>
              </Box>
            </Paper>
            <Paper elevation={2} sx={{ padding: 1.75, marginTop: 1, marginBottom: 6 }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>Instructions</Typography>
              <List>
                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1}}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">Select "LT" if you are on leave.</Typography>
                </ListItem>

                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1}}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">Select "CH" if it is a client holiday.</Typography>
                </ListItem>

                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1 }}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">Fill the timesheet before Friday of every week.</Typography>
                </ListItem>

                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1 }}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">Fill the number of hours worked in the office correctly.</Typography>
                </ListItem>

                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1 }}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">A minimum of 8 hours of effort is a must to fill the timesheet.</Typography>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
    </Box>
  ); 
}

export default TimeTracker;
