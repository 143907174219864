import React from 'react';
import { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Typography, Paper, Grid, List, ListItem, Divider, Link, IconButton, Button, Alert } from '@mui/material';
import { Cancel as CancelIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { deleteProofOfSubmission, getFilesData } from '../../services/itDeclarationService';
import { NotificationManager } from "react-notifications";

function ITDUploadProofOfSubmission(regimeData) {

    const { isOpen, onClose, originalData, selectedItem, setSelectedItem, selectedParticular, setSelectedParticular, uploadedFiles, handleSave,
        getRootProps, getInputProps, uploadedProofs, fetchUploadedProofOfSubmission, financialYear, nextFinancialYear, fileError, setFileError } = regimeData

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);

    const handleDownload = async (file) => {
        try {
            let base64Data = file.base64Data;

            if (!base64Data) {
                const fileData = await getFilesData(file.fileId);

                if (!fileData || !fileData.base64Data) {
                    console.error("No base64 data found for file:", file.fileId);
                    return;
                }
                base64Data = fileData.base64Data;
            }

            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: file.contentType });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");

            link.href = url;
            link.download = file.filename;
            document.body.appendChild(link);
            link.click();
            link.remove();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const handleConfirmDelete = async () => {
        if (!fileToDelete) return;
        try {
            await deleteProofOfSubmission(fileToDelete.fileId);
            NotificationManager.success("File deleted successfully.");
            fetchUploadedProofOfSubmission();
            setIsDialogOpen(false);
            setFileToDelete(null);
        } catch (error) {
            console.error('Error deleting file:', error);
            NotificationManager.error("Failed to delete file.");
            setIsDialogOpen(false);
        }
    };

    const handleOpenDeleteDialog = (file) => {
        setFileToDelete(file);
        setIsDialogOpen(true);
    };

    return (
        <>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle className='confirmDialogTitle'>
                    File Delete Confirmation
                    <IconButton aria-label="close" onClick={() => setIsDialogOpen(false)}>
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='confirmDialogContent'>
                    <Alert severity='warning' className='confirmDialogMsg'>
                        Are you sure you want to delete this file?
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant="contained" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isOpen} onClose={onClose} PaperProps={{ sx: { width: '100%' }, className: 'dialog' }}>
                <DialogTitle className='dialogTitle'>
                    Upload Proof of Submission for  {financialYear} (Assessment year {nextFinancialYear})
                    <IconButton aria-label='close' onClick={onClose} className='dialogCloseIcon'>
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='dc-it-declaration'>
                    <FormControl margin='normal'>
                        <InputLabel>Select Items</InputLabel>
                        <Select value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)} label='Select Items'>
                            {Object.keys(originalData).map((sectionKey) => (
                                Object.keys(originalData[sectionKey]).map((categoryKey) => (
                                    <MenuItem key={`${sectionKey}-${categoryKey}`} value={categoryKey}>{categoryKey}</MenuItem>
                                ))
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl margin='normal'>
                        <InputLabel>Select Particulars</InputLabel>
                        <Select value={selectedParticular} onChange={(e) => setSelectedParticular(e.target.value)} label='Select Particulars'
                        MenuProps={{ PaperProps: { style: { overflowY: "auto", width: "200px"}}}}>
                            {selectedItem && Object.keys(originalData).map((sectionKey) => (
                                originalData[sectionKey][selectedItem]?.map((item, index) => (
                                    <MenuItem key={index} value={item.PARTICULARS}>{item.PARTICULARS}</MenuItem>
                                ))
                            ))}
                        </Select>
                    </FormControl>

                    <div {...getRootProps({ className: 'dropzone',onclick:()=>setFileError('') })} className='file-upload-field'>
                        <input {...getInputProps()} />
                        {uploadedFiles?.length > 0 ? (
                            <ul style={{ padding: 0, listStyleType: 'none' }}>
                                {uploadedFiles.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                ))}
                            </ul>
                        ) : (
                            <Typography>Drag & Drop files here, or click to select files</Typography>
                        )}
                    </div>

                    {fileError && (
                        <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
                            {fileError}
                        </Typography>
                    )}

                    {uploadedProofs.some((proof) => proof.files.length > 0) && (
                        <Paper sx={{ padding: "1px 0px 1px 0px" ,mt:'15px'}}>
                            <Grid container sx={{ backgroundColor: '#f0f0f0', fontWeight: 'bold',padding:1 }}>
                                <Grid item xs={3}><Typography variant='subtitle1'>Items</Typography></Grid>
                                <Grid item xs={5}><Typography variant='subtitle1'>Particulars</Typography></Grid>
                                <Grid item xs={3}><Typography variant='subtitle1'>File Name</Typography></Grid>
                                <Grid item xs={1}><Typography variant='subtitle1'>Action</Typography></Grid>
                            </Grid>
                            <Divider />
                            <List>
                                {uploadedProofs.flatMap((proof) => proof.files.map((file) => (
                                    <ListItem key={file.fileId} divider>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item xs={3}><Typography variant='body2'>{proof.items}</Typography></Grid>
                                            <Grid item xs={5}><Typography variant='body2'>{proof.particulars}</Typography></Grid>
                                            <Grid item xs={3}>
                                                <Link className='link' component='button' onClick={() => handleDownload(file)} title={file.filename}>
                                                    {file.filename}
                                                </Link>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton edge='end' onClick={() => handleOpenDeleteDialog(file)} sx={{ color: 'red' }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                )))}
                            </List>
                        </Paper>
                    )}
                </DialogContent>
                <DialogActions className='dialogActions'>
                    <Button onClick={onClose} variant='outlined' color='primary'>Cancel</Button>
                    <Button variant='contained' color='primary' disabled={!uploadedFiles || !selectedItem || !selectedParticular || fileError } onClick={handleSave}>Upload</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ITDUploadProofOfSubmission;