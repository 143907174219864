import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Button, useTheme, TextField, InputAdornment, IconButton, Switch, Checkbox ,Tooltip ,LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { BorderColor as BorderColorIcon, Person as PersonIcon, PersonAdd as PersonAddIcon, Search as SearchIcon, ImportExport as ImportExportIcon, ManageSearch as ManageSearchIcon, Devices as DevicesIcon  } from '@mui/icons-material';
import { getAllEmployees } from '../../services/employeeService';
import { SvgIcons } from '../../assets/icons/SvgIcons';
import * as XLSX from 'xlsx';
import AddEmployee from './AddEmployee';
import StatusSwitch from '../../assets/icons/StatusSwitch'; 
import { PAGE_SIZE_OPTIONS, ROWS_PER_PAGE, STATUS, USER_ROLES  } from "../../Constants";
import EditReportingTo from './EditReportingTo';
import { getRoleNameById } from '../../services/UserRolesServices';

function ManageEmployee() {
  const theme = useTheme();
  const [isAddClicked, setIsAddClicked] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editEmployee, setEditEmployee] = useState(null);
  const [statusSort, setStatusSort] = useState(true);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchIdCardProvided, setSearcIdCardProvided] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(true);
  const [loggedInUserRole, setLoggedInUserRole] = useState(null);
  const [employeeUpdated, setEmployeeUpdated] = useState(false);
  const navigate = useNavigate();
  const [isReportingToClicked, setIsReportingToClicked] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [reportingTo, setReportingTo] = useState('');

  function CustomNoRowsOverlay(message) {
    return (
      <div xs={12} style={{ textAlign: "center", paddingTop: "50px" }}>
        <Typography variant="h6" gutterBottom>
          {message}
        </Typography>
      </div>
    );
  }

  useEffect(() => {
    fetchEmployees();
  }, [employeeUpdated,statusSort]);

  const fetchEmployees = async () => {
    try {
      const response = await getAllEmployees("", statusSort);
      setEmployees(response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setLoading(false);
    }
  }

  const fetchLoggedInUserRole= async () => {
    try {
      const roleId = localStorage.getItem("roleId");
      const response = await getRoleNameById(roleId);
      setLoggedInUserRole(response.roleName);
    } catch (error) {
      console.error("Error fetching logged-in user role:", error);
    }
    setLoading(false);
  };

  useEffect(()=> {
    fetchLoggedInUserRole();
  },[])

  useEffect(() => {
    if (searchTerm.length >= 3) {
      handleSearch(searchTerm);
    } else {
      setFilteredEmployees(employees);
    }
    // fetchLoggedInUserRole();
  }, [searchTerm, employees]);

  const handleSearch = (term) => {
    const lowercasedTerm = term.toLowerCase();
    const filtered = employees.filter(employee =>
      (employee.firstName && employee.firstName.toLowerCase().includes(lowercasedTerm)) ||
      (employee.emailId && employee.emailId.toLowerCase().includes(lowercasedTerm))
    );
    setFilteredEmployees(filtered);
  };
  
  const formatDate = (isoDateString) => {
    if (!isoDateString) return '';

    const date = parseISO(isoDateString);
    return format(date, 'dd-MM-yyyy');
  };

  const exportToExcel = () => {
    const data = employees.map((row) => ({
      'Emp Id': row.employeeNumber,
      'Employee Name': row.firstName +" " +row.lastName,
      'Email': row.emailId,
      'Gender': row.gender,
      'Date of Birth': formatDate(row.dateOfBirth),
      'UAN': row.uan,
      'Reporting To': `${row.reportingTo?.firstName || ''} ${row.reportingTo?.lastName || ''}`,
      'Designation': row.designation,
      'Joining Date': formatDate(row.dateOfJoining),
      'Emp ID Issued' : row.idCardProvided ? 'Yes' :'No'
    }));
  
    const worksheetName = "Employee List";
    const fileName = 'Employee_List.xlsx';

    // Create a new workbook
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
  
    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
  
    // Export to Excel file
    XLSX.writeFile(workbook, fileName);
    NotificationManager.success('Employees List exported successfully');
  };

  const columns = [
    {
      field: "employeeNumber",
      headerName: <strong>Emp Id</strong>,
      width: 100,
    },
    { field: "firstName", headerName: <strong>First Name</strong>, width: 180 },
    { field: "lastName", headerName: <strong>Last Name</strong>, width: 150 },
    { field: "emailId", headerName: <strong>Email</strong>, width: 300 },
    {
      field: "designation",
      headerName: <strong>Designation</strong>,
      width: 250,
    },
    {
      field: "dateOfJoining",
      headerName: <strong>Joining Date</strong>,
      width: 180,
      valueGetter: (params) => formatDate(params),
    },
    // {
    //   field: "activeStatus",
    //   headerName: <strong>Status</strong>,
    //   width: 150,
    //   renderCell: (params) => (
    //     <span>
    //       {params.value ? (
    //         <SvgIcons marginTop="8px" fillColor={STATUS.ACTIVE_COLOR} text={STATUS.ACTIVE} />
    //       ) : (
    //         <SvgIcons marginTop="8px" fillColor={STATUS.INACTIVE_COLOR} text={STATUS.INACTIVE} />
    //       )}
    //     </span>
    //   ),
    // },
    {
      field: "actions",
      headerName: <strong> Actions </strong>,
      type: "actions",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params) => {
        const hasAssignedAssets =
          params.row.assignedAssetsIds &&
          params.row.assignedAssetsIds.length > 0;

        return [
          loggedInUserRole && loggedInUserRole !== USER_ROLES.MANAGER ? (
          <Tooltip title="Edit" arrow>
            <BorderColorIcon
              onClick={() => {
                handleEditPopup(params.row);
              }}
              style={{ cursor: "pointer", marginRight: 6 }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title="Reporting to"
            arrow
          >
            <BorderColorIcon onClick={() =>{handleReportingToClick(params.row)}}/>
          </Tooltip>
        ),
          <Tooltip title="Update Employee Profile" arrow>
            <PersonIcon
              onClick={() => {
                handlePersonAction(params.row);
              }}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>,
          <Tooltip
            title={
              hasAssignedAssets
                ? "View Employee's Asset Details"
                : "No Assets Assigned"
            }
            arrow
            key="assets"
          >
            <DevicesIcon
              onClick={() => {
                if (hasAssignedAssets) handleAssetsAction(params.row);
              }}
              style={{
                cursor: hasAssignedAssets ? "pointer" : "not-allowed",
                opacity: hasAssignedAssets ? 1 : 0.5,
              }}
            />
          </Tooltip>,
        ].filter(Boolean);
      },
    },
  ];  

  const handleEditPopup = (employee) => {
    setEditEmployee(employee._id);
    setIsAddClicked(true);
  };

  const handleAddPopup = () => {
    setEditEmployee(null);
    setIsAddClicked(true);
  }

  const handlePersonAction = (employee) => {
    navigate(`/updateprofile/${employee._id}`);
  };

  const handleAssetsAction = (employee) => {
    navigate(`/updateprofile/${employee._id}`, { state: { tabIndex: 2 } });
  };

  const filteredDatas = filteredEmployees.filter((employee) => employee.activeStatus === statusSort);

  const handleManageSearchClick = () => {
    setIsAllChecked(true);
    setShowAdvancedSearch(true);
  };

  const handleCancel = () => {
    setShowAdvancedSearch(false);
    setSearcIdCardProvided(false);
  }

  const handleAdvancedSearch = () => {
    if (isAllChecked) {
      setFilteredEmployees(employees);
    } else {
      const filtered = employees.filter(employee => {
        const nameMatches = searchName
          ? employee.firstName && employee.firstName.toLowerCase().includes(searchName.toLowerCase())
          : true;
  
        const emailMatches = searchEmail
          ? employee.emailId && employee.emailId.toLowerCase().includes(searchEmail.toLowerCase())
          : true;
  
        const idCardProvidedMatches = searchIdCardProvided !== undefined
          ? (employee.idCardProvided === true) === searchIdCardProvided
          : true;
  
        return nameMatches && emailMatches && idCardProvidedMatches;
      });
      setFilteredEmployees(filtered);
    }
    
    setShowAdvancedSearch(false);
    setSearcIdCardProvided(false);
    setSearchName('');
    setSearchEmail('');
  };

  const handleAllChecked = (e) => {
    setIsAllChecked(e.target.checked);
    if (e.target.checked) {
      setSearchName('');
      setSearchEmail('');
      setSearcIdCardProvided(false);
    }
  };

  const handleReportingToClick = (employee) =>{
    setSelectedEmployee(employee._id);
    setReportingTo(employee.reportingTo._id);
    setIsReportingToClicked(true);
  }
 
  return (
    <Box className="main-content main-content-height">
      <Grid container className="grid-container-header align-header-center">
        <Grid item xs={3}>
          All Employees
        </Grid>
        <Grid container xs={9} className="align-filters-right">
          <div className="search-textbox">
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search Employees"
              value={searchTerm}
              onChange={(e) => {
                const value = e.target.value.trimStart();
                if (value.length <= 50) {
                  setSearchTerm(value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" sx={{ mr: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            />
            <IconButton onClick={handleManageSearchClick}>
              <ManageSearchIcon />
            </IconButton>

            {/* Dropdown for advanced search options */}
            {showAdvancedSearch && (
              <Box
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  marginTop: "5px",
                  padding: "10px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                  zIndex: 1001,
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <div style={{ display: "flex", gap: "39px" }}>
                  <Typography variant="body1" style={{ paddingTop: "6px" }}>
                    All
                  </Typography>
                  <Checkbox
                    checked={isAllChecked}
                    onChange={handleAllChecked}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div style={{ display: "flex", gap: "30px" }}>
                  <Typography variant="body1" style={{ paddingTop: "6px" }}>
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    style={{ marginBottom: "20px" }}
                    value={searchName}
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                    disabled={isAllChecked}
                  />
                </div>
                <div style={{ display: "flex", gap: "32px" }}>
                  <Typography variant="body1" style={{ paddingTop: "6px" }}>
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    style={{ marginBottom: "20px" }}
                    value={searchEmail}
                    onChange={(e) => {
                      setSearchEmail(e.target.value);
                    }}
                    disabled={isAllChecked}
                  />
                </div>
                <div style={{ display: "flex", gap: "30px" }}>
                  <Typography variant="body1" style={{ paddingTop: "8px" }}>
                    Id Card Issued
                  </Typography>
                  <Switch
                    checked={searchIdCardProvided}
                    onChange={(e) => setSearcIdCardProvided(e.target.checked)}
                    disabled={isAllChecked}
                    size="small"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleAdvancedSearch}>
                    Search
                  </Button>
                </div>
              </Box>
            )}
          </div>
          <Box className="filter-status-toggle">
            <StatusSwitch
              checked={statusSort}
              onChange={(e) => setStatusSort(e.target.checked)}
            />
          </Box>
          <Button
            variant="contained"
            disabled={!filteredDatas?.length}
            onClick={exportToExcel}
            startIcon={<ImportExportIcon />}
            sx={{
              whiteSpace: "nowrap",
              minWidth: { xs: "143px", sm: "auto" },
            }}
          >
            Export Employees
          </Button>
         {!loading && loggedInUserRole !== USER_ROLES.MANAGER && (
          <Button
            variant="contained"
            onClick={handleAddPopup}
            startIcon={<PersonAddIcon />}
            sx={{
              whiteSpace: "nowrap",
              minWidth: { xs: "130px", sm: "auto" },
            }}
          >
            Add Employee
          </Button>
        )}
        </Grid>
      </Grid>
      <Grid container padding={2} style={{ flexGrow: 1,overflow: 'hidden', backgroundColor: "#fff" }}>
        <Grid item xs={12} style={{ height: "100%" }}>
          <DataGrid
            localeText={{
              noRowsLabel: "No Employee available. Please add a new employee.",
            }}
            rows={filteredDatas}
            columns={columns}
            initialState={{ pagination: { paginationModel: { pageSize: ROWS_PER_PAGE } } }}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            getRowId={(row) => row._id}
            components={{
              NoRowsOverlay: () => CustomNoRowsOverlay("No Rows found."),
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
            sx={{
              "& .MuiDataGrid-selectedRowCount": {
                display: "none",
              },
            }}
          />
        </Grid>
      </Grid>
    {loggedInUserRole !== USER_ROLES.MANAGER ? ( 
      isAddClicked &&
      <AddEmployee
        isAddClicked={isAddClicked}
        handleCloseDialog={() => setIsAddClicked(false)}
        employeeIdToEdit={editEmployee}
        setEmployeeUpdated={setEmployeeUpdated}
      />
    ) : (
      <EditReportingTo 
        isReportingToClicked={isReportingToClicked} 
        handleCloseReportingToDialog={() => setIsReportingToClicked(false)} 
        selectedEmployee={selectedEmployee} 
        initialReportingTo={reportingTo}
      />
    )}
    </Box>
  );
}
export default ManageEmployee;