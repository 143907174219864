import React, { useEffect, useContext, useState } from 'react';
import { Avatar, Box, Divider, Grid, IconButton, Link, Menu, Tooltip, Typography, MenuItem} from "@mui/material";
import { ExitToApp as ExitToAppIcon, Person as PersonIcon } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { getProfileDtl } from '../../services/employeeService';
import PhyelementsLogo from '../../assets/logos/phyelements_logo.png';
import ProfileImageContext from '../../context/ProfileImageContext';
import { fetchLatestVersion } from '../../services/buildVersionServices';

const Header = ({ setBackDropLoading }) => {
  const employeeId = localStorage.getItem("employeeId");
  const navigate = useNavigate();

  const [profile, setProfile] = useState([]);
  const [profileUrl, setProfileUrl] = useState();
  const { profileImage } = useContext(ProfileImageContext);
  const [buildVersion, setBuildVersion] = useState("");
  const [anchorElUser, setAnchorElUser] = useState(null);

  const goLogout  =() => {
    setBackDropLoading(true);
    sessionStorage.clear();
    localStorage.clear();
    var newWindow = window.open('https://accounts.google.com/Logout','Disconnect from Google','menubar=no,status=no,location=no,toolbar=no,scrollbars=no,top=200,left=200');
    setTimeout(function(){
      if (newWindow) newWindow.close();
      navigate('/login')
  },4000);

  }
  
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const goProfile  =() => {
    navigate('/updateProfile/:id');
  }

  useEffect(()=>{
    setTimeout(() => {
      getUserProfileDetail();    
      fetchVersion(); 
    }, 3000);
    
    
  },[])

  const fetchVersion = async () => {
    try {
      const version = await fetchLatestVersion();
      if (version) {
        setBuildVersion(version.versionNumber);
      }
      else {
        setBuildVersion("1.0.0");
      }
    } catch (error) {
      console.error('Error fetching build version:', error);
    }
  };

  const getUserProfileDetail = async () => {

    const response = await getProfileDtl(employeeId);
    //  if (response.photo){
    //   const url = await getFileById(response.photo.fileId);
    //   setProfileUrl(url);
    //  }
    const photoUrl = response.photo?.base64Data ? `data:${response.photo.contentType};base64,${response.photo.base64Data}` : null;
    setProfileUrl(photoUrl);
    setProfile(response);
  }

  return (
    <div style={{ width: "100%" }}>
      <Grid container >
        <Grid item xs={4} md={2} >
          <img alt="phyelements" src={PhyelementsLogo} style={{ width: "200px", height: "auto", paddingTop: "10px" }} />
        </Grid>
        <Grid item xs={6} md={8} className='header-center'>
          phye HRMS Portal
        </Grid>

        <Grid item xs={2} md={1} sx={{ position: 'fixed', right: { xs: 15, sm: 25, md: 40, lg: 60 }, top: 15, textAlign: "right" }}>
          <Tooltip title="Profile" arrow>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar src={profileImage || profileUrl} sx={{ img: { objectPosition: 'top' } }}></Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            disableScrollLock
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            PaperProps={{
              style: {
                width: '250px',
              },
            }}
          >
            <Typography sx={{ display: 'block', textAlign: 'center', color: "#11111", fontSize: ".9rem", fontWeight: '200' }}>
              Hello <strong>{profile.firstName} {profile.lastName}</strong>
            </Typography>
            <Typography sx={{ display: 'block', textAlign: 'center', color: "#B8B8B8", marginBottom: "10px" }}>
              {profile.emailId}
            </Typography>
            <Divider />
            <Link style={{ textDecoration: 'none' }} href='#' title='Profile' onClick={() => { handleCloseUserMenu(); goProfile() }}>
              <MenuItem >
                <PersonIcon />&nbsp;&nbsp;&nbsp;My Profile
              </MenuItem>
            </Link>
            <Link style={{ textDecoration: 'none' }} href="#" title="Logout" onClick={goLogout}>
              <MenuItem onClick={handleCloseUserMenu}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ExitToAppIcon />
                    &nbsp;&nbsp;&nbsp;Logout
                  </Box>
                  <Typography sx={{ color: "#B8B8B8", fontSize: '0.875rem' }}>
                    {buildVersion ? `v${buildVersion}` : ''}
                  </Typography>
                </Box>
              </MenuItem>
            </Link>
          </Menu>
        </Grid>
      </Grid>
    </div>
  );

}


export default Header;
