import * as React from 'react';
import { Autocomplete, Popper, Grid, Box, Typography, Button, InputLabel, MenuItem, FormControl, Select, Paper , TextField ,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme , Alert, IconButton, useMediaQuery, Tooltip } from '@mui/material';
import { getAllEmployeeNames } from '../../services/employeeService';
import * as timeTrackerService from '../../services/TimeTrackerServices';
import dayjs from 'dayjs';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import CancelIcon from '@mui/icons-material/Cancel';
import ExportPopup from './ExportPopup';
import { TIMESHEET_DROPDOWN_OPTIONS } from '../../Constants';
import ReportIcon from '@mui/icons-material/Report';

const weekDaysFull = Array(7).fill().map((_, index) => moment().day(index).format('dddd'));
const weekDaysShort = weekDaysFull.map(day => day.substring(0, 3));

export default function () {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:600px)'); 
    const months = moment.months(); 
    const currentMonth = months[new Date().getMonth()];
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 1, currentYear, currentYear + 1];
    const [employeeId, setEmployeeId] = React.useState('');
    const [month, setMonth] = React.useState(currentMonth);
    const [year, setYear] = React.useState(currentYear);
    const [activeEmployees, setActiveEmployees] = React.useState([]);
    const [timesheets, setTimesheets] = React.useState([]);
    const [updateDisabled, setUpdateDisabled] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [modifiedTimesheets, setModifiedTimesheets] = React.useState({});
    const [popupOpen, setPopupOpen] = React.useState(false);    
    const [selectedEmployeeName, setSelectedEmployeeName] = React.useState("");
    const [tooltipOpen, setTooltipOpen] = React.useState({});
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const [unsavedChanges, setUnsavedChanges] = React.useState(false);

    const [timesheetStatus , setTimesheetStatus] = React.useState('');
    const [selectedTimesheetStatus , setSelectedTimesheetStatus] = React.useState('');
    const [openApproveDialog, setOpenApproveDialog] = React.useState(false);
    
    const timesheetStatusOptions = [
        { value: 'requested', label: 'Pending Approval' },
        { value: 'approved', label: 'Approved' },
        { value: 'incomplete', label: 'Incomplete' },
        { value: 'all', label: 'All' },
      ];
    

    // React.useEffect(() => {
    //     getEmployeeNameList();
    // }, []);

    const getEmployeeNameList = async () => {
        const data = await getAllEmployeeNames();
        setActiveEmployees(data);
    }

    React.useEffect(() => {
        if (employeeId) {
            fetchTimesheets();
        }
    }, [employeeId]);

    React.useEffect(() => {
        setEmployeeId('')
        setTimesheets([]);
    }, [selectedTimesheetStatus , month , year]);

    React.useEffect(() => {
        setUnsavedChanges(Object.keys(modifiedTimesheets).length > 0);
    }, [modifiedTimesheets]);

    const fetchTimesheets = async () => {
        try {
            const data = await timeTrackerService.getTimeTrackerData(employeeId, year, month);
            setTimesheets(data.timeEntries);
            setTimesheetStatus(data.approvalRequest)
        } catch (error) {
            console.error("Error fetching time sheet data:", error);
        }
    };

    
    const getEmployees = async (status , month, year) => {
        const response = await timeTrackerService.getEmployeesByStatus(status, month.toLowerCase(), year);
        setActiveEmployees(response);
    }

    const timesheetStatusHandleChange = async (event) => {
        if (unsavedChanges) {
            setOpenConfirmDialog(true);
        }
        else {
            const selectedStatus = event.target.value;
            setSelectedTimesheetStatus(selectedStatus);
            getEmployees(selectedStatus , month, year);
            setModifiedTimesheets({});
        }
    };

    const employeeHandleChange = async (event) => {
        if (unsavedChanges) {
            setOpenConfirmDialog(true);
        }
        else {
            const selectedEmployeeId = event.target.value;
            setEmployeeId(selectedEmployeeId);
            const selectedEmployee = activeEmployees.find((emp) => emp._id === selectedEmployeeId);
            setSelectedEmployeeName(selectedEmployee ? `${selectedEmployee.firstName} ${selectedEmployee.lastName}` : "");

            // if (month && year) {
            //     const data = await timeTrackerService.getTimeTrackerData(selectedEmployeeId, year, month);
            //     setTimesheets(data);
            // }
        }
    };

    const monthHandleChange = async (event) => {
        if (unsavedChanges) {
            setOpenConfirmDialog(true);
        }
        else {
            const selectedMonth = event.target.value;
            setMonth(selectedMonth);
            if(selectedTimesheetStatus){
            getEmployees(selectedTimesheetStatus, selectedMonth,year);
            }
            
            // if (employeeId && year) {
            //     const data = await timeTrackerService.getTimeTrackerData(employeeId, year, selectedMonth );
            //     setTimesheets(data);
            // }
            setModifiedTimesheets({});
        }
    };

    const yearHandleChange = async (event) => {
        if (unsavedChanges) {
            setOpenConfirmDialog(true);
        }
        else {
            const selectedYear = event.target.value;
            setYear(selectedYear);
            if(selectedTimesheetStatus){
            getEmployees(selectedTimesheetStatus, month, selectedYear);
            }
            
            // if (employeeId && month) {
            //     const data = await timeTrackerService.getTimeTrackerData(employeeId, selectedYear, month );
            //     setTimesheets(data);
            // }
            setModifiedTimesheets({});
        }
    };

    const handleUpdateClick = () => {
        setOpenDialog(true); 
    };

    const handleConfirmUpdate = async () => {
        const updatedTimesheets = timesheets.map(entry => {
            const newHours = modifiedTimesheets[dayjs(entry.date).format('YYYY-MM-DD')];
            if (newHours !== undefined) {
                return { ...entry, hoursLogged: newHours };
            }
            return entry;
        });
        // setTimesheets(updatedTimesheets);  
        const daysToUpdate = Object.entries(modifiedTimesheets).map(([dateStr, hoursLogged]) => ({
            date: dayjs(dateStr).format('YYYY-MM-DD'),
            hoursLogged: hoursLogged && (typeof hoursLogged === 'string') ? hoursLogged.toUpperCase() : hoursLogged || null,
        }));
        const payload = {
            year: year,
            month: month,
            days: daysToUpdate,
          };
          try {
            const result = await timeTrackerService.postTimeTrackerData( employeeId, payload);
            NotificationManager.success('TimeSheet Updated Successfully');
              setTimeout(async () => {
                  const newTimesheets = await timeTrackerService.getTimeTrackerData(employeeId, year, month);
                  setTimesheets(newTimesheets.timeEntries);
              }, 100);
            
          } catch (error) {
            NotificationManager.error('Something went wrong try again ');
            console.error('Error updating timesheets:', error);
          }
        setModifiedTimesheets({}); 
        setUpdateDisabled(true);  
        setOpenDialog(false); 
    };

    const handleCancelUpdate = () => {
        setOpenDialog(false); 
    };

    const handleHoursChange = (dateStr, newHours) => {
        setModifiedTimesheets((prevState) => {
            const updatedState = {
                ...prevState,
                [dateStr]: newHours,
            };
            // if (!newHours || newHours === "") {
            //     delete updatedState[dateStr];
            // }
            return updatedState;
        });
        setUpdateDisabled(false);
    };

    const handleExportClick = () =>{
        setPopupOpen(true);
    }

    const handleClosePopup = () => {
        setPopupOpen(false);
      };

    const handleSaveChanges = () => {
        handleConfirmUpdate();
        setOpenConfirmDialog(false);
    };

    const handleCancelChanges = () => {
        setOpenConfirmDialog(false);
    };

    const handleTooltipOpen = (date) => {
        setTooltipOpen((prev) => ({
          ...prev,
          [date]: true,
        }));
      };
      
      const handleTooltipClose = (date) => {
        setTooltipOpen((prev) => ({
          ...prev,
          [date]: false,
        }));
      };
    
      const isTooltipOpen = (date) => tooltipOpen[date] || false;

    const proceedToApproval = async () => {
        try {
            const data = {
                employeeId: employeeId,
                month: month,
                year: year,
                status: 'approved'
            }
            await timeTrackerService.sendTimeSheetApprovalEmail(data);
            NotificationManager.success('The timesheet has been submitted for approval.');
            setOpenApproveDialog(false)
            if (selectedTimesheetStatus !== 'all') {
                await getEmployees('requested', month, year);
            }

            setEmployeeId('')
            setTimesheets([]);
        } catch (error) {
            NotificationManager.error('Failed to send approval For Timesheet , try again later');
        }
    }

    const renderCalendar = () => {        
        if (!timesheets.length) return <Typography>No timesheet data available.</Typography>;

        const monthIndex = months.indexOf(month);
        const startOfMonth = dayjs().year(year).month(monthIndex).startOf('month');
        const endOfMonth = dayjs().year(year).month(monthIndex).endOf('month');
        const daysInMonth = endOfMonth.date();
        const startDay = startOfMonth.day();
 
        const selectedWeekDays = isMobile ? weekDaysShort : weekDaysFull;
        const calendarDays = [];

        calendarDays.push(
            <Grid container key="header" spacing={0} >
                {selectedWeekDays.map(day => (
                    <Grid item md={1.65} xs={1.7} key={day} 
                        sx={{
                            border: '1px solid #ddd',
                            padding: 1,
                            backgroundColor: '#f5f5f5',
                        }}>
                            <Typography variant="h6" fontWeight="bold"  align="center" color={ theme.palette.primary.main}>{day}</Typography>
                    </Grid>
                ))}
            </Grid>
        );

        for (let i = 0; i < startDay; i++) {
            calendarDays.push(
                <Grid item md={1.65} xs={1.7} key={`empty-${i}`}>
                    <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', border: '1px solid transparent' }}>
                        <Typography variant="body2"></Typography>
                    </Paper>
                </Grid>
            );
        }

        for (let day = 1; day <= daysInMonth; day++) {
            
            const date = dayjs().year(year).month(monthIndex).date(day);

            const dateStr = date.format('YYYY-MM-DD');            
            const timesheetEntry = timesheets.find(entry => dayjs(entry.date, 'DD-MMM-YYYY')
                                             .isSame(dateStr, 'day'));
           
            const isWeekend = date.day() === 0 || date.day() === 6;
            const isLeave = timesheetEntry ? timesheetEntry.isLeave : false;

            calendarDays.push(
                <Grid item md={1.65} xs={1.7} key={day}>
                    <Paper elevation={2}
                        className='tt-calender-date'                        
                        sx={{
                            height: {md:"70px", sm:"50px", xs:"35px"}, 
                            backgroundColor: isLeave
                            ? "#B6FFA1" // Leave
                            : isWeekend
                            ? "#FEFFA7" // Weekend
                            : modifiedTimesheets.hasOwnProperty(dateStr)
                                ? modifiedTimesheets[dateStr] !== null
                                ? "#B6FFA1" // Unsaved filled data
                                : "#ffffff" // Unsaved removal
                                : timesheetEntry?.hoursLogged
                                ? "#B6FFA1" // Saved
                                : "#ffffff", // Initially empty
                        }}
                        onClick={(e) => {
                            if (!isWeekend) {
                               // Prevent tooltip from closing by stopping the event from bubbling
                                e.stopPropagation();
                                e.preventDefault();
                                // Focus the TextField
                                document.getElementById(`hours-textField-${day}`).focus();
                            }
                          }}
                    >
                        <Typography fontWeight="bold" sx={{
                            color: theme.palette.primary.main,
                            position: 'absolute',
                            ...(isWeekend ? {} : { bottom: 0 }),
                            // bottom: isWeekend ? null : 0,
                            // right: 0,
                            textAlign:'center',
                            margin: 0,
                            padding: {md:'0px', xs:"2px"},
                            // backgroundColor: "#cccccc",
                            // fontSize: { xs: '0.75rem', sm: '1rem' },
                            fontSize: { xs: "9px", sm: "10px", md: "14px" },
                            borderRadius: '50px'
                        }}> {day} </Typography>

                        {timesheets.map((entry) => (
                            entry.leave && entry.date === dateStr && !isWeekend && (
                            <Tooltip title={`${selectedEmployeeName} has applied leave on this day.`}
                                open={isTooltipOpen(dateStr)}
                                onClose={() => handleTooltipClose(dateStr)}
                                onOpen={() => handleTooltipOpen(dateStr)}
                                leaveTouchDelay={10000}
                                disableInteractive 
                                arrow
                            >
                                <IconButton
                                    onClick={(e) =>{
                                    e.stopPropagation();
                                    setTooltipOpen((prev) => ({
                                        ...prev,
                                        [dateStr]: !isTooltipOpen(dateStr),
                                    }))
                                    }}
                                    onMouseEnter={() => handleTooltipOpen(dateStr)}
                                    onMouseLeave={() => handleTooltipClose(dateStr)}
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                    }}
                                    size="small"
                                >
                                    <ReportIcon  sx={{ color: '#1976d2' , fontSize: {xs: '0.7rem', sm: '1rem'} }} />
                                </IconButton>
                                
                            </Tooltip>
                          ))
                        )}

                        { !isWeekend 
                        ? (
                        <>
                            <Autocomplete
                                freeSolo
                                options={TIMESHEET_DROPDOWN_OPTIONS}
                                id = {`hours-textField-${day}`}
                                value={modifiedTimesheets[dateStr] ?? timesheetEntry?.hoursLogged ?? ''}
                                onChange={(e, newValue) => handleHoursChange(dateStr, newValue)}
                                onInputChange={(e, newInputValue) => {
                                if (e?.type === 'change')
                                if (/^(CH|ch|LT|lt|[4-8]?)$/.test(newInputValue)) {
                                    // Retain 'CH', 'LT' as uppercase strings; convert numbers to integers
                                    const normalizedValue = isNaN(newInputValue) 
                                        ? newInputValue.toUpperCase() // Strings: 'CH', 'LT'
                                        : parseInt(newInputValue); // Numbers: 4-8
                                    handleHoursChange(dateStr, normalizedValue);
                                } else {
                                    // Clear input if invalid to prevent incorrect entries
                                    handleHoursChange(dateStr, '');
                                    console.error("Invalid input: Only numbers 4-8, 'CH', 'ch', 'LT', or 'lt' are allowed.");
                                }
                                }}
                                PopperComponent={(props) => (
                                    <Popper
                                        {...props}
                                        style={{
                                        width: { xs: "10%", sm: "5%" }
                                        }}
                                    />
                                )}
                                ListboxProps={{
                                style: {
                                    border:"1px solid grey",
                                    maxHeight: "160px",
                                    overflowY: "auto",
                                    padding: 0
                                },
                                }}
                                getOptionLabel={(option) => String(option)}
                                sx={{
                                "& .MuiAutocomplete-input": {
                                    minWidth: {xs: "25px !important", sm: '38px !important',},
                                    paddingTop: "7.5px !important",
                                    paddingBottom: "7.5px !important",
                                    paddingLeft: { xs: "0px !important", sm: "4px" },
                                    paddingRight: { xs: "0px !important", sm: "4px" },
                                },
                                }}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id = {`hours-textField-${day}`}
                                    variant="outlined"
                                    InputProps={{
                                    ...params.InputProps,
                                    // Remove the clear icon by passing null
                                    endAdornment: null,
                                    }}
                                    onKeyDown={(e) => {
                                        // Allow backspace, delete, tab, enter, numbers (4-8), and 'C', 'H', 'L', 'T'
                                        if (!/[4-8]|Backspace|Delete|Tab|Enter|[cchlt]/i.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    sx={{
                                    border: "1px solid #cccccc",
                                    borderRadius: '0.3rem',
                                    width: "100%",
                                    maxWidth: { xs: "30px", sm: "40px" },
                                    marginBottom: {xs:'10px', sm:"5px"},
                                    height: { xs: '25px', sm: '35px' },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                        border: "none",
                                        },
                                    },
                                    "& .MuiInputBase-input": {
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: { xs: '0.75rem', sm: '1rem' },
                                        textTransform: "uppercase",
                                    },
                                    
                                    }}
                                />
                                )}
                            />
                        </>
                     ) : (
                        <Typography variant="body2"></Typography> 
                    )} 
                    </Paper>
                </Grid>
            );

            if ((startDay + day) % 7 === 0) {
                calendarDays.push(<Grid item xs={12} key={`break-${day}`} />);
            }
        }        

        return (
            <Grid container spacing={0}>
                {calendarDays}
                <Grid item xs={11.9} sm={11.9} md={11.55} marginTop={2} marginBottom={ {xs: 1, sm: 1, md: 0} } textAlign="right">
                <Button variant ="contained" disabled={updateDisabled || timesheetStatus == 'approved'} onClick={handleUpdateClick} style={{marginRight:"20px"}}> Update</Button>
                <Button variant ="contained" disabled={timesheetStatus == 'approved' || timesheetStatus !== 'requested'} onClick={()=>setOpenApproveDialog(true)} > Approve</Button>
                </Grid>
            </Grid>
        );
    };

    return (
        <Box className = 'main-content'>            
                <Grid container className='grid-container-header align-header-center'>
                    <Grid item xs={2}>
                        All Timesheets
                    </Grid>
                    <Grid container xs={10} className='align-filters-right'>
                    <FormControl size="small">
                        <InputLabel id="timesheet-select-label">Timesheet Status</InputLabel>
                            <Select
                                  labelId="timesheet-select-label"
                                  id="timesheet-select"
                                  value={selectedTimesheetStatus}
                                  label="Timesheet Status"
                                  onChange={timesheetStatusHandleChange}
                                  sx={{ minWidth: '180px' }}
                            >
                                {timesheetStatusOptions.map((status) => (
                                    <MenuItem key={status.value} value={status.value}>{status.label}</MenuItem>  ))}
                            </Select>
                        </FormControl>
                        <FormControl size="small">
                        <InputLabel id="month-select-label">Select Month</InputLabel>
                            <Select
                                  labelId="month-select-label"
                                  id="month-select"
                                  value={month}
                                  label="Select Month"
                                  onChange={monthHandleChange}
                            >
                                {months.map((month) => (
                                    <MenuItem key={month} value={month}>{month}</MenuItem>  ))}
                            </Select>
                        </FormControl>
                        <FormControl size="small">
                            <InputLabel id="year-select-label">Select Year</InputLabel>
                            <Select
                                labelId="year-select-label"
                                id="year-select"
                                value={year}
                                label="Select Year"
                                onChange={yearHandleChange}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl size="small">
                            <InputLabel id="demo-simple-select-label">Select Employee</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={employeeId}
                                label="Select Employee"
                                onChange={employeeHandleChange}
                                sx={{ minWidth: '200px' }}
                                disabled={!selectedTimesheetStatus}
                            >
                                {activeEmployees?.map((row) => (
                                    <MenuItem value={row._id}>{row.firstName} {row.lastName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button variant="contained" onClick={handleExportClick}>Export To Excel</Button>
                    </Grid>
                </Grid>
                <Grid container rowGap={1} marginBottom={4}>
                    <Grid item md={10} xs={12} padding={1}>
                        <Paper elevation={3} sx={{ padding: { md: 3 } }}>
                            {renderCalendar()}
                        </Paper>
                    </Grid>
                    <div style={{display:"flex", flexDirection:"column"}}>
                   
                    </div>
                    <Grid item md={2} xs={12} padding={1}>
                        <Paper elevation={2} sx={{ padding: 2 }}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>Legend</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <Box className='timetracker-legend tt-filled-color' />
                                <Typography variant="body2">Filled</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <Box className='timetracker-legend tt-weekend-color' />
                                <Typography variant="body2">Weekend</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <Box className='timetracker-legend tt-workingday-color' />
                                <Typography variant="body2">Working Day</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                <Dialog
                    open={openDialog}
                    onClose={handleCancelUpdate}
                >
                    <DialogTitle className="confirmDialogTitle">Update Hours Confirmation <IconButton aria-label="close" onClick={handleCancelUpdate}><CancelIcon /></IconButton></DialogTitle>
                    <DialogContent className='confirmDialogContent'>
                        <Alert severity='warning' className='confirmDialogMsg'>You are about to overwrite the existing hours. Do you want to continue? </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleCancelUpdate} color="primary"> No </Button>
                        <Button variant="contained" onClick={handleConfirmUpdate} color="primary" autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>

            <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <DialogTitle className="confirmDialogTitle" >Confirmation  <IconButton aria-label="close" onClick={handleCancelChanges}><CancelIcon /></IconButton></DialogTitle>
                <DialogContent className='confirmDialogContent'>
                    <Alert severity='warning' className='confirmDialogMsg' >Cannot proceed as there are unsaved changes. Please save the changes to proceed?</Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelChanges} variant='outlined'>Cancel</Button>
                    <Button onClick={handleSaveChanges} variant='contained'>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openApproveDialog} onClose={() => setOpenApproveDialog(false)} >
                <DialogTitle className="confirmDialogTitle"> Timesheet Approval Confirmation </DialogTitle>
                <DialogContent className='confirmDialogContent'>
                    <Alert severity='warning' className='confirmDialogMsg'>
                        Once you approve this timesheet, no further changes can be made. Are you sure you want to proceed?
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="primary" onClick={() => setOpenApproveDialog(false)}> Cancel </Button>
                        <Button variant='contained' color="primary" onClick={proceedToApproval}> Proceed </Button>
                </DialogActions>
            </Dialog>
                <ExportPopup open={popupOpen} handleClose={handleClosePopup}/>
        </Box>
    );
}