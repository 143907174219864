import React, { useState, useEffect } from 'react';
import { Alert, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography, useTheme, LinearProgress } from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CancelIcon from '@mui/icons-material/Cancel';
import { NotificationManager } from "react-notifications";
import { createEmployee, updateEmployee, getEmployeesByRole, getEmployeeByIdToEdit } from '../../services/employeeService';
import { fetchUserRoles } from '../../services/UserRolesServices';
import { USER_ROLES, STATUS } from '../../Constants';

function AddEmployee({ isAddClicked, handleCloseDialog ,employeeIdToEdit ,setEmployeeUpdated}) {
  const theme = useTheme();
  const [employeeId, setEmployeeId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [employeeEmailId, setEmployeeEmailId] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [designation, setDesignation] = useState('');
  const [uan, setUan] = useState('');
  const [selectedRole, setSelectedRole] = React.useState('');
  const [activeStatus, setActiveStatus] = useState(true);
  const [reportingTo, setReportingTo] = useState('');
  const [reportingRoles, setReportingRoles] = useState([]);
  const [roleConfirmPopup, setRoleConfirmPopup] = useState(false);
  const [userRoles, setUserRoles] = React.useState([]);
  const [gender, setGender] = React.useState('');
  const [leavingDate, setLeavingDate] = useState(null);
  const [employeeData, setEmployeeData] = useState(null);
  const [employeeIdErrorMessage, setEmployeeIdErrorMessage] = useState('');
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const [emailError, setEmailError] = React.useState(null);
  const [designationErrMsg, setDesignationErrMsg] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  
  const getUserroles = async () => {
    const data = await fetchUserRoles("dropdown");
    setUserRoles(data);

    if (data && data.length > 0) {
      const employeeRoleId = data.find((d) => d.userRole == USER_ROLES.EMPLOYEE)?._id;
      setSelectedRole(employeeRoleId);
    }
  };

const getReportingToList = async () =>{
  const data = await getEmployeesByRole();
  setReportingRoles(data);
  // setReportingTo('');
}

const getEmployeeData = async () => {
  try {
    const response = await getEmployeeByIdToEdit(employeeIdToEdit);
    setEmployeeData(response);
  } catch (error) {
    console.error("Error fetching employee data:", error);
  }
}

useEffect(()=>{  
    getUserroles();
    getReportingToList();
},[]);

useEffect(() => {
  if (employeeIdToEdit) {
    getEmployeeData();
  } else {
    // Reset form when creating a new employee
    resetfields();
    setEmployeeData(null);
  }
}, [employeeIdToEdit, isAddClicked]);

  useEffect(() => {
    if (employeeData ) {
      setEmployeeId(employeeData.employeeNumber || '');
      setFirstName(employeeData.firstName || '');
      setLastName(employeeData.lastName || '');
      setEmployeeEmailId(employeeData.emailId || '');
      setSelectedDate(employeeData.dateOfJoining ? dayjs(employeeData.dateOfJoining) : null);
      setGender(employeeData.gender || '');
      setDesignation(employeeData.designation || '');
      setUan(employeeData.uan || '');
      setActiveStatus(employeeData.activeStatus ? true : false);
      setSelectedRole(employeeData.assignedRoleId?._id || '');
      setReportingTo(employeeData.reportingTo || '');
      setLeavingDate(employeeData.dateOfLeaving ? dayjs(employeeData.dateOfLeaving) : null);
      setDateOfBirth(employeeData.dateOfBirth ? dayjs(employeeData.dateOfBirth) : null)
    } 
  }, [employeeData]);

  const handleEmployeeId = (e) => {
    const id = e.target.value;
    setEmployeeId(id);
    if (!id) {
      setEmployeeIdErrorMessage("required");
    } else {
      if (/^[a-zA-Z0-9]+$/.test(id)) {
        setEmployeeId(id);
        setEmployeeIdErrorMessage("");
      } else {
        setEmployeeIdErrorMessage("invalid id!!");
      }
    }
  };

  const handleFirstName = (e) => {
    const fName = e.target.value.trimStart();
    setFirstName(fName);
    if (!fName) {
      setFirstNameErrorMessage("required");
    } else {
      if (/^[a-zA-Z\s'-]+$/.test(fName)) {
        setFirstName(fName);
        setFirstNameErrorMessage("");
      } else {
        setFirstNameErrorMessage("invalid first name!!");
      }
    }
  };

  const handleLastName = (e) => {
    const lName = e.target.value.trimStart();
    setLastName(lName);
    if (!lName) {
      setLastNameErrorMessage("required");
    } else {
      if (/^[a-zA-Z\s'-]+$/.test(lName)) {
        setLastName(lName);
        setLastNameErrorMessage("");
      } else {
        setLastNameErrorMessage("invalid last name!!");
      }
    }
  };


  const handleEmailChange = (event) => {
    setEmployeeEmailId(event.target.value);
    setEmailError(null);
  };

  const handleEmailBlur = () => {
    if (employeeEmailId.trim() === '') {
      setEmailError('Email is required');
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(emailRegex.test(employeeEmailId) ? '' : 'Invalid email format');
    }
  };  

  const handleJoiningDate = (selectedDate) => {
    setSelectedDate(selectedDate);
  }

  const handleLeavingDate = (leavingDate) => {
    const leavingDateTimestamp = leavingDate ? leavingDate.valueOf() : 0;
    const selectedDateTimestamp = selectedDate ? selectedDate.valueOf() : 0;
  
    if (leavingDateTimestamp > selectedDateTimestamp) {
       
      setLeavingDate(leavingDate);
      setErrorMessage('');
    } else {
      setErrorMessage('Date of leaving must be greater than the joining date');
    }
  };

  const handleDesignation = (e) => {
    const designation = e.target.value;
    setDesignation(designation);
    if (!designation) {
      setDesignationErrMsg("");
    } else {
      if (/^[a-zA-Z\s'-]+$/.test(designation)) {
        setDesignation(designation);
        setDesignationErrMsg("");
      } else {
        setDesignationErrMsg("invalid, only alphabets are allowed!");
      }
    }
  };

  const handleUan = (e) => {
    const uan = e.target.value;
    setUan(uan);
  };

  const handleChange = (event) => {
    const selectedRole = event.target.value;
    const selectedRoleName = userRoles.find(role => role._id === selectedRole)?.userRole;
    if (selectedRoleName !== USER_ROLES.EMPLOYEE) {
      setRoleConfirmPopup(true);
      setSelectedRole(selectedRole);
    } else {
      setSelectedRole(selectedRole);
    }
  };

  const handleConfirmPopupContinue = () => {
    setSelectedRole(selectedRole);
    setRoleConfirmPopup(false);
  };

  const handleConfirmPopupClose = () => {
    setSelectedRole(userRoles.find(data => data.userRole == USER_ROLES.EMPLOYEE)?._id);
    setRoleConfirmPopup(false);
  };

  const handleReportingChange = (event) => {
    setReportingTo(event.target.value);
  };

  const handleActiveStatusChange = (e) => {
    setActiveStatus(e.target.checked);
  };

  const handleSave = async () => {
    if(loading) return;
    const EmployeeId = employeeId;
    const EmailId = employeeEmailId.trim().toLowerCase();
    const employeeDataToSave = {
      employeeNumber: EmployeeId,
      firstName:firstName.trim(),
      lastName:lastName.trim(),
      emailId: EmailId,
      dateOfJoining: selectedDate.toISOString(),
      gender: gender,
      activeStatus: activeStatus,
      designation: designation,
      uan: uan,
      assignedRoleId: selectedRole,
      reportingTo: reportingTo,
      dateOfBirth: dateOfBirth ? dateOfBirth.toISOString() : null,
    }
    if (leavingDate) {
      employeeDataToSave.dateOfLeaving = leavingDate.toISOString();
  }
    try{
      setLoading(true)
      if (employeeData) {        
        await updateEmployee(employeeData._id, employeeDataToSave);
        setEmployeeUpdated((prev) => !prev);
        NotificationManager.success("Employee Updated Succesfully");
      } else {
        const response = await createEmployee(employeeDataToSave);
        setEmployeeUpdated((prev) => !prev);
        NotificationManager.success(response.message);
      }
      handleClose();
    } catch (error) {
      console.error("error",error);
      NotificationManager.error(error.response.data.message);
    }finally{
      setLoading(false); 
    }
  };

  const resetfields = () => {
    setEmployeeIdErrorMessage('');
    setFirstNameErrorMessage('');
    setLastNameErrorMessage('');
    setEmployeeId('');
    setFirstName('');
    setLastName('');
    setEmployeeEmailId('');
    setEmailError('');
    setSelectedDate(null);
    setGender('');
    setDesignation('');
    setUan('');
    setDesignationErrMsg('');
    setActiveStatus(true);
    setSelectedRole(userRoles.find(data => data.userRole == USER_ROLES.EMPLOYEE)?._id);
    setReportingTo('');
    setLeavingDate(null);
    setErrorMessage('')
    setDateOfBirth(null)
  }
  const handleClose = () => {
    resetfields();
    handleCloseDialog(false);
  };

  const selectedRoleName = userRoles.find((role) => role._id === selectedRole)?.userRole || '';

  return (
    <Dialog
      open={isAddClicked}
      onClose={handleClose}
      PaperProps={{
        sx: { width: '400px' }, className: 'dialog'
      }}
    >
      <DialogTitle className= 'dialogTitle'>
        <strong>{employeeData ? 'Edit Employee' : 'Add Employee'}</strong>
        <IconButton aria-label="close" onClick={handleClose} className='dialogCloseIcon'>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dc-bottom-padding'>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            required
            fullWidth
            id="employeeId"
            label="Id"
            margin="normal"
            error={employeeIdErrorMessage}
            name="employeeId"
            onChange={(e) => handleEmployeeId(e)}
            onBlur={handleEmployeeId}
            value= {employeeId}
            helperText = {employeeIdErrorMessage}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            required
            fullWidth
            id="firstName"
            label="First Name"
            margin="normal"
            error={firstNameErrorMessage}
            name="firstName"
            value= {firstName}
            onChange={(e) => handleFirstName(e)}
            onBlur={handleFirstName}
            helperText = {firstNameErrorMessage}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            margin="normal"
            error={lastNameErrorMessage}
            name="lastName"
            value= {lastName}
            onChange={(e) => handleLastName(e)}
            onBlur={handleLastName}
            helperText = {lastNameErrorMessage}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            margin="normal"
            id="email"
            label="Email Id"
            type="email"
            fullWidth
            value={employeeEmailId}
            onChange={(e) => handleEmailChange(e)}
            onBlur={handleEmailBlur}
            error={emailError}
            helperText={emailError}
            required
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: '100%', mt: 2 }}
            label="Joining Date *"
            value={selectedDate}
            onChange={(e) => handleJoiningDate(e)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <div style={{ display: "flex", marginTop: "20px", alignItems: "center" }}>
        <FormControl sx={{ width:'100%'}}>
          <InputLabel id="simple-gender-label">Gender</InputLabel>
          <Select
            labelId="simple-gender-label"
            id="gender-select"
            value={gender}
            label="Gender"
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </Select>
        </FormControl>
        </div>
        <TextField
            fullWidth
            id="designation"
            label="Designation"
            margin="normal"
            error={designationErrMsg}
            value= {designation}
            onChange={(e) => handleDesignation(e)}
            onBlur={handleDesignation}
            helperText={designationErrMsg}
          />
        <TextField
          fullWidth
          id="uan"
          label="UAN"
          margin="normal"
          value= {uan}
          onChange={(e) => handleUan(e)}
        />
        <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>
        <FormControl sx={{ width:'100%'}}>
          <InputLabel id="demo-simple-select-label">Assign Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRole}
            label="AssignRole"
            onChange={handleChange}
          >
            {userRoles.map((row) => (
              <MenuItem value={row._id}>{row.userRole}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        <div style={{ display: "flex", marginTop: "20px", alignItems: "center" }}>
        <FormControl sx={{ width:'100%'}}>
          <InputLabel id="reporting-select-label">Reporting To *</InputLabel>
          <Select
            labelId="reporting-select-label"
            id="reporting-select"
            value={reportingTo}
            label="Reporting To * "
            onChange={handleReportingChange}
          >
            {reportingRoles.map((role) => (
              <MenuItem value={role._id}>{role.firstName} {role.lastName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: '100%', mt: 2 }}
            label="Date of Birth"
            value={dateOfBirth}
            onChange={(newDate) => setDateOfBirth(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        {employeeData && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%', mt: 2 }}
              label="Leaving Date"
              value={leavingDate}
              onChange={handleLeavingDate}
              minDate={employeeData.dateOfJoining ? dayjs(employeeData.dateOfJoining) : null}
              slotProps={{
                textField: {
                  error: !!errorMessage,
                  helperText: errorMessage
                },
              }}
            />
          </LocalizationProvider>
        )}

        <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>
        <FormControlLabel
              control={
                <Switch
                  checked={activeStatus}
                  onChange={handleActiveStatusChange}
                  color="primary"
                  size="small"
                />
              }
              label={<Typography className="status-switch">{activeStatus ? STATUS.ACTIVE : STATUS.INACTIVE}</Typography>}
            />
        </div>
        {loading && <LinearProgress />}
      </DialogContent>
      <DialogActions className='dialogActions'>
        <Button onClick={handleClose} variant="outlined" >Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary" 
          disabled={!employeeId || !firstName || !lastName || !employeeEmailId || !selectedDate || !reportingTo || !!employeeIdErrorMessage || !!firstNameErrorMessage || !!lastNameErrorMessage || !!emailError || !!designationErrMsg || !!errorMessage}>
          {employeeData ? 'Update' : 'Save'}</Button>
      </DialogActions>

      <Dialog
        open={roleConfirmPopup}
        onClose={handleConfirmPopupClose}
        PaperProps={{ sx: { width: '350px' } }}
      >
        <DialogTitle className="confirmDialogTitle">
          Role Change Confirmation <IconButton aria-label="close" onClick={handleConfirmPopupClose}><CancelIcon /> </IconButton></DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity="warning" className='confirmDialogMsg'>You selected <strong>{selectedRoleName}</strong> role for <strong>{firstName} {lastName}</strong>, Do you wish to continue?</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmPopupClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirmPopupContinue} variant="contained">
            Continue
          </Button>
        </DialogActions>
      </Dialog>

    </Dialog>
  );
};

export default AddEmployee;
