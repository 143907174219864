import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material'
import { Cancel as CancelIcon } from '@mui/icons-material';

export default function RespondQueryPopup({ openResponsePopup, handleCloseResponsePopup, selectedQuery, queryResponse, handleSaveResponse, setQueryResponse }) {
    return (
        <Dialog
            open={openResponsePopup}
            onClose={handleCloseResponsePopup}
            PaperProps={{ sx: { width: '600px' }, className: 'dialog' }}
        >
            <DialogTitle className='dialogTitle'>
                Respond to the Query
                <IconButton
                    aria-label="close"
                    onClick={handleCloseResponsePopup}
                    className='dialogCloseIcon'
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className='dc-bottom-padding'>
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Query Type:
                        </Typography>
                        <Typography variant="body1" sx={{ wordBreak: 'break-word', maxWidth: '100%' }}>
                            {selectedQuery?.queryTypeId?.type}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Subject:
                        </Typography>
                        <Typography variant="body1" sx={{ wordBreak: 'break-word', maxWidth: '100%' }}>
                            {selectedQuery?.subject}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Query:
                        </Typography>
                        <Typography variant="body1" sx={{ wordBreak: 'break-word', maxWidth: '100%', whiteSpace: 'pre-line' }}>
                            {selectedQuery?.query}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            label="Response"
                            placeholder='Respond to this query'
                            value={queryResponse}
                            onChange={(e) => setQueryResponse(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions className='dialogActions'>
                <Button onClick={handleCloseResponsePopup} variant="contained">Cancel</Button>
                <Button variant="contained" onClick={handleSaveResponse} disabled={!queryResponse}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}
