import { Box, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { getFaqQueries } from '../../services/RaiseQueryService';

export default function Faqs() {
    const [faqs, setFaqs] = useState({});
    const [loading, setLoading]=useState(false);

    useEffect(() => {
        fetchFaqQueries();
    }, []);

    const fetchFaqQueries = async () => {
        try {
            setLoading(true);
            const response = await getFaqQueries();
            setLoading(false);
            const grouped = {};

            response.forEach((faq) => {
                const type = faq.queryTypeId?.type;
                if (!type) return; //if type is undefined or null
                (grouped[type] ||= []).push(faq); // Shorter way to initialize and push
            });

            setFaqs(grouped);
        } catch (error) {
            console.error('Error fetching FAQ queries:', error);
        }
    };

     if (loading) {
          return <div className='loading-container'><CircularProgress /></div>; 
        }
    
    return (
        <Box className="main-content">
            <Grid container className="grid-container-header align-header-center">
                <Grid item xs={6}>
                    Frequently Asked Questions
                </Grid>
            </Grid>

            <Grid container padding={2} style={{ overflow: 'hidden', backgroundColor: '#fff' }}>
                {Object.keys(faqs).length > 0 ? (
                    Object.entries(faqs).map(([type, faqs]) => (
                        <Grid item xs={12} key={type} sx={{ marginBottom: 3 }}>
                            <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
                                <Typography fontWeight="bold" sx={{ marginBottom: 1 }}>{type}</Typography>
                                {faqs.map((faq, index) => (
                                    <Accordion key={faq._id || index} >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography color={'blue'}>{faq.query}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography color={'green'}>{faq.reply}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </Box>
                        </Grid>
                    ))
                ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 2 }}>
                        No FAQs available.
                    </Typography>
                )}
            </Grid>
        </Box>
    );
}
