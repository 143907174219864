import React, { useState, useEffect } from 'react';
import {
    Grid, Box, FormControl, Select, MenuItem, InputLabel, Dialog, DialogTitle, DialogContent, Alert, DialogActions,
    TextField, Paper, Button , IconButton, useMediaQuery, useTheme,
    LinearProgress,
    Divider
} from '@mui/material';
import { format, addDays, startOfMonth, endOfMonth, isWeekend, isBefore, isSameDay } from 'date-fns';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import CancelIcon from '@mui/icons-material/Cancel';
import { fetchWeeklyReports, createWeeklyReport } from '../../services/weeklyReportServices';
import { WEEKLY_REPORT_STATUS } from '../../Constants';
import TextFormatMenu from './TextFormatMenu';
import './weeklyReport.css';

export default function MyWeeklyReport() {
    const theme = useTheme();
    const id =  localStorage.getItem("employeeId");

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedWeek, setSelectedWeek] = useState('');
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [reportData, setReportData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [activeRowIndex, setActiveRowIndex] = useState(null);
    const isMobile = useMediaQuery('(max-width:800px)');
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [openDayChangeDialog, setOpenDayChangeDialog] = useState(false);
    const months = moment.months(); 
    const [initialReportData, setInitialReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const weeksForNewMonth = getWeeksInMonth(selectedYear, selectedMonth);
        const currentWeekIndex = weeksForNewMonth.findIndex(week =>
            isBefore(week.start, new Date()) && isBefore(new Date(), addDays(week.end, 1))
        );
        if (currentWeekIndex >= 0) {
            setSelectedWeek(currentWeekIndex);
            handleWeekChange({ target: { value: currentWeekIndex } });
        }
    }, [selectedMonth, selectedYear]);
    
    useEffect(() => {
        fetchReports();
    }, [selectedWeek, selectedYear, selectedMonth]); 

    const fetchReports = async () => {
        const weekDetails = weeksInMonth[selectedWeek];
        if (!weekDetails) return;
    
        const startDate = format(weekDetails.start, 'yyyy-MM-dd');
        const endDate = format(weekDetails.end, 'yyyy-MM-dd');
    
        try {
            const reports = await fetchWeeklyReports(id, selectedYear, startDate, endDate);
            setOriginalData(reports);
            setInitialReportData(reports);
    
                const fetchedReportData = weekDetails.days.map(day => {
                    const report = reports.find(report => format(new Date(report.date), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd'));
                    return {
                        date: day,
                        topic: report ? report.topic : '',
                        description: report ? report.description : '',
                        status: report ? report.status : WEEKLY_REPORT_STATUS.IN_PROGRESS
                    };
                });
    
                setReportData(fetchedReportData);

        } catch (error) {
            console.error('Error fetching weekly reports:', error);
        }
    };

    const handleYearChange = (event) => {
        const newYear = event.target.value;
        setSelectedYear(newYear);
        setSelectedDay('');
        setSelectedIndex(null);
    
        // Set month to January (0)
        const newMonth = 0;
        setSelectedMonth(newMonth);
    
        // Recalculate weeks based on new year and month
        const weeksForNewMonth = getWeeksInMonth(newYear, newMonth);
    
        // Set the first week as the selected week
        if (weeksForNewMonth.length > 0) {
            setSelectedWeek(0);
        }
    };   

    const handleMonthChange = (event) => {
        const newMonth = event.target.value;
        setSelectedMonth(newMonth);
        setSelectedDay('');
        setSelectedIndex(null);
    
        const weeksForNewMonth = getWeeksInMonth(selectedYear, newMonth);
    
        // If there are weeks available, set the first week as the selected week
        if (weeksForNewMonth.length > 0) {
            setSelectedWeek(0);
        }
    };

    const handleWeekChange = (event) => {
        setSelectedWeek(event.target.value);
        setSelectedDay('');
        setSelectedIndex(null);
    };
    
    
    const handleInputChange = (index, field, value) => {
        const updatedData = [...reportData];
        updatedData[index][field] = value;
        setReportData(updatedData);

         // Set the active row index to the current index being edited
        setActiveRowIndex(index);
    };

    const handleSaveButtonState = () => {
        let hasChanges = false;
        let hasAnyData = false;
    
        originalData.forEach((originalRow) => {
        
            // Find the currentRow based on matching date
            const currentRow = reportData.find((row) => {
                const originalDate = new Date(originalRow.date).toDateString();
                const currentDate = new Date(row.date).toDateString();
                return originalDate === currentDate; // Match by date
            });

            if (currentRow && (
                originalRow.topic !== currentRow.topic || 
                originalRow.description !== currentRow.description || 
                originalRow.status !== currentRow.status
            )) {
                const allOldRowsFilled = reportData
                .filter(row => originalData.some(originalRow => {
                    return new Date(originalRow.date).toDateString() === new Date(row.date).toDateString();
                }))
                .every(row => {
                    // Check if the old row is filled or completely empty
                    if (!row.topic && !row.description) {
                        return false;
                    }
                    // If both fields are filled
                    if (row.topic && row.description) {
                        hasAnyData = true;
                        return true;
                    }
                    // If only one field is filled, the row is incomplete
                    return false;
                });
                hasChanges = true;
                hasAnyData = true;
            }
        });

        const allOldRowsFilled = reportData
        .filter(row => originalData.some(originalRow => {
            return new Date(originalRow.date).toDateString() === new Date(row.date).toDateString();
        }))
        .every(row => {
            // Check if the old row is filled or completely empty
            if (!row.topic && !row.description) {
                return false;
            }
            // If both fields are filled
            if (row.topic && row.description) {
                return true;
            }
            // If only one field is filled, the row is incomplete
            return false;
        });
    
        // 2. Check for new rows (those not present in originalData)
        const allNewRowsFilled = reportData
        .filter(row => !originalData.some(originalRow => {
            return new Date(originalRow.date).toDateString() === new Date(row.date).toDateString();
        }))
        .every(row => {
            // Check if the new row is filled or completely empty
            if (!row.topic && !row.description) {
                return true; // Ignore completely empty new rows
            }
            // If both fields are filled
            if (row.topic && row.description) {
                hasAnyData = true;
                return true;
            }
            // If only one field is filled, the row is incomplete
            hasChanges = false;
            return false;
        });
    
        return (hasChanges || allNewRowsFilled) && hasAnyData && allOldRowsFilled;
    };
    
    const isSaveButtonEnabled = handleSaveButtonState();
    
    const isRowModified = activeRowIndex !== null && (reportData[activeRowIndex].topic || reportData[activeRowIndex].description);

    const getWeeksInMonth = (year, month) => {
        const start = startOfMonth(new Date(year, month));
        const end = endOfMonth(new Date(year, month));
        let weeks = [];
        let currentDay = start;
        let weekStart = null;

        while (isBefore(currentDay, end) || isSameDay(currentDay, end)) {
            if (currentDay.getDay() !== 0) {
                if (!weekStart) {
                    weekStart = currentDay;
                }

                const isSaturday = currentDay.getDay() === 6;
                const isLastDayOfMonth = isSameDay(currentDay, end);

                if (isSaturday || isLastDayOfMonth) {
                    const days = [];
                    let tempDay = weekStart;
                    while (isBefore(tempDay, addDays(currentDay, 1))) {
                        if (tempDay.getDay() !== 0) days.push(tempDay);
                        tempDay = addDays(tempDay, 1);
                    }
                    weeks.push({
                        start: weekStart,
                        end: currentDay,
                        days,  // Store the weekdays
                    });
                    weekStart = null;
                }
            }
            currentDay = addDays(currentDay, 1);
        }

        return weeks;
    };

    const weeksInMonth = getWeeksInMonth(selectedYear, selectedMonth);

    const handleCancel = () => {
        setOpenDialog(true)
    };

    const handleCancelDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = () => {
        setOpenDialog(false);
        setActiveRowIndex(null);
        fetchReports();
    }; 

    const handleDayChange = (event) => {
        if(isSaveButtonEnabled){
            setOpenDayChangeDialog(true);
        }
        else {
            const selectedDate = event.target.value;
            const index = reportData.findIndex(data => format(data.date, 'dd/MMM/yyyy') === selectedDate);
        
            setSelectedDay(selectedDate);
            setSelectedIndex(index);
        }
    };

    const handleDayChangeCancelDialog = () => {
        setOpenDayChangeDialog(false);
    };

    const handleSave = async () => {
        if (loading) return;
        try {
            setLoading(true)
            const formattedReportData = reportData
                .filter(data => data.topic && data.description) // Only include filled rows
                .filter(data => {
                    // Find the corresponding initial data
                    const initialData = initialReportData.find(item => format(new Date(item.date), 'yyyy-MM-dd') === format(new Date(data.date), 'yyyy-MM-dd'));
    
                    // If no matching initial data, it's a new row
                    if (!initialData) return true;
    
                    // Compare fields to detect changes
                    return (
                        initialData.topic !== data.topic ||
                        initialData.description !== data.description ||
                        initialData.status !== data.status
                    );
                })
                .map(data => ({
                    employeeId: id,
                    date: format(data.date, 'yyyy-MM-dd'),
                    topic: data.topic,
                    description: data.description,
                    status: data.status,
                }));
    
            if (formattedReportData.length > 0) {
                await createWeeklyReport(formattedReportData);
                NotificationManager.success("Weekly report saved successfully");
                setActiveRowIndex(null);
                fetchReports();
                setOpenDayChangeDialog(false);
            }
    
        } catch (error) {
            console.error('Error saving weekly reports:', error);
            NotificationManager.error("Error saving weekly report");
        }finally{
            setLoading(false)
        }
    };

    return (
        <Box className = 'main-content'>
            <Grid container className='grid-container-header align-header-center'>
                <Grid item xs={12} md={3}>
                    My Weekly Report
                </Grid>
                <Grid container xs={12} md={9} className='align-filters-right'>
                    <Grid item xs={12} md={2}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel>Year</InputLabel>
                            <Select value={selectedYear} label="Year" onChange={handleYearChange} >
                                {[currentYear, currentYear - 1].map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel>Month</InputLabel>
                            <Select value={selectedMonth}
                                label="Month"
                                onChange={handleMonthChange}
                                MenuProps={{
                                    sx: {
                                        maxHeight: { xs: 200, sm: 450 },
                                        overflow: "auto",
                                    },
                                }}
                            >
                                {Array.from({ length: 12 }, (_, i) => (
                                    <MenuItem key={i} value={i}>
                                        {months[i]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel>Week</InputLabel>
                            <Select
                                value={selectedWeek}
                                onChange={handleWeekChange}
                                label="Week"                            >
                                {weeksInMonth.map((week, index) => (
                                    <MenuItem key={index} value={index}>
                                        {`Week ${index + 1} (${format(week.start, 'dd/MMM/yyyy')} to ${format(week.end, 'dd/MMM/yyyy')})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

                {/* Enable Horizontal */}
                { !isMobile && (
                <Box>                
                <Grid container spacing={1} padding={0} marginTop={0} marginBottom={2}>
                    {/* Data Rows */}
                    {reportData.map((data, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={1.2}>
                                <Paper elevation={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
                                     fontSize: '0.8em', backgroundColor: '#808080', color: theme.palette.secondary.main, cursor: 'default'}}>
                                        {format(data.date, 'dd/MMM/yyyy')}
                                </Paper>
                            </Grid>
                            <Grid item xs={3}>
                                <Paper elevation={1} sx={{ padding: 0, borderRadius: 0 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            placeholder="Topic Name"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                sx: { padding: 0, margin: 0}
                                            }}
                                            value={data.topic}
                                            onChange={(e) => handleInputChange(index, 'topic', e.target.value)}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={6.3}>
                                <Paper elevation={1} sx={{ padding: 0, borderRadius: 0 }}>
                                    <Box>
                                        <TextFormatMenu                                                                           
                                            value={data.description}
                                            onChange={(newValue) => handleInputChange(index, 'description', newValue)}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Paper elevation={1} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <FormControl variant="standard" >
                                            <Select
                                                value={data.status}
                                                onChange={(e) => handleInputChange(index, 'status', e.target.value)}
                                                disableUnderline
                                            >
                                                <MenuItem value={WEEKLY_REPORT_STATUS.IN_PROGRESS}>In Progress</MenuItem>
                                                <MenuItem value={WEEKLY_REPORT_STATUS.COMPLETED}>Completed</MenuItem>
                                            </Select>
                                        </FormControl>
                                </Paper>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </Box>
            )}

            { isMobile && (
                <Grid container rowGap={2} padding={1} marginTop={0} marginBottom={2} alignItems={"center"}>
                    <Grid item xs ={12}>
                        <TextField
                            select
                            label={!selectedDay ? 'Select Date' : ''}
                            value={selectedDay}
                            onChange={handleDayChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: selectedDay !== "",
                                className: 'input-label-root'
                            }}
                            InputProps={{
                                classes: {
                                root: 'input-base-root'
                                }
                            }}
                            SelectProps={{
                                classes: {
                                icon: 'select-icon'
                                }
                            }}
                            className="mobile-dates"
                        >
                            {reportData.map((data, index) => (
                                <MenuItem key={format(data.date, 'dd/MMM/yyyy')} value={format(data.date, 'dd/MMM/yyyy')}>
                                {format(data.date, 'dd/MMM/yyyy')}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {selectedIndex !== null && reportData[selectedIndex] && (
                    <>
                        <Grid item xs={12} onClick={()=>setIsActive(false)}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Topic"
                            value={reportData[selectedIndex].topic || ''}
                            onChange={(e) => handleInputChange(selectedIndex, 'topic', e.target.value)}
                        />
                        </Grid>
                        <Grid item xs={12} className={isActive ? 'mobile-description-active' : 'mobile-description-inactive'} onClick={()=>setIsActive(true)}>
                        <TextFormatMenu
                            value={reportData[selectedIndex].description || ''}
                            onChange={(newValue) => handleInputChange(selectedIndex, 'description', newValue)}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Select
                            value={reportData[selectedIndex].status || ''}
                            onChange={(e) => handleInputChange(selectedIndex, 'status', e.target.value)}
                            onOpen={() => setIsActive(false)} 
                            >
                            <MenuItem value={WEEKLY_REPORT_STATUS.IN_PROGRESS}>In Progress</MenuItem>
                            <MenuItem value={WEEKLY_REPORT_STATUS.COMPLETED}>Completed</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>
                    </>
                    )}
                </Grid>
            )}
            <Dialog open={openDayChangeDialog} onClose={handleDayChangeCancelDialog}>
                <DialogTitle className="confirmDialogTitle">Unsaved Changes Confirmation <IconButton aria-label="close" onClick={handleDayChangeCancelDialog}><CancelIcon /></IconButton></DialogTitle>
                <DialogContent className='confirmDialogContent' >
                    <Alert severity='warning' className='confirmDialogMsg'>
                        You have unsaved changes for the current day. Click 'Continue' to save them before switching to another day.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained'  onClick={handleDayChangeCancelDialog} color="primary"> Cancel </Button>
                    <Button variant='contained'  onClick={handleSave} color="primary"> Continue </Button>
                </DialogActions>
            </Dialog>
                <Box className='linear-progress'>
                    {loading && <LinearProgress />}
                        <Divider />
                </Box>
                <Box className='button-styles'>
                    <Button variant="contained"
                        className='button-alignment-1'
                        onClick={handleCancel}
                        disabled={!isRowModified}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained"
                        className='button-alignment-2'
                        onClick={handleSave}
                        disabled={!isSaveButtonEnabled||loading}
                    >
                        Save
                    </Button>
                </Box>

                <Dialog open={openDialog} onClose={handleCancelDialog}>
                    <DialogTitle className="confirmDialogTitle">Unsaved Changes Confirmation <IconButton aria-label="close" onClick={handleCancelDialog}><CancelIcon /></IconButton></DialogTitle>
                    <DialogContent className='confirmDialogContent' >
                    <Alert severity='warning' className='confirmDialogMsg'>
                        There are unsaved changes that will be lost. Do you wish to continue?
                    </Alert>
                    </DialogContent>
                    <DialogActions>
                    <Button variant='contained' onClick={handleCancelDialog} color="primary"> No </Button>
                    <Button variant='contained'  onClick={handleConfirmDialog} color="primary"> Continue </Button>
                    </DialogActions>
                </Dialog>
        </Box>
    );
}
