import React, { useState  } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, FormHelperText, Grid, IconButton, InputLabel, LinearProgress, MenuItem,TextField, Select, useTheme } from '@mui/material';
import { NotificationManager } from "react-notifications";
import { createLeave ,sendLeaveApprovalEmail} from '../../services/leaveServices';
import { parseISO, eachDayOfInterval, isWeekend } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';
import { LEAVE_STATUS, MAX_LEAVE_REQUEST_DURATION  } from '../../Constants';
import moment from 'moment';

function ApplyLeavePopup({ open, handleCloseDialog, employeeId, totalAppliedLeaves, totalDays, fetchLeavesList, appliedLeaves , leaveThreshold }) {
    const {SICK_LEAVE_THRESHOLD, CASUAL_LEAVE_THRESHOLD , MATERNITY_LEAVE_THRESHOLD } = leaveThreshold;
    // console.log("SICK_LEAVE_THRESHOLD", SICK_LEAVE_THRESHOLD, "CASUAL_LEAVE_THRESHOLD", CASUAL_LEAVE_THRESHOLD, "MATERNITY_LEAVE_THRESHOLD", MATERNITY_LEAVE_THRESHOLD , leaveThreshold)
    const {sickLeavesApplied, casualLeavesApplied, maternityLeavesApplied } = totalAppliedLeaves ;
    const maxLeave = process.env.REACT_APP_EMPLOYEE_MAX_LEAVE_REQUEST
    const theme = useTheme();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [reason, setReason] = useState('');
    const [leaveType, setLeaveType] = useState('');
    const [errors, setErrors] = useState({
        fromDate: '',
        toDate: '',
        reason: '',
        leaveType: ''
    });
    const [showThresholdPopup, setShowThresholdPopup] = useState(false);
    const [thresholdMessage, setThresholdMessage] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const validateLeave = () => {
                
            let filteredAppliedLeaves = appliedLeaves.filter((l) => l.status == LEAVE_STATUS.PENDING || l.status == LEAVE_STATUS.APPROVED);
            
            // if(filteredAppliedLeaves.length == 0){ return "";}
            
            const matchingLeave = filteredAppliedLeaves.find((leave) => {
    
                const existingLeaveFromDate = new Date(leave.fromDate);
                existingLeaveFromDate.setHours(0, 0, 0, 0);
                const existingLeaveToDate = new Date(leave.toDate);
                existingLeaveToDate.setHours(0, 0, 0, 0);
            
                const selectedFromDate = new Date(fromDate);
                selectedFromDate.setHours(0, 0, 0, 0);
                const selectedToDate = new Date(toDate);
                selectedToDate.setHours(0, 0, 0, 0);
                        
                // Case 1: Selected From Date is between the existing leave period
                if (selectedFromDate >= existingLeaveFromDate && selectedFromDate <= existingLeaveToDate) {
                    return true;
                }

                // Case 2: Selected To Date is between the existing leave period
                if (selectedToDate >= existingLeaveFromDate && selectedToDate <= existingLeaveToDate) {
                    return true;
                   
                }

                // Case 3: The selected leave period fully overlaps an existing leave period
                if (selectedFromDate <= existingLeaveFromDate && selectedToDate >= existingLeaveToDate) {
                    return true;
                }

                return false;
            });

           
            if (matchingLeave) {
                setMessage("Leaves for From/To dates are already applied. Please select different dates to continue.")
              return false;

              // const leaveFrom = new Date(matchingLeave.fromDate).toISOString().split('T')[0];
              // const leaveTo = new Date(matchingLeave.toDate).toISOString().split('T')[0];

              // if (fromDate === leaveFrom && toDate === leaveTo) {
              //     return `Leave is already applied for ${fromDate} to ${toDate}.`;
              // } else if (selectedFrom >= new Date(matchingLeave.fromDate) && selectedTo <= new Date(matchingLeave.toDate)) {
              //     return `The range from ${fromDate} to ${toDate} is within an existing leave.`;
              // } else if (selectedFrom <= new Date(matchingLeave.fromDate) && selectedTo >= new Date(matchingLeave.toDate)) {
              //     return `The range from ${fromDate} to ${toDate} overlaps an existing leave.`;
              // } else if (selectedTo >= new Date(matchingLeave.fromDate) && selectedTo <= new Date(matchingLeave.toDate)) {
              //     return `To-Date ${toDate} overlaps with an existing leave.`;
              // } else if (selectedFrom >= new Date(matchingLeave.fromDate) && selectedFrom <= new Date(matchingLeave.toDate)) {
              //     return `From-Date ${fromDate} overlaps with an existing leave.`;
              // }
            } else {
                setMessage("");
                return true;

            }
            
        // }
    };

    const calculateBusinessDays = (startDate, endDate) => {
        const days = eachDayOfInterval({
            start: parseISO(startDate),
            end: parseISO(endDate)
        });
        if(leaveType === 'ML'){
            return days.length;
        } else {
            return days.filter(day => !isWeekend(day)).length;
        }
    };

    // Function to validate the leave duration
    const validateLeaveDuration = (selectedFromDate, selectedToDate) => {
        if (selectedFromDate && selectedToDate) {
            const fromDateObj = new Date(selectedFromDate);
            const toDateObj = new Date(selectedToDate);
            const leaveDuration = (toDateObj - fromDateObj) / (1000 * 3600 * 24);
            const maxLeaveDuration = MAX_LEAVE_REQUEST_DURATION;
            if (leaveDuration > maxLeaveDuration) {
                setMessage('Please revise your leave plan. Your Reporting Manager can only approve up to 10 days of leave at a time.');
            } else {
                setMessage("");
            }
        }
    };

    const handleFromDateChange = (selectedFromDate) => {
        const newFromDate = selectedFromDate.toISOString();
        setFromDate(newFromDate);
        setMessage("");
        if (toDate && newFromDate > toDate) {
            setErrors(prev => ({
                ...prev,
                fromDate: 'From Date cannot be later than To Date'
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                fromDate: '',
                toDate: ''
            }));
        }

        if (toDate && leaveType !== 'ML' ) {
            validateLeaveDuration(newFromDate, toDate);
        }
    }
    const handleToDateChange = (selectedToDate) => {
        const newToDate = selectedToDate.toISOString();
        setToDate(newToDate);
        setMessage("");
        if (fromDate && newToDate < fromDate) {
            setErrors(prev => ({
                ...prev,
                toDate: 'The \'To Date\' field should not be earlier than the \'From Date\'.'
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                toDate: '',
                fromDate: ''
            }));
        }

        if (fromDate && leaveType !== 'ML') {
            validateLeaveDuration(fromDate, newToDate);
        }
    }
    
    const handleReasonChange = (e) => {
        const reason = e.target.value;
        const regex = /^[A-Za-z0-9\s]*$/;

        if (regex.test(reason)) {
        setReason(reason);
        errors.reason = '';
        } else {
        errors.reason = 'Reason can only contain letters, numbers, and spaces';
        }
    };        
    const handleLeaveTypeChange = (e) => {
        setLeaveType(e.target.value);
        errors.leaveType = '';
    }

    const validate = () => {
        const newErrors = {
            fromDate: fromDate ? '' : 'From Date is required',
            toDate: toDate ? '' : 'To Date is required',
            reason: reason ? '' : 'Reason is required',
            leaveType: leaveType ? '' : 'Leave Type is required',
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleClose = () => {
        setFromDate(null);
        setToDate(null);
        setReason('');
        setLeaveType('');
        setErrors({
            fromDate: '',
            toDate: '',
            reason: '',
            leaveType: ''
        });
        setMessage("");
        handleCloseDialog();
    };

    const handleSave = async () => {
        if (loading) return;

        if (!validate()) return;

        if(!validateLeave())return;

        const fromDateYear = new Date(fromDate).getFullYear();
    
        const yearEndBoundary = new Date(`December 31, ${fromDateYear}`);
        if (new Date(fromDate) <= yearEndBoundary && new Date(toDate) > yearEndBoundary) {
            NotificationManager.warning(
                "As the year ends on December 31, If you need to take leaves in December, apply separately upto December 31. And for leaves in January, submit a new leave request"
            );
            return;
        }

        const newLeaveDays = calculateBusinessDays(fromDate, toDate);
        if (leaveType === 'SL') {
            const remainingSickLeaves = SICK_LEAVE_THRESHOLD  - sickLeavesApplied;
            const sickLopLeaves = Math.abs(newLeaveDays - remainingSickLeaves);
        
            if (sickLeavesApplied + newLeaveDays > SICK_LEAVE_THRESHOLD  ) {
                const message = `You are exceeding the sick leave threshold. You are eligible to avail ${remainingSickLeaves} more day(s) of sick leave without LOP. The remaining ${sickLopLeaves} day(s) will be treated as Loss of Pay (LOP) if included in this request. Consider splitting your leave request accordingly.`;
                setThresholdMessage(message);
                setShowThresholdPopup(true);
                return;
            }
        } else if (leaveType === 'CL') {
            const remainingCasualLeaves = CASUAL_LEAVE_THRESHOLD  - casualLeavesApplied;
            const casualLopLeaves = Math.abs(newLeaveDays - remainingCasualLeaves);
            if (casualLeavesApplied + newLeaveDays > CASUAL_LEAVE_THRESHOLD ) {
                const message = `You are exceeding the casual leave threshold. You are eligible to avail ${remainingCasualLeaves} more day(s) of casual leave without LOP. The remaining ${casualLopLeaves} day(s) will be treated as Loss of Pay (LOP) if included in this request. Consider splitting your leave request accordingly.`;
                setThresholdMessage(message);
                setShowThresholdPopup(true);
                return;
            }
        } else if (leaveType === 'ML') {
            const remainingMaternityLeaves = MATERNITY_LEAVE_THRESHOLD  - maternityLeavesApplied;
            const maternityLopLeaves = Math.abs(newLeaveDays - remainingMaternityLeaves);
            if (maternityLeavesApplied + newLeaveDays > MATERNITY_LEAVE_THRESHOLD ) {
                const message = `You are exceeding the maternity leave threshold. You are eligible to avail ${remainingMaternityLeaves} more day(s) of maternity leave without LOP. The remaining ${maternityLopLeaves} day(s) will be treated as Loss of Pay (LOP) if included in this request. Consider splitting your leave request accordingly.`;
                setThresholdMessage(message);
                setShowThresholdPopup(true);
                return;
            }
        }


        // const updatedTotalDays = totalDays + newLeaveDays;
        // const remainingLeaves = maxLeave - totalDays;
        // const lopLeaves = Math.abs(newLeaveDays - remainingLeaves);

        // if (updatedTotalDays > maxLeave && remainingLeaves > 0) {
        //     const message = `You are exceeding the leave threshold. You are eligible to avail ${remainingLeaves} more day(s) of leave without LOP. The remaining ${lopLeaves} day(s) will be treated as Loss of Pay (LOP) if included in this request. Consider splitting your leave request accordingly.`;
        //     setThresholdMessage(message);
        //     setShowThresholdPopup(true);
        //     return;
        // }
const fDate = new Date(fromDate).toLocaleString("en-US", {timeZone: 'Asia/Kolkata'}) ;
const tDate = new Date(toDate).toLocaleString("en-US", {timeZone: 'Asia/Kolkata'}) ;
        const leaveData = { employeeId, fromDate:fDate, toDate:tDate, reason, leaveType };
        try {
            setLoading(true); 
            const response = await createLeave(leaveData);
            const emailData = {
                employeeId : employeeId,
                leaveType : leaveType,
                leaveReason : reason,
                fromDate: moment.utc(fromDate).local().format('DD-MM-YYYY'),
                toDate: moment.utc(toDate).local().format('DD-MM-YYYY'),
                noOfDays: newLeaveDays,
                id : response._id,
            }
            await sendLeaveApprovalEmail(emailData);
            NotificationManager.success("Leave request created successfully");
            handleClose();
            fetchLeavesList()
        } catch (error) {
            NotificationManager.error(error.response?.data?.message || 'An error occurred');
        }
        finally {
            setLoading(false);
        }
    };

    const handleThresholdPopupClose = () => {
        setShowThresholdPopup(false);
    };

    const shouldDisableDate = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };    

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '400px' }, className: 'dialog' }}>
            <DialogTitle className= 'dialogTitle'>
                Add New Leave Request
                <IconButton aria-label="close" onClick={handleClose} className='dialogCloseIcon'>
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className='dc-bottom-padding'>

                <Grid container spacing={2} padding={1}>
                    <Grid item xs={12}>
                    <Box>
                    <FormHelperText error style={{ width: '100%' }}>
                    {message}
                    </FormHelperText>
                </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={!!errors.leaveType}>
                            <InputLabel>Leave Type</InputLabel>
                            <Select
                                value={leaveType}
                                onChange={handleLeaveTypeChange}
                                label="Leave Type"
                            >
                                <MenuItem value="SL" disabled={sickLeavesApplied >= SICK_LEAVE_THRESHOLD }>Sick Leave (SL)</MenuItem>
                                <MenuItem value="CL" disabled={casualLeavesApplied >= CASUAL_LEAVE_THRESHOLD }>Casual Leave (CL)</MenuItem>
                                <MenuItem value="ML" >Maternity Leave (ML)</MenuItem>
                                <MenuItem value="LOP" disabled={sickLeavesApplied + casualLeavesApplied  < SICK_LEAVE_THRESHOLD  + CASUAL_LEAVE_THRESHOLD  }>Loss of pay (LOP)</MenuItem>
                            </Select>
                            {errors.reason && (
                                <FormHelperText>{errors.leaveType}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ width: '100%', '& .MuiInputAdornment-root': { marginRight: '10px' }}}
                            label="From Date"
                            value={fromDate ? dayjs(fromDate) : null}
                            onChange={handleFromDateChange}
                            shouldDisableDate={shouldDisableDate}
                            format='DD-MM-YYYY'
                            slotProps={{
                            textField: {
                                error: !!errors.fromDate,
                                helperText: errors.fromDate,
                                fullWidth: true,
                                required: true,
                                InputLabelProps: { shrink: true }
                            },
                            }}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ width: '100%', '& .MuiInputAdornment-root': { marginRight: '10px' }}}
                            label="To Date"
                            value={toDate ? dayjs(toDate) : null}
                            onChange={handleToDateChange}
                            shouldDisableDate={shouldDisableDate}
                            format='DD-MM-YYYY'
                            slotProps={{
                            textField: {
                                error: !!errors.toDate,
                                helperText: errors.toDate,
                                fullWidth: true,
                                required: true,
                                InputLabelProps: { shrink: true }
                            },
                            }}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Reason"
                            multiline
                            rows={3}
                            value={reason}
                            onChange={handleReasonChange}
                            error={!!errors.reason}
                            helperText={errors.reason}
                        />
                    </Grid>
                </Grid>
                {loading && <LinearProgress className='progress-loading'/>}
            </DialogContent>
            <DialogActions className='dialogActions'>
                <Button variant='outlined' onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSave} disabled={!!Object.values(errors).find(error => error) || !fromDate || !toDate || !reason || !leaveType || message }>
                    Send
                </Button>
            </DialogActions>

            <Dialog
                open={showThresholdPopup}
                onClose={handleThresholdPopupClose}
                PaperProps={{ sx: { width: '380px' }}}
            >
                <DialogTitle className="confirmDialogTitle">Leave Threshold Exceeded
                    <IconButton aria-label="close" onClick={handleThresholdPopupClose}>
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='confirmDialogContent' >
                    <Alert severity='warning' className='confirmDialogMsg' sx={{margin:"15px 0px", textAlign:"justify"}}>
                        {thresholdMessage}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleThresholdPopupClose} variant={'contained'}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </Dialog>
    );
}

export default ApplyLeavePopup;
