import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchHolidaysByYear } from '../../services/HolidayServices';
import { Grid, Box, Button, IconButton, Tooltip, LinearProgress, useTheme} from '@mui/material';
import { format, parseISO } from 'date-fns';
import logo from '../../assets/logos/phyelements_logo.png';
import Info from '../../assets/icons/info_icon.png'; 
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Download as DownloadIcon, Info as InfoIcon } from '@mui/icons-material';
import { NotificationManager } from 'react-notifications';
import "./holiday.css";

export default function ViewHolidays() {
    const [holidays, setHolidays] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    useEffect(() => {
        fetchHolidays(currentYear);
    }, [currentYear]);

    const fetchHolidays = async (year) => {
        setLoading(true);
        try {
            const holidaysResponse = await fetchHolidaysByYear(year);
            const sortedHolidays = holidaysResponse.sort((a, b) => new Date(a.date) - new Date(b.date));
            setHolidays(sortedHolidays);
        } catch (error) {
            console.error('Error fetching holidays:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (isoDateString) => {
        if (!isoDateString) return '';
        try {
            const date = parseISO(isoDateString);
            return format(date, 'dd-MM-yyyy');
        } catch (error) {
            console.error('Error formatting date:', error);
            return '';
        }
    };

    const preloadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
        });
    };

    const exportToPDF = async () => {
        const doc = new jsPDF();
        try {
            //Preload Image
            const logoImage = await preloadImage(logo);
            const infoIconImage = await preloadImage(Info);

            doc.addImage(logoImage, 'PNG', 13, 10); 

            const columns = [
                { title: 'Occasion', dataKey: 'holidayName' },
                { title: 'Date', dataKey: 'date' },
                { title: 'Holiday Type', dataKey: 'holidayType' }
            ];
            const data = holidays.map(row => ({
                holidayName: row.holidayName,
                date: formatDate(row.date),
                holidayType: row.holidayType,
            }));

            const headerTextY = 40;
            const infoIconX = 14;
            const infoTextX = infoIconX + 4;
            const tableStartY = headerTextY + 10;

            // Add header text
            const headerText = `Annual Holidays - ${currentYear}`;
            doc.text(headerText, 14, headerTextY);

            // Add info icon and text
            doc.addImage(infoIconImage, 'PNG', infoIconX, headerTextY + 3.5, 3, 3);
            doc.setFont("helvetica", "italic");
            const infoText = "(Some holidays may not be applicable to employees working at client locations. Please refer to the client's holiday calendar.)";
            doc.setFontSize(8);
            doc.text(infoText, infoTextX, headerTextY + 6);
            doc.setFont("helvetica", "normal");

            // Add table of holiday data
            doc.autoTable({
                columns,
                body: data,
                startY: tableStartY,
                margin: { top: tableStartY },
                headStyles: {
                    fillColor: theme.palette.primary.main,
                },
            });
            doc.save(`Phyelements_Holidays_${currentYear}.pdf`);
            NotificationManager.success('Holiday downloaded successfully');
        } catch (error) {
            console.error('Error generating PDF:', error);
            NotificationManager.error('Failed to download holidays');
        }
    };

    const columns = [
        { field: 'holidayName', headerName: <strong>Occasion</strong>, flex: 4, minWidth: 200 },
        {
            field: 'date',
            headerName: <strong>Date</strong>,
            flex: 3, minWidth: 150,
            valueGetter: (params) => formatDate(params),
        },
        { field: 'holidayType', headerName: <strong>Holiday Type</strong>,flex: 3, minWidth: 150 },
    ];

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };
    return (
        <Box className = 'main-content'>            
                <Grid container className='grid-container-header align-header-center'>
                <Grid item xs={6}>                        
                        <span>{currentYear} Holidays 
                        <Tooltip title="Some holidays may not be applicable to employees working at client locations. Please refer to the client's holiday calendar."
                            open={open}
                            onClose={handleTooltipClose}
                            onOpen={handleTooltipOpen} 
                            leaveTouchDelay={10000}
                            arrow
                        >
                            <IconButton onClick={() => setOpen(!open)}
                                onMouseEnter={handleTooltipOpen}
                                onMouseLeave={handleTooltipClose}  >
                                <InfoIcon className="info-icon" />
                            </IconButton>
                        </Tooltip>                                    
                        </span>  
                </Grid>
                <Grid item xs={6} sx={{textAlign:'right'}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={exportToPDF}
                                startIcon={<DownloadIcon />}                                
                            >Download</Button>                            
                </Grid>
                </Grid>
            
            <Grid container className="holiday-grid-container" sx={{flexGrow: 1,overflow: 'hidden',}}>
                <Grid item xs={12}>
                    <DataGrid
                        localeText={{ noRowsLabel: "No Holidays To Show" }}
                        rows={holidays}
                        columns={columns}
                        getRowId={(row) => row._id}
                        autoHeight
                        hideFooterPagination
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                        className="holiday-datagrid"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
