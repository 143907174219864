import {
  PeopleAlt as PeopleAltIcon, AdminPanelSettings as AdminPanelSettingsIcon, Bento as BentoIcon, VerifiedUser as VerifiedUserIcon, 
  Dashboard as DashboardIcon, RecentActors as RecentActorsIcon, ViewTimeline as ViewTimelineIcon, Assignment as AssignmentIcon, 
  AssignmentTurnedIn as AssignmentTurnedInIcon, CalendarMonth as CalendarMonthIcon, Devices as DevicesIcon, WorkHistory as WorkHistoryIcon, 
  Assessment as AssessmentIcon, Lan as HierarchyIcon,  LiveHelp as LiveHelpIcon , Quiz as QuizIcon, QuestionAnswer as QuestionAnswerIcon
} from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import DashboardComponent from "../Dashboard/Dashboard";
import UserRoles from "../components/UserRole/UserRoles";
import ManageEmployee from "../components/Employee/ManageEmployee";
import Modules from "../components/Modules/Modules";
import UserRolePermissions from "../components/UserPermissions/UserPermissions";
import UpdateProfile from "../components/UpdateProfile/UpdateProfile";
import ManageHolidays from '../components/Holidays/ManageHolidays';
import ViewHolidays from '../components/Holidays/ViewHolidays';
import TimeTracker from "../components/TimeTracker/TimeTracker";
import ApplyLeave from "../components/Leaves/ApplyLeave";
import LeaveApproval from "../components/Leaves/LeaveApprovals";
import TimeTrackerView from "../components/TimeTracker/TimeTrackerView";
import ManageAsset from "../components/Assets/ManageAsset";
import MyWeeklyReport from "../components/WeeklyReport/MyWeeklyReport";
import ViewWeeklyReports from "../components/WeeklyReport/ViewWeeklyReports"
import ITDeclaration from '../components/ITDeclaration/ITDeclaration';
import ItdReports from '../components/ITDReports/ItdReports';
import OrgChart from '../components/OrgChart/OrgChart';
import Faqs from '../components/FAQ/Faqs';
import MyRaisedQueries from '../components/RaisedQueries/MyRaisedQueries';
import AllRaisedQueries from '../components/RaisedQueries/AllRaisedQueries';

const AllRoutes = [
  {
    path: "/dashboard",
    title: "Dashboard",
    element: <DashboardComponent />,
    icon: DashboardIcon,
    key: 'dashboard'
  },
  {
    path: "/updateprofile/:id",
    title: "My Profile",
    element: <UpdateProfile />,
    icon: RecentActorsIcon,
    key: 'myprofile'
  },
  {
    path: "/myWeeklyReport",
    title: "My Weekly Report",
    element: <MyWeeklyReport />,
    icon: WorkHistoryIcon,
    key: 'myweeklyreport'
  },
  {
    path: "/viewWeeklyReports",
    title: "View Weekly Reports",
    element: <ViewWeeklyReports />,
    icon: AssessmentIcon,
    key: 'viewweeklyreports'
  },
  {
    path: "/userroles",
    title: "Roles",
    element: <UserRoles />,
    icon: AdminPanelSettingsIcon,
    key: "roles",
  },
  {
    path: "/employees",
    title: "Employees",
    element: <ManageEmployee />,
    icon: PeopleAltIcon,
    key: "employees",

  },
  {
    path: "/modules",
    title: "Modules",
    element: <Modules />,
    icon: BentoIcon,
    key: "modules",
  },
  {
    path: "/permissions",
    title: "Permissions",
    element: <UserRolePermissions />,
    icon: VerifiedUserIcon,
    key: 'permissions'
  },
  {
    path: "/managetimesheets",
    title: "Manage Timesheets",
    element: <TimeTrackerView />,
    icon: ViewTimelineIcon,
    key: 'managetimesheet'
  },
  {
    path: "/mytimesheet",
    title: "My Timesheet Tracker",
    element: <TimeTracker />,
    icon: ViewTimelineIcon,
    key: "mytimesheettracker",
  },
  {
    path: "/applyleave",
    title: "Apply Leave",
    element: <ApplyLeave />,
    icon: AssignmentIcon,
    key:'applyleave'
  },
  {
    path: "/leaveapprovals",
    title: "Leave Approvals",
    element: <LeaveApproval />,
    icon: AssignmentTurnedInIcon,
    key: 'leaveapprovals'
  },
  // {
  //   path: "/payslips",
  //   title: "Payslips",
  //   element: <DashboardComponent />,
  //   icon: ReceiptIcon,
  //   key: 'payslips'
  // },
  {
    path: "/manageHolidays",
    title: "Manage Holidays",
    element: <ManageHolidays />,
    icon: CalendarMonthIcon,
    key: "manageholidays",
  },
  {
    path: "/viewHolidays",
    title: "View Holidays",
    element: <ViewHolidays />,
    icon: CalendarMonthIcon,
    key: 'viewholidays'
  },
  {
    path: "/assetManagement",
    title: "Asset Management ",
    element: <ManageAsset />,
    icon: DevicesIcon,
    key: 'assetmanagement'
  },
  {
    path: "/ITDeclaration",
    title: "My IT Declaration",
    element: <ITDeclaration />,
    icon: ArticleIcon,
    key:'itDeclaration'
  },
  {
    path: "/ItdReports",
    title: "ITD reports",
    element: <ItdReports />,
    icon: ArticleIcon,
    key:'itdReports'
  },
  {
    path: "/organizationChart",
    title: "Organization Chart",
    element: <OrgChart />,
    icon: HierarchyIcon,
    key:'organizationChart'
  },
  {
    path: "/myqueries",
    title: "Raise Query",
    element: <MyRaisedQueries/>,
    icon: LiveHelpIcon,
    key:'myqueries'
  },
  {
    path: "/allqueries",
    title: "All Queries",
    element: <AllRaisedQueries/>,
    icon: QuizIcon,
    key:'allqueries'
  },
  {
    path: "/faq",
    title: "FAQ",
    element: <Faqs/>,
    icon: QuestionAnswerIcon,
    key:'faq'
  },
]

export default AllRoutes;