import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';
export const getTimeTrackerData = async (employeeId, currentYear, currentMonth) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/timesheettracker/${employeeId}`,
        {
            params: {
                year: currentYear,
                month: currentMonth
            }
        });
        const Key = `${currentMonth.toLowerCase()}${currentYear}`;
        const timeEntries = response.data[Key];
        return {timeEntries :timeEntries || [] , approvalRequest : response.data.approvalRequest} ;
    } catch (error) {
        console.error("Error fetching time tracker data:", error);
        throw error;
    }
};

export const getTimesheetForEmployees = async (employeeIds, year, month) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/timesheettracker/`, {
        params: { employeeIds, month, year }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Timesheet for ', month, year, error);
      return [];
    }
    };

export const postTimeTrackerData = async (employeeId, data) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/timesheettracker/${employeeId}`, data);
        return response;
    } catch (error) {
        console.error("Error posting time tracker data:", error);
        throw error;
    }
};

export const getTimesheetsForEmployeeAndMonth = async (employeeId, month ,year) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/timesheettracker/`, {
        params: { employeeId, month, year }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Timesheet for ', month, year, error);
      return [];
    }
    };

export const sendTimeSheetApprovalEmail = async (data) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/sendEmail/timeSheetApproval` , data );
        return response.data;
    } catch (error) {
        console.error('Error sending leave approval email :', error);
        throw error;
    }
};

export const getEmployeesByStatus = async (status, month ,year) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/timesheettracker/employees`, {
        params: { status, month, year }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching Timesheet for ', month, year, error);
      return [];
    }
    };