import { Alert, Box, Button, DialogTitle, Grid, IconButton, Dialog, DialogActions, DialogContent, Typography, TextField, Tooltip, CircularProgress } from '@mui/material'
import { Cancel as CancelIcon } from '@mui/icons-material';
import React, { useState, useEffect, useRef } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { NotificationManager } from "react-notifications";
import "./ITDeclaraton.css"
import { saveRegime, getRegimeData, saveProofOfSubmission, getProofsData, updateRegime, getITDConfigurationDetails } from '../../services/itDeclarationService';
import _ from 'lodash';
import originalJsonData from '../../ITD_TEMPLATE.json';
import { useDropzone } from 'react-dropzone';;
import { v4 as uuidv4 } from 'uuid';
import ITDUploadProofOfSubmission from './ITDUploadProofOfSubmission';
import {OLD_REGIME, NEW_REGIME, HOUSE_RENT_THRESHOLD} from '../../Constants';

export default function ITDeclaration() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRegime, setSelectedRegime] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const employeeId = localStorage.getItem("employeeId");
  const [declaredAmounts, setDeclaredAmounts] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [ownerPan, setOwnerPan] = useState();
  const [isProofDialogOpen, setIsProofDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedParticular, setSelectedParticular] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedProofs, setUploadedProofs] = useState([]);
  const [error, setError] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [originalDeclaredAmounts, setOriginalDeclaredAmounts] = useState({});
  const [enableUploadProoOfSubmission, setEnableUploadProoOfSubmission] = useState();
  const [regimeData, setRegimeData] = useState();
  const [fileError, setFileError] = useState('');
  const [editRegimeDetails,setEditRegimeDetails] = useState(true);
  const [jsonData, setJsonData] = useState(_.cloneDeep(originalJsonData));
  const [loading, setLoading] = useState(false);
  const [itdPolicyURL, setItdPolicyURL] = useState();

  const houseRentFieldRef = useRef();
  const panInputRef = useRef(null);
  
  const currentDate = new Date();
  const financialYearStart = currentDate.getMonth() >= 3 ? currentDate.getFullYear() : currentDate.getFullYear() - 1;
  const financialYear = `${financialYearStart}-${(financialYearStart + 1).toString().slice(-2)}`;
  const nextFinancialYear = financialYear.split("-").map(year => parseInt(year) + 1).join("-");

  useEffect(() => {
    fetchRegime();
    fetchITDConfigurationDetails();
  }, []);
  
  useEffect(() => {
    if (isProofDialogOpen) {
      fetchUploadedProofOfSubmission()
    }
  }, [isProofDialogOpen])

  useEffect(() => {
    setUploadedFiles([]);  // Clear uploaded files when item or particular is changed
    setFileError("");      // Clear any file error as well
}, [selectedItem, selectedParticular]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop: (acceptedFiles) => {
        setFileError("");

      if (acceptedFiles.length > 5) {
        setFileError("Only 5 files are allowed.");
        return;
      }

      const validFiles = acceptedFiles.filter(file => file.size <= 500 * 1024);
      const oversizedFiles = acceptedFiles.filter(file => file.size > 500 * 1024);

      if (oversizedFiles.length > 0) {
        setFileError(`Some files exceed 500KB: ${oversizedFiles.map(f => f.name).join(", ")}`);
      }

      if (validFiles.length > 0) {
        setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
      }
    }
});

  const fetchRegime = async () => {
    try {
      setLoading(true)
      const result = await getRegimeData(employeeId, financialYear);
      setLoading(false)
      if (result) {
        setRegimeData(result);
        setSelectedRegime(result.regime);
        setIsSaved(true);
        if (result.regime == OLD_REGIME) {
          result.ownerPan && setOwnerPan(result.ownerPan);
        }
        // Update items and declaredAmounts if applicable
        if (result.oldRegimeDetails) {
          const newDeclaredAmounts = {};
          Object.entries(result.oldRegimeDetails).map(([sectionKey, sectionValue]) => {         //section 
            Object.entries(sectionValue).map(([categoryItemKey, categoryItemValue]) => {       //categoryItems 
              Object.entries(categoryItemValue).map(([key, value]) => {
                if (value.DECLARED_AMOUNT) {                  
                  const targetItem = jsonData.root[sectionKey][categoryItemKey].find(
                    (item) => item.PARTICULARS === value.PARTICULARS
                  );
                  if (targetItem) {
                    targetItem.DECLARED_AMOUNT = value.DECLARED_AMOUNT;
                  }
                  newDeclaredAmounts[`${value.PARTICULARS}`] = value.DECLARED_AMOUNT;
                }
              })
            })
          });
          setDeclaredAmounts(newDeclaredAmounts);
          setOriginalDeclaredAmounts(newDeclaredAmounts);
        }
      }
    } catch (error) {
      console.error("Error fetching regime data:", error);
    }
  };

  const fetchUploadedProofOfSubmission = async () => {
    try {
      const result = await getProofsData(employeeId, financialYear)
      result.proofOfSubmission && setUploadedProofs(result.proofOfSubmission)
      result.oldRegimeDetails && setOriginalData(result.oldRegimeDetails)
    } catch (error) {
      console.error(error, "error fetching uploaded Proof Of Submission details")
    }
  }

  const handleCancel = () => {
    setIsDialogOpen(false);
    setSelectedRegime('');
  };

  const handleProceed = () => {
    setIsDialogOpen(false);
    setIsSaved(true)
  }

  const handlePopupOpen = () => {
    if (!isSaved && editRegimeDetails) {
      setIsDialogOpen(true);
    }
  }

  const handleRegimeSave = async () => {
    try { 
      var regimeDetailsToSave = {
        employeeId: employeeId,
        regime: selectedRegime,
        financialYear: financialYear,
      }
      
      if (selectedRegime === OLD_REGIME) {

        const houseRentAmount = houseRentFieldRef.current.value;
        if (parseFloat(houseRentAmount) > HOUSE_RENT_THRESHOLD && (!ownerPan || error ||ownerPan.length < 10)) {
          if (!ownerPan || ownerPan.length === 0) {
            setError("PAN required");
            panInputRef.current?.focus();
            return;
          } else if (ownerPan.length < 10 || error) {
            setError("Invalid PAN format");
            return;
          }
        }

        const filteredItems = {};

        for (const sectionKey in jsonData.root) {
          const filteredSection = {};

          for (const categoryKey in jsonData.root[sectionKey]) {
            const validEntries = jsonData.root[sectionKey][categoryKey].filter(
              (entry) => entry.DECLARED_AMOUNT && parseFloat(entry.DECLARED_AMOUNT) > 0
            );

            if (validEntries.length) {
              filteredSection[categoryKey] = validEntries;
            }
          }

          if (Object.keys(filteredSection).length) {
            filteredItems[sectionKey] = filteredSection;
          }
        }

        regimeDetailsToSave.oldRegimeDetails = filteredItems;


        if (ownerPan && ownerPan.trim().length > 0) {
          regimeDetailsToSave.ownerPan = ownerPan; 
        }

        if(regimeData){
          await updateRegime(regimeDetailsToSave);
        }
        else{
          await saveRegime(regimeDetailsToSave);
        }
        NotificationManager.success("Regime saved successfully");
        setHasChanges(false)
        setIsSaved(true);
        // fetchRegime();
      } else {
        
        if(regimeData){
          await updateRegime(regimeDetailsToSave);
        }
        else{
          await saveRegime(regimeDetailsToSave);
        }
        NotificationManager.success("Regime saved successfully");
        setIsSaved(true);
        setIsDialogOpen(false);
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Failed to save regime");
      setIsDialogOpen(false);
    }
  };

  const handlePanChange = (e) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const filteredValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "");

    setOwnerPan(filteredValue);

    if (filteredValue.length === 0 || filteredValue.length < 10) {
      setError("");
    } else if (!panRegex.test(filteredValue)) {
      setError("Invalid PAN format");
    }
  };

  const handleDeclaredAmountChange = (e, entry, categoryKey, propertyKey) => {
    const value = e.target.value.trim();
    const numericRegex = /^[0-9]*\.?[0-9]*$/;

    // If the input is numeric or empty, update the state; otherwise, do nothing
    if (numericRegex.test(value) || value === "") {
      const updatedDeclaredAmounts = {
        ...declaredAmounts,
        [`${entry.PARTICULARS}`]: value,
      };
      setDeclaredAmounts(updatedDeclaredAmounts);

      entry[propertyKey] = value;

      // originalDeclaredAmounts will store new values entered
      if (!originalDeclaredAmounts.hasOwnProperty(entry.PARTICULARS)) {
        setOriginalDeclaredAmounts(prev => ({
          ...prev,
          [`${entry.PARTICULARS}`]: "",
        }));
      }

      // Check if any changes exist
      const hasAnyChange = Object.keys(updatedDeclaredAmounts).some(key => 
        originalDeclaredAmounts[key] !== updatedDeclaredAmounts[key]
    );

      setHasChanges(hasAnyChange);

      if (categoryKey === "HOUSE RENT") {

        setDeclaredAmounts(prev => ({
          ...prev,
          [`PAN-Input-${categoryKey.replace(" ", "")}`]: parseFloat(value) > HOUSE_RENT_THRESHOLD,
        }));

        if (parseFloat(value) <= HOUSE_RENT_THRESHOLD) {
          setOwnerPan('');
          setError('');
        }
      }
    }
    else {
        // If a non-numeric value is entered, revert back to the last valid number
        e.target.value = declaredAmounts[entry.PARTICULARS] || ""; 
    }
  }

  const handleProofDialogOpen = () => {
    setIsProofDialogOpen(true);
    setFileError('');
  };

  const handleProofDialogClose = () => {
    setSelectedItem('');
    setSelectedParticular('');
    setIsProofDialogOpen(false);
    setUploadedFiles('');
    setFileError('');
  };

  const handleSaveProofOfSubmission = async () => {
    try {
      const formData = new FormData();
      formData.append("employeeId", employeeId);
      formData.append("financialYear", financialYear);
      formData.append("proofId", uuidv4());
      formData.append("items", selectedItem);
      formData.append("particulars", selectedParticular);
      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append('proofFiles', uploadedFiles[i]);
      }
      await saveProofOfSubmission(formData);
      NotificationManager.success('Proof of Submission saved successfully');
      fetchUploadedProofOfSubmission();
      setSelectedItem('')
      setSelectedParticular('')
      setUploadedFiles('')
    } catch (error) {
      NotificationManager.error('Failed to save Proof of Submission');
      console.error(error)
    }
  }

  const renderTableRows = () => {
    return Object.keys(jsonData.root).map((sectionKey, sectionIndex) => {
      const section = jsonData.root[sectionKey];
      return (
        <>
          <tr className='section'>
            <td colSpan={Object.keys(section[Object.keys(section)[0]][0]).length + 1} className='section'>
              {sectionKey}
            </td>
          </tr>
          {Object.keys(section).map((categoryKey, categoryIndex) => {
            const category = section[categoryKey];
            return category.map((entry, entryIndex) => (
              <tr key={`${sectionIndex}-${categoryIndex}-${entryIndex}`} className='category-tr'>
                {entryIndex === 0 && (
                  <td rowSpan={category.length} className='category'>
                    {categoryKey}
                  </td>
                )}
                {Object.keys(entry).map((propertyKey) => (
                  <td key={propertyKey} className='textfield-td'>
                    {propertyKey !== 'DECLARED_AMOUNT' ? entry[propertyKey] : (
                      <div className='text-fields'>
                        <TextField
                           inputRef= {categoryKey === "HOUSE RENT" && houseRentFieldRef != undefined ? houseRentFieldRef : undefined } 
                           inputProps={{
                            maxLength: 10,
                            readOnly : !editRegimeDetails
                          }}
                          size='small'
                          value={declaredAmounts[`${entry.PARTICULARS}`]}
                          onChange={(e) => { handleDeclaredAmountChange(e, entry, categoryKey, propertyKey) }}
                        />

                        {categoryKey === "HOUSE RENT" && parseFloat(entry.DECLARED_AMOUNT) > HOUSE_RENT_THRESHOLD && (
                          <div className='text-fields'>
                            <TextField
                              inputProps={{
                                maxLength: 10,
                                readOnly: !editRegimeDetails,
                              }}
                              inputRef={panInputRef}
                              size='small'
                              label='Enter PAN no of Owner'
                              placeholder='eg:AFZPK7190K'
                              variant="outlined"
                              value={ownerPan}
                              onChange={handlePanChange}
                              error={error}
                              helperText={error}
                            />
                          </div>
                        )}
                      </div>)}

                  </td>
                ))}
              </tr>
            ));
          })}
        </>
      );
    });
  };

  const fetchITDConfigurationDetails = async () => {
    try {
      const response = await getITDConfigurationDetails();
      setEnableUploadProoOfSubmission(response.ENABLE_PROOF_OF_SUBMISSION);
      setEditRegimeDetails(response.REGIME_DETAILS_EDITABLE);
      setItdPolicyURL(response.ITD_POLICY_URL);

    } catch (error) {
      console.error('Error fetching proof of submission button status:', error);
    }
  }

  const proofSubmissionProps = {
    isOpen: isProofDialogOpen,
    onClose: handleProofDialogClose,
    originalData: originalData,
    selectedItem: selectedItem,
    setSelectedItem: setSelectedItem,
    uploadedProofs: uploadedProofs,
    selectedParticular: selectedParticular,
    setSelectedParticular: setSelectedParticular,
    uploadedFiles: uploadedFiles,
    handleSave: handleSaveProofOfSubmission,
    getRootProps: getRootProps,
    fetchUploadedProofOfSubmission: fetchUploadedProofOfSubmission,
    getInputProps: getInputProps,
    financialYear: financialYear,
    nextFinancialYear: nextFinancialYear,
    fileError: fileError,
    setFileError: setFileError
  };

  const handleDownload = () => {
    window.open(itdPolicyURL);
  };
  

    if (loading) {
      return <div className='loading-container'><CircularProgress /></div>; 
    }

  return (
    <Box className='main-content'>
      <Grid container className='grid-container-header align-header-center'>
        <Grid item xs={6}>
          My IT Declaration
        </Grid>
        <Grid item xs={6} className='align-filters-right' >
          {(selectedRegime === OLD_REGIME && enableUploadProoOfSubmission && isSaved ) && (<Button variant="contained" color="primary" onClick={handleProofDialogOpen} > Upload Proof of Submission
          </Button>)}
          <Tooltip
            title={`View IT declaration policy for ${financialYear}`}
            arrow
          >
            <Button variant="contained" onClick={handleDownload}>View Policy</Button>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container className="it-declaration-container">
        <Grid item xs={12} className='ITD-upload-proof-align-right'>
          <FormControl>
            <Typography>
              <strong>Select the regime for the financial year {financialYear} (Assessment Year {nextFinancialYear})</strong>
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={selectedRegime}
              onChange={(event)=>setSelectedRegime(event.target.value)}
              className='radio-group'
            >
              <FormControlLabel
                className="radio-small"
                value={NEW_REGIME}
                control={<Radio />}
                label="New Regime"
                disabled={isSaved && selectedRegime !== NEW_REGIME || !editRegimeDetails}
                onClick={handlePopupOpen} />

              <FormControlLabel
                className="radio-small"
                value={OLD_REGIME}
                control={<Radio />}
                label="Old Regime"
                disabled={isSaved && selectedRegime !== OLD_REGIME || !editRegimeDetails}
                onClick={handlePopupOpen} />
            </RadioGroup>
            {!editRegimeDetails && ( <Typography style={{color:"red"}}>Note: Currently you cannot perform edits on this page. Please contact Accounts team.
              </Typography>)}
            {(selectedRegime === NEW_REGIME && isSaved && editRegimeDetails) && ( <Typography style={{color:"green"}}>Note : You have already selected New Regime for the financial year {financialYear}. Further changes are not allowed.</Typography>)}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {(selectedRegime === OLD_REGIME && isSaved) && (
            <table>
              <thead>
                <tr>
                  <th>ITEMS</th>
                  <th>PARTICULARS</th>
                  <th>DECLARED AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows()}
              </tbody>
            </table>
          )}
        </Grid>
        <Grid item xs={12} className='align-itdeclaration-buttons-right'>
          {editRegimeDetails && (selectedRegime === OLD_REGIME  && isSaved) && (
            <Button variant="contained" onClick={handleRegimeSave}
              disabled={ error || !hasChanges}
            >
              Submit
            </Button>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={handleCancel}
      >
        <DialogTitle className='confirmDialogTitle'>
          Regime selection Confirmation
          <IconButton aria-label="close" onClick={handleCancel}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity="warning" className="confirmDialogMsg">
            You are about to select the <strong>{selectedRegime === NEW_REGIME ? "New Tax Regime" : "Old Tax Regime"}</strong> for the Financial Year {financialYear}.
            Please note that once {selectedRegime === NEW_REGIME ? "submitted" : "selected"}, this decision cannot be reversed.
            Are you sure you want to {selectedRegime === NEW_REGIME ? "submit your selection?" : <>proceed and submit your <strong>IT declarations</strong>?</>}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined" color="primary">
            Cancel
          </Button>

          <Button
            onClick={selectedRegime === NEW_REGIME ? handleRegimeSave : handleProceed}
            variant="contained" color="primary">
            {selectedRegime === NEW_REGIME ? 'Submit' : 'Proceed'}
          </Button>
        </DialogActions>
      </Dialog>
      <ITDUploadProofOfSubmission {...proofSubmissionProps} />
    </Box>
  )
}
