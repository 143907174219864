import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';

export const getAllLeaves = async (selectedStatus) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/leaves`,
        {
            params: { status: selectedStatus === 0 ? undefined : selectedStatus }
        });
      return response.data;
    } catch (error) {
      console.error('Error fetching leaves:', error);
      throw error;
    }
};

export const getLeavesByEmployeeId = async (employeeId, selectedStatus) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/leaves/${employeeId}`,
            {
                params: { status: selectedStatus === 0 ? undefined : selectedStatus },
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching leaves by employee ID:', error);
        throw error;
    }
};

export const getLeavesByApproverId = async (approverId, selectedEmployee, selectedStatus) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/leaves/approver/${approverId}`,
            {
                params: {
                    employeeId: selectedEmployee,
                    status: selectedStatus === 0 ? undefined : selectedStatus
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching leaves by approver ID:', error);
        throw error;
    }
};

export const createLeave = async (formData) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/leaves`, formData);
        return response.data;
    } catch (error) {
        console.error('Error creating leave:', error);
        throw error;
    }
};

export const updateLeave = async (id, status, adminRejectComment = '') => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/leaves/${id}`, { status, adminRejectComment });
        return response.data;
    } catch (error) {
        console.error('Error updating leave:', error);
        throw error;
    }
};

export const updateLeaveRejectRequest = async (id,employeeRejectRequestComment = '') => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/leaves/reject-request/${id}`,{employeeRejectRequestComment});
        
        return response.data;
    } catch (error) {
        console.error('Error updating reject request:', error);
        throw error; 
    }
};

export const deleteLeave = async (leaveId) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/leaves/${leaveId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting leave:', error);
        throw error;
    }
};

export const fetchLeavesByEmployeeIdAndDate = async (employeeId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/leaves/${employeeId}/financialYear`);
        return response.data;
    } catch (error) {
        console.error('Error fetching leaves by employeeId for financial year:', error);
        throw error;
    }
};

export const sendLeaveApprovalEmail = async (emailData) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/sendEmail`, emailData);
        return response.data;
    } catch (error) {
        console.error('Error sending leave approval email :', error);
        throw error;
    }
};

export const fetchLeavesByEmployeeIdAndMonthYear = async (employeeId, month, year) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/leaves/leave-status/${employeeId}/${month}/${year}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching leaves by employeeId, year and month:', error);
        throw error;
    }
};

export const getLeavesThreshold = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/leaves/leavesThreshold`);
      return response.data;
    } catch (error) {
      console.error('Error fetching leaves:', error);
      throw error;
    }
};