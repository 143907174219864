import axios from 'axios';

const axiosInstance = axios.create();



axiosInstance.interceptors.request.use(
  (config) => {

    var token = sessionStorage.getItem('userToken'); 

    if (token) {
      config.headers.Authorization = `Bearer ${token}`; 
    }
    console.log("config", config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
