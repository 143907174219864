import { Box, Button, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Paper, Select, Typography, TextareaAutosize } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./itdReports.css";
import { getEmployeesByRegime, getRegimeData, enableITDFlags, getProofsDataForItdReports, getITDConfigurationDetails } from '../../services/itDeclarationService';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Download as DownloadIcon } from '@mui/icons-material';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { NotificationManager } from 'react-notifications';
import { Cancel as CancelIcon, } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import {OLD_REGIME, NEW_REGIME} from '../../Constants';

export default function ItdReports() {

    const currentDateNew = new Date();
    const financialYearStart = currentDateNew.getMonth() >= 3 ? currentDateNew.getFullYear() : currentDateNew.getFullYear() - 1;
    const financialYear = `${financialYearStart}-${(financialYearStart + 1).toString().slice(-2)}`;
    const previousFinancialYear = financialYear.split("-").map(year => parseInt(year) - 1).join("-");

    const [selectedRegime, setSelectedRegime] = useState();
    const [empDetails, setEmpDetails] = useState([]);
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(financialYear);
    const [uploadProofButtonEnabled, setUploadProofButtonEnabled] = useState(false);
    const [enableProofButtonDialogOpen, setEnableProofButtonDialogOpen] = useState(false);
    const [editRegimeDetails, setEditRegimeDetails] = useState(false);
    const [editRegimeDetailsDialogOpen, setEditRegimeDetailsDialogOpen] = useState(false);
    const [uploadITDPolicyUrlDialogOpen, setUploadITDPolicyUrlDialogOpen] = useState(false);
    const [itdPolicyUrl, setItdPolicyUrl] = useState();

    const fetchEmployeeNamesByRegime = async () => {
        try {
            const result = await getEmployeesByRegime(selectedRegime,selectedFinancialYear)
            setEmpDetails(result);
        } catch (error) {
            console.error('Error fetching employee names by regime and financial year', error);
        }
    }

    useEffect(() => {
        if (selectedRegime && selectedFinancialYear) {
            fetchEmployeeNamesByRegime()
        }
    }, [selectedRegime, selectedFinancialYear])

    useEffect(() => {
        const fetchITDConfigurationDetails = async () => {
            try {
                const response = await getITDConfigurationDetails();
                setUploadProofButtonEnabled(response.ENABLE_PROOF_OF_SUBMISSION);
                setEditRegimeDetails(response.REGIME_DETAILS_EDITABLE);
                setItdPolicyUrl(response.ITD_POLICY_URL);
            } catch (error) {
                console.error('Error fetching ITDConfigurationDetails:', error);
            }
        }
        fetchITDConfigurationDetails();
    }, [])

    const handleFinancialYearSelection = (e) => {
        setSelectedFinancialYear(e.target.value)
    }

    const handleDownload = async (emp) => {
        try {
            const result = await getRegimeData(emp.employeeId, financialYear);
            const regimeDetails = result.oldRegimeDetails;
            const ownerPan = result.ownerPan;

            const doc = new jsPDF();
            // Centered Header
            const pageWidth = doc.internal.pageSize.width;
            const centerText = (text, y) => {
                const textWidth = doc.getTextWidth(text);
                const x = (pageWidth - textWidth) / 2;
                doc.text(text, x, y);
            };

            doc.setFontSize(14);
            doc.setFont("helvetica", "bold");
            centerText("PHYELEMENTS LLP", 15);
            doc.setFontSize(12);
            centerText(`INCOME TAX DECLARATION FORM - Financial Year ${financialYear}`, 25);

            const employeeDetails = [
                ["Employee Name", `${emp.firstName} ${emp.lastName}`,"Employee Number", emp.employeeNumber,],
                ["PAN Number", emp.panNumber, "Regime", "Old"],
            ];
            if (ownerPan) {
                employeeDetails.push(["House Owner PAN", ownerPan]);
            }

            doc.autoTable({
                startY: 35,
                body: employeeDetails,
                theme: "grid",
                styles: {
                    fontSize: 10,
                    halign: "center",
                    cellPadding: 2,
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                },
                columnStyles: {
                    0: { fontStyle: "bold" }, // First column (labels)
                    2: { fontStyle: "bold" }, // Second column (labels)
                },
                tableWidth: "auto", // Allow dynamic width
                margin: { left: 15 }, // Align table to start at same position as tax table
            });

            // Leave space before tax details table
            let tableStartY = doc.autoTable.previous.finalY + 5;

            // Define Tax Declaration Table
            const tableColumns = ["ITEMS", "PARTICULARS", "DECLARED AMOUNT"];
            const tableRows = [];

            // Track row spans for ITEMS column
            const itemsRowSpanMap = new Map();

            Object.keys(regimeDetails).forEach((sectionKey) => {
                const section = regimeDetails[sectionKey];
                tableRows.push([
                    { content: sectionKey, colSpan: 3, styles: { halign: "center", fontStyle: "bold" } },
                ]); // Section header spans all columns

                Object.keys(section).forEach((categoryKey) => {
                    const category = section[categoryKey];

                    category.forEach((entry, index) => {
                        const declaredAmount = entry.DECLARED_AMOUNT || 0;
                        if (index === 0) {
                            itemsRowSpanMap.set(categoryKey, category.length); // Store rowspan count
                            tableRows.push([categoryKey, entry.PARTICULARS, declaredAmount]);
                        } else {
                            tableRows.push(["", entry.PARTICULARS, declaredAmount]); // Simulating rowspan
                        }
                    });
                });
            });

            // Add Tax Declaration Table
            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: tableStartY,
                margin: { left: 15 }, // Ensure both tables start at the same position
                tableWidth: "auto", // Allow dynamic width while maintaining alignment
                styles: {
                    fontSize: 9,
                    halign: "center",
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                    cellPadding: 2,
                    overflow: "linebreak",
                    valign: "middle"
                },
                bodyStyles: {
                    fillColor: [255, 255, 255], // No alternate row color
                },
                alternateRowStyles: {
                    fillColor: null, // Disable row striping
                },
                didParseCell: (data) => {
                    const rowIndex = data.row.index;
                    const cellIndex = data.column.index;
                    const sectionRows = tableRows[rowIndex];

                    if (cellIndex === 0 && sectionRows[0] !== "") {
                        const categoryKey = sectionRows[0];
                        if (itemsRowSpanMap.has(categoryKey)) {
                            const rowSpanValue = itemsRowSpanMap.get(categoryKey);
                            data.cell.rowSpan = rowSpanValue;
                            itemsRowSpanMap.delete(categoryKey); // Prevent duplicate row spans
                        }
                    }
                },
            });

            // Save PDF
            doc.save(`${emp.firstName}_${emp.lastName}_ITD_Form_${financialYear}.pdf`);
        } catch (error) {
            console.error("Error fetching Regime:", error);
        }
    };

    const handleZipFolder = async (emp) => {
        try {
            const result = await getProofsDataForItdReports(emp.employeeId, financialYear);
            const regimeDetails = result.proofOfSubmission;
            const zip = new JSZip();

            for (const proof of regimeDetails) {
                for (const file of proof.files) {
                    const { base64Data, contentType, filename } = file;

                    // Convert Base64 to Blob
                    const byteCharacters = atob(base64Data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: contentType });

                    zip.file(filename, blob);
                }
            }

            // Generate ZIP and trigger download
            const zipBlob = await zip.generateAsync({ type: "blob" });
            saveAs(zipBlob, `${emp.firstName}_ITD_Proofs_${financialYear}.zip`);

        } catch (error) {
            console.error("Error creating ZIP file:", error);
        }
    };

    const confirmProofSubmissionStatus = async () => {
        try {
            await enableITDFlags({uploadProofStatus : !uploadProofButtonEnabled}); // Toggle the status
            setUploadProofButtonEnabled((prev) => !prev); // Update state only after confirmation
            NotificationManager.success('Proof of submission status updated successfully');
        } catch (error) {
            console.error('Failed to update proof of submission status:', error);
            NotificationManager.error('Failed to update proof of submission status.');
        } finally {
            setEnableProofButtonDialogOpen(false); // Close the dialog after action
        }
    };

    const confirmEditRegimeDetails = async () => {
        try {
            await enableITDFlags ({editRegimeStatus : !editRegimeDetails}); 
            setEditRegimeDetails((prev) => !prev);
            NotificationManager.success(`Edit Regime details is ${!editRegimeDetails ? 'enabled' : 'disabled'}`);
        } catch (error) {
            console.error('Failed to update regime details editable status:', error);
            NotificationManager.error('regime details not editable');
        } finally {
            setEditRegimeDetailsDialogOpen(false); 
        }
    };

    const uploadITDPolicyUrl = async () => {
      try {
        await enableITDFlags ({itdPolicyUrl: itdPolicyUrl});
        NotificationManager.success("ITD policy document URL uploaded successfully"); 
        } catch (error) {
            console.error('Failed to update regime details editable status:', error);
        } finally {
            setUploadITDPolicyUrlDialogOpen(false); 
        }
    }

    return (
        <Box className='main-content'>
            <Grid container className='grid-container-header align-header-center'>
                <Grid item xs={12} md={2}>
                    IT Declaration Reports
                </Grid>
                <Grid item xs={12} md={10} className='align-filters-right'>
                    {selectedRegime === OLD_REGIME && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={uploadProofButtonEnabled}
                                    onChange={() => setEnableProofButtonDialogOpen(true)}
                                />
                            }
                            label="Enable Proof of Submission"
                            sx={{
                                paddingLeft: "16px",
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '0.9rem',
                                    fontWeight: 'normal'
                                },
                            }}
                        />
                    )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={editRegimeDetails}
                                    onChange={() => setEditRegimeDetailsDialogOpen(true)}
                                />
                            }
                            label="Edit Regime Details "
                            sx={{
                                paddingLeft: "16px",
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '0.9rem',
                                    fontWeight: 'normal'
                                },
                            }}
                        />
                    <FormControl className='dropdown-regime-selection' size="small">
                        <InputLabel >Select Regime</InputLabel>
                        <Select
                            value={selectedRegime}
                            label="Select Regime"
                            onChange={(e)=>setSelectedRegime(e.target.value)}
                        >
                            <MenuItem value={NEW_REGIME}>New Regime</MenuItem>
                            <MenuItem value={OLD_REGIME}>Old Regime</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className='dropdown-regime-selection' size="small">
                        <InputLabel >Select Financial Year</InputLabel>
                        <Select
                            value={selectedFinancialYear}
                            label="Select Financial Year"
                            onChange={handleFinancialYearSelection}
                        >
                            {[previousFinancialYear, financialYear].map((fy) => {
                                const ay = fy.split("-").map(year => parseInt(year) + 1).join("-"); 
                                return (
                                    <MenuItem key={fy} value={fy}>
                                        {`${fy} (Assessment Yr ${ay})`}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    <Button variant="contained" color="primary" onClick={(e)=>setUploadITDPolicyUrlDialogOpen(true)}>Set Policy URL</Button>

                </Grid>
            </Grid>
            <Grid className='itd-reports-container'>
                <Grid item xs={12} style={{ width: "60%" }}>
                {selectedRegime ? (
                    <Paper sx={{ p: 2, backgroundColor: "#f9f9f9" }}>
                        {/* Table Header */}
                        <Grid container sx={{ padding: "10px 16px", fontWeight: "bold" }}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" sx={{ textAlign: "left" }}>Employee Name</Typography>
                            </Grid>
                            {selectedRegime === OLD_REGIME && (
                                <>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>ITD Form</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>Download Proofs</Typography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Divider />

                        {/* Employee List */}
                        <List>
                            {empDetails.length === 0 ? (
                                <ListItem>
                                    <Typography variant="body2" sx={{ textAlign: "center", width: "100%" }}>
                                    No employees found for the selected Regime
                                    </Typography>
                                </ListItem>
                            ) : (
                                empDetails.map((emp) => (
                                    <ListItem key={emp.employeeId} divider >
                                        <Grid container alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography variant="body2" sx={{ textAlign: "left" }}>
                                                    {emp.firstName} {emp.lastName}
                                                </Typography>
                                            </Grid>

                                            {selectedRegime === OLD_REGIME && (
                                                <>
                                                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                                                        <IconButton onClick={() => handleDownload(emp)}>
                                                            <Tooltip title="Download ITD form">
                                                                <DownloadIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                                                        <Tooltip title={emp.proofId ? "Download proofs" : "No proofs uploaded"} arrow key="assets" >
                                                            <span> <IconButton onClick={() => handleZipFolder(emp)} disabled={!emp.proofId} >
                                                                <FolderZipIcon />
                                                            </IconButton></span>
                                                        </Tooltip>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </ListItem>
                                ))
                            )}
                        </List>
                    </Paper>
                ) : null}
                </Grid>
            </Grid>
            <Dialog
                open={enableProofButtonDialogOpen}
                onClose={() => setEnableProofButtonDialogOpen(false)}
            >
                <DialogTitle className='confirmDialogTitle'>
                    Confirmation
                    <IconButton aria-label="close" onClick={() => setEnableProofButtonDialogOpen(false)}>
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='confirmDialogContent'>
                    <Alert severity='warning' className='confirmDialogMsg'>
                        {uploadProofButtonEnabled
                            ? `You are about to disable employees from uploading the proof of submission for the financial year ${financialYear}, Do you wish to continue?`
                            : `You are about to enable employees to upload their proof of submission for the financial year ${financialYear}, Do you wish to continue?`}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEnableProofButtonDialogOpen(false)} variant="contained" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmProofSubmissionStatus} variant="contained" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={editRegimeDetailsDialogOpen}
                onClose={() => setEditRegimeDetailsDialogOpen(false)}
            >
                <DialogTitle className='confirmDialogTitle'>
                    Confirmation
                    <IconButton aria-label="close" onClick={() => setEditRegimeDetailsDialogOpen(false)}>
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='confirmDialogContent'>
                    <Alert severity='warning' className='confirmDialogMsg'>
                        {editRegimeDetails
                            ? `You are about to disable employees from updating the regime details for the financial year ${financialYear}, Do you wish to continue?`
                            : `You are about to enable employees to update their regime details for the financial year ${financialYear}, Do you wish to continue?`}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditRegimeDetailsDialogOpen(false)} variant="contained" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmEditRegimeDetails} variant="contained" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={uploadITDPolicyUrlDialogOpen}
                onClose={() => setUploadITDPolicyUrlDialogOpen(false)}
                PaperProps={{ sx: { width: '400px', borderRadius: "10px" } }}
            >
                <DialogTitle className='confirmDialogTitle'>
                     Set ITD Policy Document URL
                    <IconButton aria-label="close" onClick={() => setUploadITDPolicyUrlDialogOpen(false)}>
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{padding:"10px"}}>
                    <TextareaAutosize
                        value={itdPolicyUrl}
                        onChange={(e) => setItdPolicyUrl(e.target.value)}
                        placeholder="Enter ITD Policy Document URL here (Google Drive Link)"
                        className='text-area'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUploadITDPolicyUrlDialogOpen(false)} variant="contained" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={uploadITDPolicyUrl} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
