import React, { useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import RaisedQueries from './RaisedQueries'
import RaiseQueryPopup from './RaiseQueryPopup';
import HelpIcon from '@mui/icons-material/Help';

export default function MyRaisedQueries() {

const [openQueryPopup, setOpenQueryPopup] = useState(false);
const [selectedQuery, setSelectedQuery] = useState(null);
const [popupTitle,setPopupTitle] = useState();
const [refreshQueriesGrid, setRefreshQueriesGrid] = useState(false);
const employeeId = localStorage.getItem("employeeId");

const handleRaiseQuery =()=>{
  setPopupTitle('Raise a Query')
  setRefreshQueriesGrid(false);
  setOpenQueryPopup(true);
  setSelectedQuery(null)
}

const handleCloseQueryPopup = (isSubmitClicked) => {
  if(isSubmitClicked){
    setRefreshQueriesGrid(isSubmitClicked);
  }
  setOpenQueryPopup(false);
};


const handleEditQueryPopup = (query) => {
  setPopupTitle('Edit a Query')
  setRefreshQueriesGrid(false);
  setOpenQueryPopup(true);
  setSelectedQuery(query);
};
  
  return (
    <Box className='main-content'>
      <Grid container className='grid-container-header align-header-center'>
        <Grid item xs={6}>
          My Queries
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
        <Button variant='contained' color='primary' onClick={handleRaiseQuery} startIcon={<HelpIcon/>}>Raise a Query</Button>
        </Grid>
      </Grid>

      <Grid container padding={2} style={{ flexGrow: 1, overflow: 'hidden', backgroundColor: '#fff' }}>
        <Grid item xs={12}>
        <RaisedQueries handleEditQueryPopup={handleEditQueryPopup} myQueries={true} refreshQueriesGrid = {refreshQueriesGrid} />
        </Grid>
      </Grid>
      <RaiseQueryPopup
        open={openQueryPopup}
        handleCloseQueryPopup={handleCloseQueryPopup}
        employeeId={employeeId}
        selectedQuery={selectedQuery}
        popupTitle={popupTitle}
      />
    </Box>
  )
}
