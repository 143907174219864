import React, { useState, useRef , useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Drawer, List, ListItemButton, ListItemText, Grid, useMediaQuery, Collapse, Popover, useTheme, Box } from '@mui/material';
import { Menu as MenuIcon, LinkedIn as LinkedInIcon, ExpandLess, ExpandMore} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Logo from '../../assets/logos/phyelements_logo.png';
import './PhyelementsMain.css';

function PhyelementsMain() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuVLSI, setSubmenuVLSI] = useState(null);
  const [submenuAi, setSubmenuAi] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [vlsiOpen, setVlsiOpen] = useState(false);
  const [aiOpen, setAiOpen] = useState(false);

  const vlsiTimerRef = useRef(null);
  const aiTimerRef = useRef(null);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleServicesClick = () => {
    setServicesOpen(!servicesOpen);
  };

  const handleVlsiClick = () => {
    setVlsiOpen(!vlsiOpen);
  };

  const handleAiClick = () => {
    setAiOpen(!aiOpen);
  };

  const handleloginButton = () =>{
    navigate('/login')
  }
  
  const drawerContent = (
    <List className="drawer">
      <ListItemButton>
        <ListItemText primary="ABOUT" />
      </ListItemButton>
      <ListItemButton onClick={handleServicesClick}>
        <ListItemText primary="SERVICES" />
        {servicesOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton onClick={handleVlsiClick}>
            <ListItemText primary="VLSI Semiconductors" />
            {vlsiOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={vlsiOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton>
                <ListItemText primary="Memory Layout" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Memory Design" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Analog Layout" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={handleAiClick}>
            <ListItemText primary="AI / ML" />
            {aiOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={aiOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton>
                <ListItemText primary="Deep Learning" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Natural Language Processing" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Computer Vision" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Collapse>
      <ListItemButton>
        <ListItemText primary="CAREERS" />
      </ListItemButton>
      <ListItemButton>
        <ListItemText primary="CONTACT" />
      </ListItemButton>
      <ListItemButton onClick={handleloginButton}>
        <ListItemText primary="PHYE-HRMS LOGIN" />
      </ListItemButton>
      <ListItemButton>
      <LinkedInIcon/>
      </ListItemButton>
    </List>
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setSubmenuVLSI(null);
    setSubmenuAi(null);
  };

  const handleSubmenuMouseLeave = () => {
    setSubmenuVLSI(null);
    setSubmenuAi(null);
  };

  const handleVLSIMenuLeave = () => {
    vlsiTimerRef.current = setTimeout(() => setSubmenuVLSI(null), 200);
  };

  const handleAIMenuLeave = () => {
    aiTimerRef.current = setTimeout(() => setSubmenuAi(null), 200);
  };

  const handleVLSIMenuHover = (event) => {
    if (!submenuVLSI) {
      setSubmenuVLSI(event.currentTarget);
      setSubmenuAi(null);
    }
  };

  const handleAIMenuHover = (event) => {
    if (!submenuAi) {
      setSubmenuAi(event.currentTarget);
      setSubmenuVLSI(null);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuVLSI(null);
    setSubmenuAi(null);
  };

  useEffect(()=>{
    localStorage.clear();
    sessionStorage.clear();
  },[])

  return (
    <div>
      <AppBar position="static" className="appbar">
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <img src={Logo} alt="Logo" className="logo" />
            {isMobile ? (
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            ) : (
              <Grid item>
                <Button className="menu-button">ABOUT</Button>
                <Button
                  onClick={handleMenuClick}
                  className="menu-button"
                >
                  SERVICES
                  {anchorEl ? <ExpandLess className="menu-icon" /> : <ExpandMore className="menu-icon" />}
                </Button>
                <Menu
                  id="services-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  onMouseLeave={handleMenuClose}
                >
                  <MenuItem
                    onMouseEnter={handleVLSIMenuHover}
                    onMouseLeave={handlePopoverClose}
                  >
                    VLSI Semiconductors
                    {submenuVLSI ? <ExpandLess className="menu-icon" /> : <ExpandMore className="menu-icon" />}
                    <Popover
                      id="vlsi-popover"
                      open={Boolean(submenuVLSI)}
                      anchorEl={submenuVLSI}
                      onClose={handlePopoverClose}
                      onMouseLeave={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem onClick={handlePopoverClose}>Memory Layout</MenuItem>
                      <MenuItem onClick={handlePopoverClose}>Memory Design</MenuItem>
                      <MenuItem onClick={handlePopoverClose}>Analog Layout</MenuItem>
                    </Popover>
                  </MenuItem>

                  <MenuItem
                    onMouseEnter={handleAIMenuHover}
                    onMouseLeave={handlePopoverClose}
                  >
                    AI / ML
                    {submenuAi ? <ExpandLess className="menu-icon" /> : <ExpandMore className="menu-icon" />}
                    <Popover
                      id="ai-popover"
                      open={Boolean(submenuAi)}
                      anchorEl={submenuAi}
                      onClose={handlePopoverClose}
                      onMouseLeave={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem onClick={handlePopoverClose}>Deep Learning</MenuItem>
                      <MenuItem onClick={handlePopoverClose}>Natural Language Processing</MenuItem>
                      <MenuItem onClick={handlePopoverClose}>Computer Vision</MenuItem>
                    </Popover>
                  </MenuItem>
                </Menu>
                <Button className="menu-button">CAREERS</Button>
                <Button className="menu-button">CONTACT</Button>
                <Button className="menu-button" onClick={handleloginButton}>PHYE-HRMS LOGIN</Button>
                <Button className="menu-button"><LinkedInIcon fontSize='large'/></Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
      {/* Swiper Integration */}
      <Box className="swiper-container">
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          modules={[Mousewheel, Pagination]}
          speed={700}
          className="mySwiper"
        >
          <SwiperSlide>Slide 1</SwiperSlide>
          <SwiperSlide>Slide 2</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide>
        </Swiper>
      </Box>
    </div>
  );
}

export default PhyelementsMain;
