const BASE_URL = `${process.env.REACT_APP_NODE_API_BASE_URL}${process.env.REACT_APP_NODE_API_PORT_NUMBER}`;

const ADMIN_ROUTES = ["modules", "permissions", "roles", "myprofile"];

const USER_ROLES = {
  EMPLOYEE: "EMPLOYEE",
  ADMIN: "ADMIN",
  HR: "HR",
  MANAGER: "MANAGER",
};

const LEAVE_STATUS = {
  APPROVED: "Approved",
  CANCELED: "Canceled",
  PENDING: "Pending",
  REJECTED: "Rejected",
  APPROVED_COLOR: "#81BC06",
  CANCELED_COLOR: "#FFBA08",
  PENDING_COLOR: "#66c2ff",
  REJECTED_COLOR: "#ff3333",
};

//This constant will be used where active and inactive status is deifined across application
const STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  ACTIVE_COLOR: "#81BC06",
  INACTIVE_COLOR: "#B4B4B4",
};

const WEEKLY_REPORT_STATUS = {
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
  COMPLETED_COLOR: "#81BC06",
  IN_PROGRESS_COLOR: "#66c2ff",
};

const LEAVE_TAKEN = "LT";

const CLIENT_HOLIDAY = "CH";

const TIMESHEET_DROPDOWN_OPTIONS = [8, LEAVE_TAKEN, CLIENT_HOLIDAY, 7, 6, 5, 4];

const REJECT_REQUEST_ALLOWED_DAYS = 2;

const MAX_LEAVE_REQUEST_DURATION = 14; //Two weeks

const PAGE_SIZE_OPTIONS = [10,25,50,100];

const ROWS_PER_PAGE = 10;

const OLD_REGIME = "old";
const NEW_REGIME = "new";
const HOUSE_RENT_THRESHOLD = 99996;

export {
  BASE_URL,
  ADMIN_ROUTES,
  USER_ROLES,
  LEAVE_TAKEN,
  CLIENT_HOLIDAY,
  TIMESHEET_DROPDOWN_OPTIONS,
  LEAVE_STATUS,
  STATUS,
  WEEKLY_REPORT_STATUS,
  REJECT_REQUEST_ALLOWED_DAYS,
  MAX_LEAVE_REQUEST_DURATION,
  PAGE_SIZE_OPTIONS,
  ROWS_PER_PAGE,
  OLD_REGIME,
  NEW_REGIME,
  HOUSE_RENT_THRESHOLD
};
