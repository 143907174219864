import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const LeaveChart = ({totalAppliedLeaves , leaveThreshold}) => {
    const {SICK_LEAVE_THRESHOLD, CASUAL_LEAVE_THRESHOLD , MATERNITY_LEAVE_THRESHOLD } = leaveThreshold;
    const {sickLeavesApplied, casualLeavesApplied, maternityLeavesApplied } = totalAppliedLeaves ;
  const data = [
    { label: 'SL', threshold: SICK_LEAVE_THRESHOLD, applied: sickLeavesApplied },
    { label: 'CL', threshold: CASUAL_LEAVE_THRESHOLD, applied: casualLeavesApplied },
    // { label: 'ML', threshold: MATERNITY_LEAVE_THRESHOLD, applied: maternityLeavesApplied },
  ];

  return (
    <BarChart
      dataset={data}
      xAxis={[{ scaleType: 'band', dataKey: 'label' , categoryGapRatio: 0.8 ,barGapRatio: -0.01 }]}
      series={[
        { dataKey: 'threshold', label: 'Threshold Leave', color: '#001375' },
        { dataKey: 'applied', label: 'Applied Leave', color: '#dc7703' },
      ]}
      width={350}
      height={300}
    />
  );
};

export default LeaveChart;
