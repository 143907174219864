import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, FormControl, InputLabel, Select, 
    MenuItem, TextField, Grid, Button, LinearProgress } from "@mui/material";
import { Cancel as CancelIcon } from '@mui/icons-material';
import { getQueryTypes, saveQuery, updateRaiseQuery } from '../../services/RaiseQueryService';
import { NotificationManager } from 'react-notifications';

const RaiseQueryPopup = ({ open, handleCloseQueryPopup, employeeId, selectedQuery, popupTitle }) => {
    const [queryType, setQueryType] = useState('');
    const [subject, setSubject] = useState('');
    const [query, setQuery] = useState('');
    const [queryTypesList, setQueryTypesList] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        fetchQueryTypes();
    }, []);

    useEffect(() => {
        if (open && selectedQuery) {
            setQueryType(selectedQuery.queryTypeId?._id || '');
            setSubject(selectedQuery.subject || '');
            setQuery(selectedQuery.query || '');
        }
    }, [open, selectedQuery]);

    const fetchQueryTypes = async () => {
        try {
            const result = await getQueryTypes();
            setQueryTypesList(result);
        } catch (error) {
            console.error('Error fetching query types', error);
        }
    };

    const handleSubmitQuery = async () => {
        if (loading) return;
        try {
            setLoading(true);
            var queryDetails = { employeeId, queryType, subject, query };
            if (selectedQuery && selectedQuery._id) {
                queryDetails.id = selectedQuery._id
                await updateRaiseQuery(queryDetails);
                NotificationManager.success("Your Query is updated successfully");
            } else {
                await saveQuery(queryDetails);
                NotificationManager.success("Your Query is raised successfully");
            }
            handleClose(true);
        } catch (error) {
            console.error("Error while raising query:", error);
            NotificationManager.error("Failed to raise query");
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (isSubmitClicked) => {        
        handleCloseQueryPopup(isSubmitClicked);
        setQueryType('');
        setSubject('');
        setQuery('');
    };

    return (
        <Dialog open={open} onClose={(e) => handleClose(false, e)} 
        PaperProps={{ sx: { width: '400px' }, className: 'dialog' }}>
            <DialogTitle className='dialogTitle'>
                {popupTitle}
                <IconButton aria-label="close" onClick={(e) => handleClose(false, e)} className='dialogCloseIcon'>
                    <CancelIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className='dc-bottom-padding'>
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel id="query-type-label">Query Type</InputLabel>
                            <Select
                                labelId="query-type-label"
                                label="Query Type"
                                value={queryType}
                                onChange={(e) => setQueryType(e.target.value)}
                            >
                                {queryTypesList.map((query) => (
                                    <MenuItem key={query._id} value={query._id}>{query.type}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField required fullWidth label="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField required fullWidth multiline rows={3} label="Query" placeholder='Enter your query here' value={query} onChange={(e) => setQuery(e.target.value)} />
                    </Grid>
                </Grid>
                {loading && <LinearProgress className='progress-loading' />}
            </DialogContent>

            <DialogActions className='dialogActions'>
                <Button onClick={(e) => handleClose(false, e)} variant="contained">Cancel</Button>
                <Button onClick={handleSubmitQuery} variant="contained" color="primary" disabled={!queryType || !subject || !query}>
                {selectedQuery ? 'Update' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RaiseQueryPopup;
