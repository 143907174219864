import axios from 'axios';
import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';


export const createEmployee = async (newEmployee) => {
  try {
    const response = await axiosInstance.post(`${BASE_URL}/employees/`, newEmployee);
    return response.data;
  } catch (error) {
    console.error('Error creating employee:', error);
    throw error;
  }
};

export const getAllEmployees = async (fetchType = "", activeStatus = true) => {  
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees?activeStatus=${activeStatus}&fetchType=${fetchType}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

export const getAllEmployeeNames = async () => {  
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees/employeeNames`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

export const getEmployeesForAssets = async () => {  
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees/employeesForAssets`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

export const getAssetsByEmployee = async (id) => {  
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees/assets/${id}`);
      return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

export const updateEmployee = async (id, employeeData) => {
  const employeeId = localStorage.getItem("employeeId");
  try {
    if (employeeData instanceof FormData) {
      employeeData.append('LastModifiedBy', employeeId);
      employeeData.append('LastModifiedOn', new Date());
    } else{
      employeeData = {
        ...employeeData,
        LastModifiedBy: employeeId,
        LastModifiedOn: new Date(), 
      }
    }
  const response = await axiosInstance.put(`${BASE_URL}/employees/${id}`, employeeData);
  return response.data;
  } catch (error) {
      console.error('Error updating  employee:', error);
      throw error;
  }
};

export const updateEmployeeAssingedAssetIds = async (existingEmpId, newEmpId, assignedId) => {
  try {
    const response = await axiosInstance.put(`${BASE_URL}/employees/updateAssignedIds`,
      {
        "existingEmpId": existingEmpId, 
        "newEmpId": newEmpId,
        "assignedId": assignedId,
      });
    return response;
  } catch (error) {
    console.error('Error updating  employee:', error);
    throw error;
  }
};

export const removeAsset = async (id, assetdata) => {
  try {
  const response = await axiosInstance.put(`${BASE_URL}/employees/${id}/unassign`, assetdata);
  return response.data;
  } catch (error) {
      console.error('Error updating  employee:', error);
      throw error;
  }
};


export const getEmployeeById = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee:', error);
    throw error;
  }
}

export const getEmployeeByIdToEdit = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees/ToEdit/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee:', error);
    throw error;
  }
}

export const getFileById = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees/fetchFile/${id}` ,{
      responseType: 'blob',
  });
    // const objectUrl = URL.createObjectURL(response.data);
    // return objectUrl;
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            resolve(reader.result); // This will be a Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(response.data); // Convert blob to Base64
    });
  } catch (error) {
    console.error('Error fetching File:', error);
    throw error;
  }
}

export const getEmpPrimaryDetails = async (token, emailId) => {
  try {
    const response = await axios.get(`${BASE_URL}/employees/emailId?emailId=${emailId}`,{
      headers: {
        Authorization: `Bearer ${token}` 
      }
    });
    return response.data;
  } catch (error) {
     if (error.response && error.response.status === 403) {
      throw new Error('Employee not found');  // Explicitly throw error for 403
    }
    console.error('Error fetching employee:', error);
    return [];
  }
}

export const getEmployeesByRole = async () => {
  try {
      const response = await axiosInstance.get(`${BASE_URL}/employees/ByRole`);
      return response.data;
  } catch (error) {
      console.error('Error fetching employees by role:', error);
      throw error;
  }
};

export const deleteFile = async (id, fileId) => {
  try {
    const response = await axiosInstance.put(`${BASE_URL}/employees/${id}/photo`);
    return response.data;
} catch (error) {
    console.error('Error deleting employee photo:', error);
    throw error;
}
};

export const getNewJoinees = async()=>{
  try{
    const response =await axiosInstance.get(`${BASE_URL}/employees/getNewJoinees`)
    return response.data;
  }
  catch (error) {
    console.error('Error fetching the new joinee employees lists', error);
    throw error;
  }
}

export const getLongServiceEmpList = async()=>{
  try{
    const response =await axiosInstance.get(`${BASE_URL}/employees/getLongServiceEmpList`)
    return response.data;
  }
  catch (error) {
    console.error('Error fetching the long service employees lists', error);
    throw error;
  }
}

export const getHierarchalDataByEmployeeId = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees/${id}/hierarchy`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee:', error);
    throw error;
  }
}

export const OLDgetProfileDtl = async (id) => {
  try {
    
    const response = await axiosInstance.get(`${BASE_URL}/employees/profileDetails?_id=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee:', error);  
    throw error;
  }
}

export const getProfileDtl = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/employees/profileDetails?_id=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee:', error);  
    throw error;
  }
}


