import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { updateLeave, fetchLeavesByEmployeeIdAndDate, updateLeaveRejectRequest , getLeavesThreshold } from '../../services/leaveServices';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,  Grid, IconButton, LinearProgress, Modal, Tooltip, useTheme, FormControlLabel, Checkbox, TextField ,useMediaQuery  } from '@mui/material';
import { Cancel as CancelIcon, DateRange as DateRangeIcon, Clear as ClearIcon, Report as ReportIcon , Analytics as AnalyticsIcon } from '@mui/icons-material';
import { NotificationManager } from 'react-notifications';
import { format, parseISO } from 'date-fns';
import { SvgIcons } from '../../assets/icons/SvgIcons';
import ApplyLeavePopup from './ApplyLeavePopup';
import LeaveChart from './LeaveChart';
import { LEAVE_STATUS , PAGE_SIZE_OPTIONS, REJECT_REQUEST_ALLOWED_DAYS, ROWS_PER_PAGE} from '../../Constants';
import './applyLeave.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function ApplyLeave() {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const theme = useTheme();
    const employeeId = localStorage.getItem("employeeId");
    const reportingManagerId = localStorage.getItem("rmid");
    const [leaves, setLeaves] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalDays, setTotalDays] = useState(0);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [leaveCancelConformationPopup, setLeaveCancelConformationPopup] = useState(false);
    const [selectedRow, setSelectedRow] = useState('');
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedLeaveId, setSelectedLeaveId] = useState(null);
    const [checkboxStates, setCheckboxStates] = useState({});
    const previousCheckboxState = useRef({});
    const [rejectTooltipOpen, setRejectTooltipOpen] = useState({});
    const [rejectStatusTooltipOpen, setStatusRejectTooltipOpen] = useState({});
    const[employeeRejectRequestComment,setEmployeeRejectRequestComment]=useState("");

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const [totalAppliedLeaves, setTotalAppliedLeaves] = useState({"sickLeavesApplied": 0 , "casualLeavesApplied" : 0 , "maternityLeavesApplied" : 0});
    const [leaveThreshold , setLeaveThreshold ] = useState([{}]);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    useEffect(() => {
        fetchLeaves();
        fetchLeaveThreshold();
    }, []);


  useEffect(() => {
    setColumnVisibilityModel({
        reason: !isMobile,
        leaveType: !isMobile
    });
  }, [isMobile]);

    const fetchLeaves = async () => {
        try {
            setSelectedEmployeeId(employeeId);
            const fetchedLeaves = await fetchLeavesByEmployeeIdAndDate(employeeId);
            const totalDays = fetchedLeaves
                .filter(leave => leave.status === LEAVE_STATUS.PENDING || leave.status === LEAVE_STATUS.APPROVED)
                .reduce((total, leave) => {
                    const days = parseFloat(leave.numberOfDays) || 0;
                    return total + days;
                }, 0);

            const sickLeaveDays = fetchedLeaves
                .filter(leave => leave.status === LEAVE_STATUS.PENDING || leave.status === LEAVE_STATUS.APPROVED)
                .filter(leave => leave.leaveType === 'SL')
                .reduce((total, leave) => {
                    const days = parseFloat(leave.numberOfDays) || 0;
                    return total + days;
                }, 0);

            const casualLeaveDays = fetchedLeaves
                .filter(leave => leave.status === LEAVE_STATUS.PENDING || leave.status === LEAVE_STATUS.APPROVED)
                .filter(leave => leave.leaveType === 'CL')
                .reduce((total, leave) => {
                    const days = parseFloat(leave.numberOfDays) || 0;
                    return total + days;
                }, 0);

            const maternityLeaveDays = fetchedLeaves
                .filter(leave => leave.status === LEAVE_STATUS.PENDING || leave.status === LEAVE_STATUS.APPROVED)
                .filter(leave => leave.leaveType === 'ML')
                .reduce((total, leave) => {
                    const days = parseFloat(leave.numberOfDays) || 0;
                    return total + days;
                }, 0);

            setTotalAppliedLeaves({
                sickLeavesApplied: sickLeaveDays,
                casualLeavesApplied: casualLeaveDays,
                maternityLeavesApplied: maternityLeaveDays
            });
            // console.log("totalDays" , totalDays , "sickLeaveDays" , sickLeaveDays , "casualLeaveDays" , casualLeaveDays ,"maternityLeaveDays", maternityLeaveDays)
            setTotalDays(totalDays);
            setLeaves(fetchedLeaves);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching leaves:', error);
            setLoading(false);
        }
    };

    const fetchLeaveThreshold = async () => {
        try {
          const response =await getLeavesThreshold();
          setLeaveThreshold(response);
        } catch (error) {
            console.error('Error fetching leave threshold:', error);
        }
    } 

    const formatDate = (isoDateString) => {
        if (!isoDateString) return '';

        const date = parseISO(isoDateString);
        return format(date, isMobile ? 'dd MMM' : 'dd MMMM');
    };

    const handleCancelClick = (row) => {
        const pendingLopLeave = leaves.find(
            (leave) => leave.leaveType === 'LOP' && leave.status === LEAVE_STATUS.PENDING
        );
        if (row.leaveType !== 'LOP' && row.status === LEAVE_STATUS.PENDING && pendingLopLeave) {
            setConfirmationOpen(true);
        } else {
            setSelectedRow(row);
            setLeaveCancelConformationPopup(true);
        }
    };

    const cancelLeave = async (leaveId) => {
        try {
            await updateLeave(leaveId, LEAVE_STATUS.CANCELED); 
            fetchLeaves();
        } catch (error) {
            NotificationManager.error(error.response?.data?.message || 'An error occurred while canceling the leave request');
        }
    };

    const handleCancelConfirmation = () => {
        setConfirmationOpen(false);
    };

    const handleRejectTooltipOpen = (leaveId) => {
        setRejectTooltipOpen((prev) => ({
          ...prev,
          [leaveId]: true,
        }));
    };

    const handleRejectTooltipClose = (leaveId) => {
        setRejectTooltipOpen((prev) => ({
            ...prev,
            [leaveId]: false,
        }));
    };
    
    const isRejectTooltipOpen = (leaveId) => rejectTooltipOpen[leaveId] || false;

    const handleRejectStatusTooltipOpen = (id) => {
        setStatusRejectTooltipOpen((prev) => ({
            ...prev,
            [id]: true,
        }));
    };

    const handleRejectStatusTooltipClose = (id) => {
        setStatusRejectTooltipOpen((prev) => ({
            ...prev,
            [id]: false,
        }));
    };

    const isRejectStatusTooltipOpen = (id) => rejectStatusTooltipOpen[id] || false;

    const columns = [
        // { field: 'fromDate', headerName: <strong>From Date</strong>, flex: 1, minWidth:80,
        //     valueGetter: (params) => formatDate(params),
        // },
        // { field: 'toDate', headerName: <strong>To Date</strong>, flex: 1, minWidth: 80,
        //     valueGetter: (params) => formatDate(params),
        // },
        { field: 'date',
            headerName: <strong>From - To Date</strong>,
            flex: 1,
            minWidth: 115,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              const fromDate = formatDate(params.row.fromDate);
              const toDate = formatDate(params.row.toDate);
              return `${fromDate} - ${toDate}`;
            },
        },
        { field: 'reason', headerName: <strong>Reason For Leave</strong>, flex: 1.5, minWidth: 200,},
        { field: 'leaveType', headerName: <strong>Leave Type</strong>, flex: 1, minWidth: 100,},
        { 
            field: 'status', 
            headerName: <strong>Status</strong>, 
            flex: 1, minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                let fillColor;
    
                switch (params.value) {
                    case LEAVE_STATUS.PENDING:
                        fillColor = LEAVE_STATUS.PENDING_COLOR;
                        break;
                    case LEAVE_STATUS.APPROVED:
                        fillColor = LEAVE_STATUS.APPROVED_COLOR;
                        break;
                    case LEAVE_STATUS.REJECTED:
                        fillColor = LEAVE_STATUS.REJECTED_COLOR;
                        break;
                    case LEAVE_STATUS.CANCELED:
                        fillColor = LEAVE_STATUS.CANCELED_COLOR;
                        break;
                    default:
                        return null;
                }

                return (
                    <Tooltip 
                        title={params?.row?.adminRejectComment}
                        open={isRejectStatusTooltipOpen(params.row._id)}
                        onClose={() => handleRejectStatusTooltipClose(params.row._id)}
                        onOpen={() => handleRejectStatusTooltipOpen(params.row._id)}
                        leaveTouchDelay={10000}
                        arrow
                    >
                        <IconButton
                            style={{ padding: 0, borderRadius:0, display:'flex', alignItems:'center'}}
                            onClick={(e) =>{
                                setStatusRejectTooltipOpen((prev) => ({
                                  ...prev,
                                  [params.row._id]: !isRejectStatusTooltipOpen(params.row._id),
                                }))
                            }}
                            onMouseEnter={() => handleRejectStatusTooltipOpen(params.row._id)}
                            onMouseLeave={() => handleRejectStatusTooltipClose(params.row._id)}
                        >
                            <SvgIcons marginTop='8px' fillColor={fillColor} text={params.value} />
                        </IconButton>

                    </Tooltip>
                );
            }
        },
        // { field: 'comment', headerName: <strong>Comments</strong>, flex: 1, minWidth: 200, },
        {
            field: 'actions',
            headerName: <strong>Actions</strong>,
            flex: 1, minWidth:200,
            sortable: false,
            renderCell: (params) => {
                const leaveDate = new Date(params.row.fromDate); // Convert `fromDate` to a Date object
                const currentDate = new Date(); // Get the current date
                const daysDifference = Math.floor((leaveDate - currentDate) / (1000 * 60 * 60 * 24));
                return (
                    <Box 
                        sx={{
                            pt:1.2,
                            display: 'flex',
                            alignItems:'center',
                            gap:{xs:'1px' , sm:'15px'},
                            // gap: '15px',
                            width: '100%', 
                        }}
                    >
                        <Button 
                            variant="contained" 
                            style={{ maxHeight: '30px', width: '80px' }} 
                            disabled={params.row.status !== LEAVE_STATUS.PENDING} 
                            onClick={() => handleCancelClick(params.row)} 
                            endIcon={<ClearIcon />}
                        > 
                            Cancel 
                        </Button>
                        {/* If leave status is Approved, show the checkbox */}
                        {params.row.status === LEAVE_STATUS.APPROVED && (daysDifference >= REJECT_REQUEST_ALLOWED_DAYS || params.row.isRejectRequested) ? (
                            <FormControlLabel
                                className='reject-leave-form-control'
                                control={
                                    <Checkbox 
                                        sx={{ paddingLeft : '0rem'}}
                                        checked={checkboxStates[params.row._id] !== undefined ? checkboxStates[params.row._id] : (params.row.isRejectRequested || false)}
                                        onChange={(e) => handleCheckboxChange(e, params.row._id)}
                                        disabled={params.row.isRejectRequested || checkboxStates[params.row._id]}
                                    />
                                }
                                label={
                                    <Tooltip title="The checkbox allows you to reject approved leaves. Please discuss with your Reporting Manager before raising a Reject Request." 
                                        open={isRejectTooltipOpen(params.row._id)}
                                        onClose={() => handleRejectTooltipClose(params.row._id)}
                                        onOpen={() => handleRejectTooltipOpen(params.row._id)}
                                        leaveTouchDelay={10000}
                                        arrow
                                    >
                                        <IconButton sx={{paddingLeft: '0rem', paddingRight: '0rem'}}
                                            onClick={(e) =>{
                                                setRejectTooltipOpen((prev) => ({
                                                  ...prev,
                                                  [params.row._id]: !isRejectTooltipOpen(params.row._id),
                                                }))
                                              }}
                                              onMouseEnter={() => handleRejectTooltipOpen(params.row._id)}
                                              onMouseLeave={() => handleRejectTooltipClose(params.row._id)}
                                        >
                                        <ReportIcon sx={{ color: '#1976d2', fontSize: '1rem'}} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                        ) : null}
                    </Box>
                )
            }
        }
    ];

    const handleAddClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    const handleConfirmPopupClose = () => {
        setLeaveCancelConformationPopup(false);
    };
    
    const handleConfirmPopupContinue = async () => {
        if (selectedRow) {
            try {
                await cancelLeave(selectedRow._id);
                setLeaveCancelConformationPopup(false);
                NotificationManager.success('Leave request canceled successfully');
            } catch (error) {
                NotificationManager.error('Failed to cancel leave');
            }
        }
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleCheckboxChange = (e, leaveId) => {
        const { checked } = e.target;
        previousCheckboxState.current[leaveId] = checkboxStates[leaveId];
        setCheckboxStates((prevStates) => ({
            ...prevStates,
            [leaveId]: checked
        }));
        setSelectedLeaveId(leaveId);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        if (selectedLeaveId) {
            setCheckboxStates((prevStates) => ({
                ...prevStates,
                [selectedLeaveId]: previousCheckboxState.current[selectedLeaveId]
            }));
        }
        setOpenDialog(false);
        setSelectedLeaveId(null);
    };

    const handleSend = async () => {
        if (selectedLeaveId !== null) {
            try {
                await updateLeaveRejectRequest(selectedLeaveId,employeeRejectRequestComment);
                NotificationManager.success('Reject leave request sent successfully');
            } catch (error) {
                NotificationManager.error('An error occurred while sending the reject leave request');
                setCheckboxStates((prevStates) => ({
                    ...prevStates,
                    [selectedLeaveId]: previousCheckboxState.current[selectedLeaveId]
                }));
            }
        }
        setOpenDialog(false);
        setSelectedLeaveId(null);
    };
    const [openChart, setOpenChart] = useState(false);

    return (
        <Box className = 'main-content main-content-height' >                
                <Grid container className='grid-container-header align-header-center'>
                  <Grid item xs={12} sm={6}>
                        My Leaves History {currentYear}
                        {(!reportingManagerId || reportingManagerId === 'undefined') && <Tooltip title="You cannot apply leaves as you are not associated to any Reporting Manager. Please contact your Manager to apply leaves."
                                    open={open}
                                    onClose={handleTooltipClose}
                                    onOpen={handleTooltipOpen} 
                                    leaveTouchDelay={10000}
                                    arrow>
                                    <IconButton onClick={() => setOpen(!open)}
                                        onMouseEnter={handleTooltipOpen}
                                        onMouseLeave={handleTooltipClose}  >
                                        <ReportIcon className='info-icon' />
                                    </IconButton>
                                </Tooltip>}
                  </Grid> 
                  <Grid item xs={12} sm={6} sx={{textAlign:'right'}}>
                    <Button variant="contained" startIcon={<AnalyticsIcon />} onClick={() => setOpenChart(true)} 
                    sx={{
                        whiteSpace: 'nowrap',
                        minWidth: { xs: '110px', sm: 'auto' },
                        padding: { xs: '6px 12px', sm: '8px 16px' },
                        marginRight: '10px'
                    }}> Leave Statistic </Button>
                        <Button variant="contained" onClick={handleAddClick} startIcon={<DateRangeIcon/>} disabled={!reportingManagerId || reportingManagerId === 'undefined'}
                        sx={{
                            whiteSpace: 'nowrap',
                            minWidth: { xs: '110px', sm: 'auto' },
                            padding: { xs: '6px 12px', sm: '8px 16px' }
                          }}>
                            Apply Leave
                        </Button>
                    </Grid>             
                </Grid>
                
                <Grid container padding={2} style={{ flexGrow: 1,overflow: 'hidden', backgroundColor: '#fff' }}>
                    <Grid item xs={12} style={{ height: "100%" }}>
                        <DataGrid
                            localeText={{ noRowsLabel: "No leave requests available. Please add a new request." }}
                            rows={leaves}
                            getRowId={(row) => row._id}
                            columns={columns}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: ROWS_PER_PAGE,
                                    },
                                },
                            }}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            pagination
                            components={{
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={loading}
                            sx={{
                                '& .MuiDataGrid-selectedRowCount': {
                                  display: 'none',
                                },
                              }}
                        />
                    </Grid>
                </Grid>
                <ApplyLeavePopup open={popupOpen} handleCloseDialog={handleClosePopup} employeeId={selectedEmployeeId} totalAppliedLeaves={totalAppliedLeaves} totalDays={totalDays}  fetchLeavesList={fetchLeaves} appliedLeaves={leaves} leaveThreshold={leaveThreshold}/>
                <Modal
                    open={openChart}
                    onClose={()=>{setOpenChart(false)}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                > 
                <Box sx={style}>
                <LeaveChart  totalAppliedLeaves={totalAppliedLeaves}  leaveThreshold={leaveThreshold}/>
                </Box>
                </Modal>
                <Dialog
                    open={confirmationOpen}
                    onClose={handleCancelConfirmation}
                    PaperProps={{ sx: { width: '400px' }}}
                >
                    <DialogTitle className="confirmDialogTitle">
                        Cancel Leave Request
                        <IconButton aria-label="close" onClick={handleCancelConfirmation}>
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className='confirmDialogContent'>
                        <Alert severity='warning' className='confirmDialogMsg'>
                            You have a pending LOP leave request. Please cancel the LOP leave first before canceling any other leave request.
                        </Alert>
                    </DialogContent>
                    <DialogActions >
                        <Button onClick={handleCancelConfirmation} variant='contained'>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={leaveCancelConformationPopup}
                    onClose={handleConfirmPopupClose}
                    PaperProps={{ sx: { width: '350px' } }}
                >
                    <DialogTitle className="confirmDialogTitle">
                        Leave Cancel Confirmation
                        <IconButton aria-label="close" onClick={handleConfirmPopupClose}>
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="confirmDialogContent">
                    <Alert severity="warning" className="confirmDialogMsg">You are about to cancel the leave request dated&nbsp;                  
                            <strong>
                            {selectedRow.fromDate === selectedRow.toDate 
                                ? <>{formatDate(selectedRow.fromDate)}</>
                                : <>{formatDate(selectedRow.fromDate)} to {formatDate(selectedRow.toDate)}</>
                            }
                            </strong>
                        . Do you wish to continue?                          
                    </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmPopupClose} variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmPopupContinue} variant="contained">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDialog} onClose={handleDialogClose} PaperProps={{ sx: { width: '500px' }}}>
                    <DialogTitle className="confirmDialogTitle">Reject Request Confirmation
                        <IconButton aria-label="close" onClick={handleDialogClose}>
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                        <DialogContent >
                            <Alert severity="warning" className="confirmDialogMsg">
                                You are about to raise a Reject Request for an Approved Leave. Please proceed, if you have informed your Reporting Manager.
                            </Alert>
                            <TextField
                                autoFocus
                                label="Comment"
                                placeholder='Optional'
                                multiline
                                rows={2}
                                fullWidth
                                value={employeeRejectRequestComment}
                                onChange={(e)=>{setEmployeeRejectRequestComment(e.target.value)}}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} variant ='outlined' sx={{ width: '80px' }}>Cancel</Button>
                            <Button onClick={handleSend} variant="contained">Send</Button>
                        </DialogActions>
                </Dialog>
        </Box>
    );
}
