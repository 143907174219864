import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchUserRoles } from '../../services/UserRolesServices';
import { Box, Button, Grid, LinearProgress, useTheme} from '@mui/material';
import { BorderColor as BorderColorIcon, ManageAccounts as ManageAccountsIcon } from '@mui/icons-material';
import UserRolePopup from './UseRolePopup';
import { SvgIcons } from '../../assets/icons/SvgIcons';
import StatusSwitch from '../../assets/icons/StatusSwitch'; 
import { PAGE_SIZE_OPTIONS, ROWS_PER_PAGE, STATUS } from '../../Constants';
export default function UserRoles() {
    const theme = useTheme();
    const [userRoles, setUserRoles] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statusSort, setStatusSort] = useState(true);
    const [userUpdated, setUserUpdated] = useState(false);
    
    useEffect(() => {
        fetchUserRolesDb();
    }, [userUpdated]);

    const fetchUserRolesDb = async () => {
        try {
          const userRolesResponse = await fetchUserRoles();
          setUserRoles(userRolesResponse);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user roles:', error);
          setLoading(false);
        }
    };

    const filteredDatas = userRoles.filter((role) => role.activeStatus === statusSort);

    const columns = [
      { field: "userRole", headerName: <strong>Role</strong>, width: 250 },
      {
        field: "description",
        headerName: <strong>Description</strong>,
        width: 250,
      },
      {
        field: "activeStatus",
        headerName: <strong>Status</strong>,
        width: 250,
        renderCell: (params) => (
          <span>
            {params.value ? (
              <SvgIcons marginTop="8px" fillColor={STATUS.ACTIVE_COLOR} text={STATUS.ACTIVE} />
            ) : (
              <SvgIcons marginTop="8px" fillColor={STATUS.INACTIVE_COLOR} text={STATUS.INACTIVE} />
            )}
          </span>
        ),
      },
      {
        field: "actions",
        headerName: <strong>Actions</strong>,
        width: 250,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <BorderColorIcon onClick={() => handleEditClick(params.row)} />
        ),
      },
    ];

    const handleAddClick = () => {
        setSelectedRole(null);
        setPopupOpen(true);
    };
    
    const handleEditClick = (role) => {
        setSelectedRole(role);
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
        // fetchUserRolesDb();
    };
    
    return (
    <Box className = 'main-content main-content-height'>

                <Grid container className='grid-container-header align-header-center'>
                <Grid item xs={6}>                        
                        User Roles
                </Grid>
                <Grid item xs={6} className='align-filters-right'>
                <StatusSwitch
                    checked={statusSort}
                    onChange={(e) => setStatusSort(e.target.checked)}
                />
                <Button variant="contained" onClick={handleAddClick} startIcon={<ManageAccountsIcon/>}>Add Role</Button>
                </Grid>
                </Grid>
            <Grid container  sx={{ flexGrow: 1,overflow: 'hidden' }}>
                <Grid item xs={12}  sx={{height: '100%'}}>
                    <DataGrid
                        localeText={{ noRowsLabel: "No user roles available. Please add a new role." }}
                        rows={filteredDatas}
                        getRowId={(row) => row._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: ROWS_PER_PAGE,
                              },
                            },
                        }}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        pagination
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                        sx={{
                            '& .MuiDataGrid-selectedRowCount': {
                              display: 'none',
                            },
                          }}   
                    />
                </Grid>
            </Grid>
            <UserRolePopup open={popupOpen} handleCloseDialog={handleClosePopup} initialData={selectedRole}  setUserUpdated= {setUserUpdated}/>
    </Box>
    );
}
