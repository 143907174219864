import React, { useEffect, useState } from 'react';
import { getAllQueries, getQueriesByEmployeeId, saveReponse } from '../../services/RaiseQueryService';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, IconButton, Popover, Typography, Tooltip, Checkbox } from '@mui/material';
import { BorderColor as BorderColorIcon, QuestionAnswer as QuestionAnswerIcon} from '@mui/icons-material';
import { NotificationManager } from 'react-notifications';
import RespondQueryPopup from './RespondQueryPopup';

export default function RaisedQueries({queryResponseFilter, handleEditQueryPopup, myQueries, allRaisedQueries, refreshQueriesGrid}) {    
    const employeeId = localStorage.getItem("employeeId");
    const [raisedQueries, setRaisedQueries] = useState([]);
    const [openResponsePopup, setOpenResponsePopup] = useState(false);
    const [selectedQuery, setSelectedQuery] = useState(null);
    const [queryResponse, setQueryResponse] = useState();
    const [showResponse, setShowResponse] = useState();
    const [anchorEl, setAnchorEl] = useState(null);

    const fetchAllQueries = async () => {
        try {
            if (allRaisedQueries) {
                const response = await getAllQueries(queryResponseFilter);
                setRaisedQueries(response);
            }
            if (myQueries) {
                const response = await getQueriesByEmployeeId(employeeId);
                setRaisedQueries(response);
            }

        } catch (error) {
            console.error('Error fetching Queries:', error);
        }
    }

    useEffect(() => {
        setRaisedQueries([]);
        fetchAllQueries();
    }, [queryResponseFilter]);

    useEffect(() => {
        if (refreshQueriesGrid) {
            refreshQueriesGrid = false;
            fetchAllQueries();
        }
    }, [refreshQueriesGrid]);

    const handleOpenResponsePopup = (query) => {
        setSelectedQuery(query);
        if (query.reply) {
            setQueryResponse(query?.reply);
        }
        else {
            setQueryResponse("");
        }
        setOpenResponsePopup(true);
    };

    const handleCloseResponsePopup = () => {
        setOpenResponsePopup(false);
        setSelectedQuery(null);
        setQueryResponse('');
    };

    const handleShowResponse = (e, params) => {
        if (params.reply) {
            setShowResponse(params.reply);
            setAnchorEl(e.currentTarget);
        }
        else {
            setAnchorEl(null);
        }
    }

    const handleClosePopover = () => {
        setAnchorEl(null);
        setShowResponse('');
    };

    const handleSaveResponse = async () => {
        try {
            const queryDetails = {
                reply: queryResponse,
                id: selectedQuery._id
            };
            await saveReponse(queryDetails);
            fetchAllQueries();
            NotificationManager.success("Query raised successfully");
            setOpenResponsePopup(false);
        } catch (error) {
            console.error("Error while raising query:", error);
            NotificationManager.error("Failed to raise query");
        }
    };

    const handleFAQToggle = async (event, row) => {
        const updatedValue = event.target.checked;
        try {
            const queryDetails = {
                id: row._id,
                showInFAQ: updatedValue,
            };
            await saveReponse(queryDetails);
            fetchAllQueries();
            NotificationManager.success("FAQ status updated successfully");
        } catch (error) {
            console.error("Error updating FAQ status:", error);
            NotificationManager.error("Failed to update FAQ status");
        }
    };

    const columns = [
        { field: 'queryTypeId', headerName: <strong>Query Type</strong>, flex: 1, valueGetter: (params) => params?.type },
        { field: 'subject', headerName: <strong>Subject</strong>, flex: 2 },
        { field: 'query', headerName: <strong>Query</strong>, flex: 3 },
    ];

    if (allRaisedQueries) {
        columns.push({
            field: 'provideResponse',
            headerName: <strong>Provide Response</strong>,
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={"Click here to respond to the query"}>
                <span>
                <IconButton onClick={() => handleOpenResponsePopup(params.row)}
                    style={{ color: params.row.reply ? 'green' : 'orange' }}
                >
                    <QuestionAnswerIcon />
                </IconButton>
                </span>
            </Tooltip>
            ),
        },
    );
    }

    if (allRaisedQueries && queryResponseFilter) {
        columns.push({
            field: 'showInFAQ',
            headerName: <strong>Show in FAQ</strong>,
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.showInFAQ}
                    onChange={(event) => handleFAQToggle(event, params.row)}
                />
            ),
        },
        {
            field: 'editQuery',
            headerName: <strong>Edit</strong>,
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleEditQueryPopup(params.row)}
                >
                    <BorderColorIcon />
                </IconButton>
            ),
        })
    }

    if (myQueries) {
        columns.push({
            field: 'editQuery',
            headerName: <strong>Edit</strong>,
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <IconButton onClick={() => handleEditQueryPopup(params.row)}
                disabled={params.row.reply}
                >
                    <BorderColorIcon />
                </IconButton>
            ),
        },
            {
                field: 'viewResponse',
                headerName: <strong>View Response</strong>,
                sortable: false,
                flex: 1,
                renderCell: (params) => (
                    <Tooltip title={!params.row.reply ? "No Response yet" : "Click here to view response"}>
                        <span>
                            <IconButton
                                onClick={(e) => handleShowResponse(e, params.row)}
                                disabled={!params.row.reply}
                                style={{ color: params.row.reply ? 'green' : 'gray' }}
                            >
                                <QuestionAnswerIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                ),
            });
    }

    return (
        <>
            <Grid item xs={12} style={{ height: "100%" }}>
                <DataGrid 
                    // rows={raisedQueries.map((query) => ({ ...query, id: query._id }))}
                    rows={Array.isArray(raisedQueries) ? raisedQueries.map((query) => ({ ...query, id: query._id })) : []}
                    columns={columns}
                    pageSize={10}
                    isRowSelectable={() => false}
                    localeText={{ noRowsLabel: "No Queries posted" }}
                />
            </Grid>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography sx={{ p: 2, maxWidth: 200 }}>{showResponse}</Typography>
            </Popover>

            <RespondQueryPopup
            openResponsePopup={openResponsePopup}
            handleCloseResponsePopup={handleCloseResponsePopup}
            selectedQuery={selectedQuery}
            queryResponse={queryResponse}
            handleSaveResponse={handleSaveResponse}
            setQueryResponse={setQueryResponse}
            />
        </>
    );
}
