import React, { useState, useEffect } from "react";
import Tree from "react-d3-tree";
import { getHierarchalDataByEmployeeId } from "../../services/employeeService";
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material";
import FaceIcon from '@mui/icons-material/Face';
import Face3Icon from '@mui/icons-material/Face3';
import "./orgChart.css";

const OrgChart = () => {
  const [hierarchicalData, setHierarchicalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const employeeId = localStorage.getItem("employeeId");

  const isMobile = useMediaQuery('(max-width:600px)');

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (employeeId) {
      getHierarchicalData();
    }
  }, [employeeId]);

  const getHierarchicalData = async () => {
    try {
      const responseData = await getHierarchalDataByEmployeeId(employeeId);
      if (responseData) {
        setHierarchicalData(transformData(responseData));
      }
    } catch (error) {
      console.error("Error fetching hierarchy data:", error);
    } finally {
      setLoading(false);
    }
  };

  const transformData = (node) => {
    if (!node) return null;
    return {
      name: `${node.firstName || "Unknown"} ${node.lastName || ""}`.trim(),
      attributes: { 
        designation: node.designation || "N/A",
        gender: node.gender, 
      },
      children: node.Child ? node.Child.map(transformData) : [],
    };
  };

  if (loading) {
    return (
      <div className="hierarchy-container">
        <CircularProgress />
        <Typography variant="h6" sx={{ marginLeft: 2 }}>Loading hierarchy...</Typography>
      </div>
    );
  }

  const centerX = dimensions.width / 2.1;
  const centerY = dimensions.height / 8;

  return (
    <Box className="main-content main-content-height" >
      <Grid className='grid-container-header grid-container-header-with-nofilters align-header-center'>
          Organization Chart
      </Grid>
      <Grid container className="hierarchy-container">
        {hierarchicalData ? (
          <Tree
            data={hierarchicalData}
            orientation="vertical"
            pathFunc="step"
            collapsible={true}
            nodeSize={{ x: isMobile ? 120 : 220, y: isMobile ? 100 : 120 }}
            separation={{ siblings: isMobile ? 2 : 1.2, nonSiblings: 2 }}
            translate={{ x: centerX, y: centerY }}
            draggable={true}
            zoomable={true}
            renderCustomNodeElement={({ nodeDatum, toggleNode }) => (
              <g className="tree-node" onClick={toggleNode}>
                <rect x="-100" y="-30" width="200" height="60" rx="10"></rect>
                {nodeDatum.attributes?.gender && (
                  <foreignObject x="-98" y="-29" width="30" height="60">
                    {nodeDatum.attributes.gender === 'Male' ? (
                      <FaceIcon style={{ fontSize: 24, color:'#ffffff', stroke: 'none' }} />
                    ) : (
                      <Face3Icon style={{ fontSize: 24, color:'#ffffff', stroke: 'none' }} />
                    )}
                  </foreignObject>
                )}
                <text x="0" y="-5" fontWeight="bold" fontSize="14">
                  {nodeDatum.name}
                </text>
                <text x="0" y="15" fontSize="12">
                  {nodeDatum.attributes?.designation}
                </text>
              </g>
            )}            
          />
        ) : (
          <Typography variant="h6">No hierarchy data found</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default OrgChart;


