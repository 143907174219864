import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';

export const saveRegime = async (formData) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/itdeclaration`, formData);
        return response.data;
    } catch (error) {
        console.error('Error saving IT Declaration:', error);
        throw error;
    }
};

export const updateRegime = async (formData) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/itdeclaration/updateRegimeData`,formData);
        return response.data;
    } catch (error) {
        console.error("Error updating IT declaration regime data:", error);
        throw error;
    }
};

export const getFilesData = async (fileId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/itdeclaration/file/${fileId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching file data:", error.message);
        return undefined;
    }
};

export const getRegimeData = async (employeeId, financialYear) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/itdeclaration/${employeeId}/${financialYear}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching regime data:", error.message);
        return undefined;
    }
};

export const getProofsDataForItdReports = async (employeeId, financialYear) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/itdeclaration/zipProofs/${employeeId}/${financialYear}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching proof of submission data:", error.message);
        return undefined;
    }
};

//this is used for upload proof popup
export const getProofsData = async (employeeId, financialYear) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/itdeclaration/proofs/${employeeId}/${financialYear}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching proofs data:", error.message);
        return undefined;
    }
};

export const getEmployeesByRegime = async (regime, financialYear) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/itdeclaration`, {
            params: { regime, financialYear }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching employees by regime and financial year:", error);
        throw error;
    }
};


export const deleteProofOfSubmission = async (fileId) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/itdeclaration/${fileId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting proof file:', error);
        throw error;
    }
};

export const saveProofOfSubmission = async (formData) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/itdeclaration/proofs`, formData);
        return response.data;
    } catch (error) {
        console.error('Error saving proof of submission:', error);
        throw error;
    }
};

export const enableITDFlags = async (reqBody) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/itdeclaration/ITDFlags`, reqBody);
        return response.data;
    } catch {
        console.error('Error updating enableProofOfSubmission button status:', error);
        throw error;
    }
}

export const getITDConfigurationDetails = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/itdeclaration/ITDConfigurationDetails`);
      return response.data;
    } catch (error) {
      console.error('Error fetching ITConfigurationDetails:', error);
      throw error;
    }
};