import React, { useState } from 'react'
import RaisedQueries from './RaisedQueries'
import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import RaiseQueryPopup from './RaiseQueryPopup';

export default function AllRaisedQueries() {
  const [queryResponseFilter, setQueryResponseFilter] = useState(false);
  const [openQueryPopup, setOpenQueryPopup] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [popupTitle,setPopupTitle] = useState();
  const [refreshQueriesGrid, setRefreshQueriesGrid] = useState(false);
  const employeeId = localStorage.getItem("employeeId");

  const handleCloseQueryPopup = (isSubmitClicked) => {
    if(isSubmitClicked){
      setRefreshQueriesGrid(isSubmitClicked);
    }
    setOpenQueryPopup(false);
  };
  
  
  const handleEditQueryPopup = (query) => {
    setPopupTitle('Edit a Query')
    setRefreshQueriesGrid(false);
    setOpenQueryPopup(true);
    setSelectedQuery(query);
  };

  return (
    <Box className='main-content'>
      <Grid container className='grid-container-header align-header-center'>
        <Grid item xs={6}>
          All Raised Queries
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <FormControlLabel
            control={<Switch checked={queryResponseFilter} onChange={(e) => setQueryResponseFilter(e.target.checked)}/>}
            label={<Typography className="status-switch" width={"130px"}> {queryResponseFilter ? "Responded Queries" : "Not Responded Queries"}</Typography>}
          />
        </Grid>
      </Grid>

      <Grid container padding={2} style={{ flexGrow: 1, overflow: 'hidden', backgroundColor: '#fff' }}>
        <Grid item xs={12}>
          <RaisedQueries handleEditQueryPopup={handleEditQueryPopup} refreshQueriesGrid = {refreshQueriesGrid} queryResponseFilter={queryResponseFilter} allRaisedQueries={true}/>
        </Grid>
      </Grid>
      <RaiseQueryPopup
        open={openQueryPopup}
        handleCloseQueryPopup={handleCloseQueryPopup}
        employeeId={employeeId}
        selectedQuery={selectedQuery}
        popupTitle={popupTitle}
      />
    </Box>
  )
}
