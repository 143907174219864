import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';

export const saveQuery = async (formData) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/raiseQueries`, formData);
        return response.data;
    } catch (error) {
        console.error('Error raising query:', error);
        throw error;
    }
};

export const saveReponse = async (formData) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/raiseQueries/update`, formData);
        return response.data;
    } catch (error) {
        console.error('Error raising query:', error);
        throw error;
    }
};

export const getAllQueries = async (filter = false) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/raiseQueries`, {
            params: { filter } // Pass filter as query parameter
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching queries:', error);
        throw error;
    }
};


export const getQueryTypes = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/raiseQueries/types`);
        return response.data;
    } catch (error) {
        console.error('Error fetching query types:', error);
        throw error;
    }
};

export const updateRaiseQuery = async (formData) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/raiseQueries/updateRaiseQuery`, formData);
        return response.data;
    } catch (error) {
        console.error('Error raising query:', error);
        throw error;
    }
};

export const getQueriesByEmployeeId = async (employeeId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/raiseQueries/${employeeId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching query types:', error);
        throw error;
    }
};

export const getFaqQueries = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/raiseQueries/faq/all`);
        return response.data;
    } catch (error) {
        console.error('Error fetching FAQ queries:', error);
        throw error;
    }
};
